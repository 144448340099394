.news-page-container{    
    padding: 50px 0 20px;
}
.news-header {
    font-family: Felix-Titling;
    font-size: 21px;
    display: flex;
    justify-content: space-between;
    max-width: 890px;
    width: 100%;
    margin: 0 auto;
    letter-spacing: 1px;
    margin-bottom: 45px !important;
    margin-top: 0px;
}
.news-page-content{
    margin-top: 80px;
    height: 500px;
    overflow-y: auto;
    overflow-x: hidden;
}
.news-card{
    max-width: 350px;
    width: 100%;
    margin: 0 auto 40px;
}
.news-card .news-body{
    max-width: 230px;
    height: 150px;
    width: 100%;
    /* margin: 30px auto 0; */
}
.news-card .news-body img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}
.news-card h4{
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: 'Century-Bold';
    text-transform: uppercase;
}
.news-btn-sec {
    display: flex;
    justify-content: center;
    padding: 20px 0;
    /* flex-wrap: wrap; */
}

.news-btn-sec button:first-child {
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.newsAddContent{
    margin-top: 50px;
}

.edit-news-sec .editnews-Content{
    padding: 70px 0 0;
}
.editnews-Content .editnews-card{
    max-width: 350px;
    width: 100%;
    margin: 10px auto 30px;
}
.editnews-Content .editnews-card h3{
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    margin-bottom: 10px;
}
.editnews-Content {
    height: 630px;
    overflow-y: auto;
    overflow-x: hidden;
}
.editnews-Content .editenews-data p{
    font-size: 12px;
    line-height: 19px;
    font-family: 'Century-Italic';
    margin-bottom: 15px;
}
.editnews-btn-sec{
    display: flex;
    justify-content: space-between;
    padding: 50px 0 10px;
}
.editnewscard-img {
    max-width: 200px;
    height: 200px;
    margin: 0 auto;
}
.editnewscard-img img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.user-comment-area h4{
    font-size: 16px;
    line-height: 20px;
    opacity: 0.6;
}

.comments-sec{
    background-color: #131313;
    border-radius: 5px;
    border: 0.3px solid #FEF6F2;
    max-width: 260px;
    width: 100%;
    margin: 0 auto;
}
.comments-sec .comment-sec-heading{
    padding: 10px 5px;
    border-bottom: 0.3px solid #FEF6F2;
}
.comments-sec .comment-sec-heading h2{
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Bold';
    text-align: center;
    margin: 0;
}
.user-comment-area {
    padding: 15px;
}
.user-comment {
    display: flex;
    justify-content: space-between;
    margin-bottom: 25px;
}

.user-content {
    margin-left: 5px;
}
.user-content .user-name{
    font-size: 13px;
    line-height: 20px;
    font-family: 'Century-Bold';
}
.user-content .user-name span{
    font-family: 'Century-Italic';
}
.user-content p{
    font-size: 10px;
    line-height: 12px;
    font-family: 'Century-Italic';
}
.user-comment-area .user-data{
    display: flex;
}
.user-img {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    /* top: 5px; */
    
}
.notification-sec{
    display: flex;
    align-items: center;
}
.notification-sec .comment-sec svg{
    width: 16px;
    height: 16px;
    margin-right: 2px;
}
.notification-sec .comment-sec{
    font-size: 12px;
    line-height: 12px;
    margin-right: 15px;
}
.notification-sec .like-sec svg{
    width: 20px;
    height: 20px;
    margin-right: 2px;
}
.notification-sec .like-sec{
    font-size: 12px;
    line-height: 12px;
    margin-right: 15px;   
}
.notification-sec .share-sec svg{
    width: 20px;
    height: 20px;
}
.notification-sec .share-sec{
    font-size: 12px;
    line-height: 12px;
    margin-right: 15px;   
}
@media only screen and (max-width:390px){
    .news-btn-sec {
        flex-direction: column;
    }
    .news-btn-sec button{
        max-width: 100%;
    }
}

.file-input-sec .fileUpload {
    position: relative;
    overflow: hidden;
     cursor: pointer;
}
.file-input-sec .fileUpload input.upload {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: 0;
    left: 0;
    padding: 0;
    font-size: 20px;
    cursor: pointer;
    opacity: 0;   
    height: 100%;
    width: 100%;
    z-index: 1;
    max-width: 100%;
}

.file-input-sec .btn--browse{  
    border: 1px solid #3a3838;
    outline: none;
    cursor: pointer;
}
.file-input-sec .btn--browse:hover{
    border: 1px solid #3a3838;
    cursor: pointer;
}

.file-input-sec .f-input{	
	width: 100%;
	max-width: 400px;
	padding: 10px 14px;
    text-align: center;
     cursor: pointer;
}
.file-input-sec .f-input::placeholder{
    text-align: center;
}
