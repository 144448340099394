.event-page-content {
  padding: 0px 0 20px;
}
.event-calendar-sec {
  padding: 80px 0 20px;
}
.event-calendar .react-calendar {
  background-color: black;
  padding: 5px;
  max-width: 530px;
  width: 100%;
  border-radius: 30px;
}
.toolbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  background: white;
  width: 40%;
  margin: 0 auto;
}
.editor {
  width: 100%;
  height: 500px;
  background: white;
  color: black;
  padding: 11px;
  font-size: 15px;
}

.event-calendar .react-calendar button {
  color: #fff;
}
.button-for-choice {
  background-color: white;
  border-color: transparent;
}
.button-goup-popup {
  margin-top: 10px;
  padding: 10px;
  background: white;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  margin-bottom: -3px;
  z-index: 99;
}
div#modal-description {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
}
.event-data {
  margin-left: 0px !important;
  width: 80%;
}
.event-calendar .react-calendar .react-calendar__tile {
  background-color: transparent;
}
.event-calendar .react-calendar .react-calendar__tile abbr {
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}
.event-calendar .react-calendar .react-calendar__tile--active abbr {
  background-color: rgb(255, 171, 171);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}
.event-calendar .react-calendar .react-calendar__tile:enabled:hover,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus {
  background-color: transparent;
}
.event-calendar .react-calendar .react-calendar__tile:enabled:hover abbr,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus abbr {
  background-color: red;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}
.event-page-content {
  padding: 50px 0 20px;
}

.event-calendar-sec {
  padding: 0px 0 20px;
}

.event-calendar .react-calendar {
  background-color: black;
  padding: 5px;
  max-width: 530px;
  width: 100%;
  border-radius: 30px;
}

.event-calendar .react-calendar button {
  color: #fff;
}

.event-calendar .react-calendar .react-calendar__tile {
  background-color: transparent;
}

.event-calendar .react-calendar .react-calendar__tile abbr {
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}

.event-calendar .react-calendar .react-calendar__tile--active abbr {
  background-color: rgb(255, 171, 171);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}

.event-calendar .react-calendar .react-calendar__tile:enabled:hover,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus {
  background-color: transparent;
}

.event-calendar .react-calendar .react-calendar__tile:enabled:hover abbr,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus abbr {
  background-color: red;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}
.cal-list-card2 {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  padding: 10px 20px 10px 8px;
  color: #131313;
  margin-bottom: 15px;
}

.cal-list-card .left-sec {
  display: flex;
  align-items: center;
}

.cal-list-card .left-sec .date {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #800b0f;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}

.cal-list-card .left-sec .event-data h4 {
  font-size: 13px;
  line-height: 14px;
  font-family: "Century-BoldItalic";
}

.cal-list-card .left-sec .event-data p {
  font-size: 10px;
  line-height: 13px;
  font-family: "Century-Italic";
  margin: 0;
}

.cal-list-card .right-sec h3 {
  font-size: 19px;
  line-height: 14px;
  font-family: "Century-BoldItalic";
  margin: 0;
}

.event-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 30px 5px 10px;
}

.calendar-list-sec2 {
  padding: 5px 20px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 435px;
  max-width: 750px;
  width: 100%;
}
.calendar-list-sec2 .cal-month h4 {
  font-size: 16px;
  line-height: 20px;
  font-family: "Century-Bold";
  margin-bottom: 20px;
}

.calendar-list-sec .cal-month h4 {
  font-size: 16px;
  line-height: 20px;
  font-family: "Century-Bold";
  margin-bottom: 20px;
}

.event-calendar-sec .react-calendar__navigation button:enabled:hover,
.event-calendar-sec .react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.op-31 {
  opacity: 31%;
}

.custom-container {
  width: 100%;
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #000;
}

.custom-calendar span {
  color: #fff;
}

.rmdp-shadow {
  box-shadow: none;
  border-radius: 15px !important;
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #000;
  box-shadow: unset;
  border: 1px solid #fef6f257;
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
  border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
  border-bottom: 1px solid bisque;
}

.custom-calendar,
.custom-calendar .rmdp-calendar,
.custom-calendar .rmdp-day-picker,
.custom-calendar .rmdp-day-picker > div {
  width: 100% !important;
}

.custom-calendar .rmdp-day,
.custom-calendar .rmdp-week-day {
  color: #fff;
  height: 41px !important;
  width: 41px !important;
}

.custom-calendar .rmdp-arrow {
  border: solid #fff;
  border-width: 0px 2px 2px 0px;
  display: inline-block;
  height: 10px;
  margin-top: 5px;
  padding: 2px;
  width: 10px;
}

.custom-calendar .rmdp-arrow-container {
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 5px;
  padding: 0;
  width: 40px;
  align-items: center;
}

/*
  .custom-calendar .rmdp-border-bottom {
    border-bottom: 1px solid #000;
  } */

.eventColor_list ul li a {
  font-size: 11px;
  color: #fff;
}

.eventColor_list ul li span {
  font-size: 12px;
}

.clr-circle {
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-right: 10px;
  background: transparent;
  border-radius: 50%;
}

.eventColor_list ul li {
  display: flex;
  align-items: center;
}

.yellow-clr {
  background-color: #df9825;
}

.red-clr {
  background-color: #800b0f;
}
.green-clr {
  background-color: #25762e;
}

.blue-clr {
  background-color: #54a3c9;
}

.custom-calendar .rmdp-border-bottom {
  border-bottom: 0;
}

.custom-calendar .rmdp-colors {
  width: 0;
  height: 0;
  padding: 0;
}

.custom-calendar .rmdp-color.active {
  box-shadow: unset;
}

.dates.today {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #800b0f;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}
.dates.events {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #df9825;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}
.dates.exclusive {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #df9825;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}
.dates.exclusive-events {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #54a3c9;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}
.dates.experiences-messice {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #25762e;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}
.dates.general {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #25762e;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}

/* calender */
/* Calendar.css */

.app-cal-cont {
  width: 100%;
  margin: 0 auto;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.239);
  border-style: solid;
  padding: 20px;
  border-radius: 25px;
}

.app-cal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.app-cal {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 10px;
  font-size: 14px;
}

.app-cal-day {
  border: 1px solid #dddddd00;
  padding: 10px;
  text-align: center;
}

.yesterday {
  background-color: #ffe0e0; /* Yesterday's color */
}

.empty {
  visibility: hidden;
}
.month-name-app {
  font-size: 14px !important;
}
.button-arrow {
  border-color: transparent;
  outline: none;
  background-color: transparent;
  color: white;
}

/*********  event-edit-box  *********/
.event-edit-box {
  border: 1px solid #fef6f2;
  padding: 32px 30px;
  border-radius: 20px;
}

.event-edit-box {
  border: 1px solid #fef6f2;
  padding: 32px 30px;
  border-radius: 20px;
  font-size: 13px;
  margin-bottom: 65px;
}

.day-box {
  font-weight: 700;
}

.edit-box-date,
.event-name-box {
  display: flex;
  gap: 10px;
  align-items: center;
}

.mb-20 {
  margin-bottom: 20px;
}

.date-box {
  width: 28px;
  height: 28px;
  background: #cb8b15;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  min-width: 28px;
}

.eevent-page .event-page-content {
  max-width: 350px;
  margin: 0 auto;
}

.event-name {
  font-weight: 700;
}

.event-box-content p {
  font-size: 11px;
  line-height: 15px;
  font-style: italic;
}

.event-box-btn button {
  border-radius: 30px;
}

.event-box-img {
  border-radius: 16px;
}
.event-box-img img {
  width: 100%;
  height: 100%;
  max-height: 200px;
  object-fit: cover;
}
/*********  event-edit-box  *********/

.event-link-box {
  word-wrap: break-word;
  border: 1px solid white;
  border-radius: 15px;
  padding: 10px;
}
.news-header1 {
  font-family: Felix-Titling;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 1px;
  margin-bottom: 45px !important;
  margin-top: 0px;
}
.app-cal-day.day-name {
  font-weight: 900;
  font-family: "Century-Bold";
}
.cal-month h4 {
  font-size: 17px;
  font-family: "Century-Bold";
  margin-bottom: 15px;
  margin-left: 38px;
}
.no-data-thing {
  font-size: 14px;
  margin-left: 8px;
  margin-top: 18px;
}
.event-box-content.mb-20 {
  max-height: 400px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.MuiBox-root.css-14916sd {
  background: #ffffff1f;
  border-color: white;
  border-style: solid;
  border-radius: 14px;
  backdrop-filter: blur(13px);
  border-width: 1px;
}
.dates.events {
  min-width: 50px;
}

@media screen and (max-width: 1566px) {
  .app-cal {
    gap: 12px !important;
  }
}
@media screen and (max-width: 1299px) {
  .app-cal {
    gap: 5px !important;
  }
}
@media screen and (max-width: 767px) {
  .cal-month {
    margin-top: 30px;
  }
  .app-cal {
    gap: 9px !important;
  }
}
