/* Global css start */
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  /* max-width: 1569px !important; */
}

html {
  scroll-behavior: smooth;
}

body.main-wrapper {
  font-family: "Century-Gothic";
  font-weight: 500;
  font-size: 22px;
  line-height: 14px;
  color: #fef6f2;
  overflow-x: hidden;
  scroll-behavior: smooth;
  background: transparent radial-gradient(closest-side at 50% 50%, #131313 0%, #000000 100%) 0% 0% no-repeat padding-box;
  min-height: 100vh;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0;
}

a {
  text-decoration: none !important;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  font-size: 16px;
}

ul {
  list-style: none;
  padding: 0;
}

/* Common css */
.dashboard {
  min-height: 80vh;
}

.dashboard-container {
  padding: 50px 15px;
}

.main-title {
  font-size: 21px;
  text-align: center;
  text-transform: uppercase;
  font-family: "Felix-Titling";
  line-height: 1.4;
}

.news-header-pv .main-title,
.news-header .main-title {
  margin-bottom: 0;
}

.header-title {
  text-transform: uppercase;
}

.inter-page-header-title {
  font-family: "Felix Titling";
}

/* common input form css */

.common-form-control {
  max-width: 890px;
  width: 100%;
  background-color: #fff;
  border-radius: 39px;
  text-align: center;
  padding: 8px;
  margin-bottom: 35px;
  color: #131313;
}

.common-form-control label {
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
  display: block;
  margin-bottom: 5px;
}

.common-form-control input {
  font-size: 16px;
  line-height: 20px;
  font-family: "Century-Italic";
  margin: 0;
  max-width: 640px;
  width: 100%;
  border: 0;
  padding: 5px 10px;
  outline: 0;
  text-align: center;
}

.common-form-control input::placeholder {
  text-align: center;
}

.common-form-control select {
  width: 99%;
  border: 0;
  font-size: 16px;
  color: #5e5959;
  outline: none;
  background: transparent;
  text-align: center;
}

.blue-btn {
  background-color: #005eaa;
  max-width: 230px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  text-transform: uppercase;
}

.green-btn {
  max-width: 230px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #25762e;
  text-transform: uppercase;
}

.red-btn {
  max-width: 230px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #800b0f;
  text-transform: uppercase;
}

.green-blue-btn {
  max-width: 230px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background: linear-gradient(270deg,
      #25762e 0%,
      #25762e 50%,
      #005eaa 54%,
      #005eaa 100%) 0% 0% no-repeat padding-box;
}

.common-dash-padding {
  padding: 0px 0 10px;
}

.space-between-btn {
  padding: 30px 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-icon .searchBarInput {
  position: absolute;
  right: 30px;
  top: 50%;
  transform: translateY(-50%);
}

.center-space-btn {
  justify-content: center;
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 30px 0 20px;
}

.grid-list-sec {
  display: flex;
  justify-content: space-between;
  max-width: 890px;
  width: 100%;
  margin: 30px auto 0;
  align-items: baseline;
  flex-wrap: wrap;
  /* Allow items to wrap on smaller screens */
}

/* For tablets (medium screens) */
@media screen and (max-width: 768px) {
  .grid-list-sec {
    flex-direction: column;
    /* Stack items vertically */
    align-items: center;
    /* Center align the items */
    margin: 20px auto 0;
    /* Reduce margin */
  }
}


/* For mobile phones (small screens) */
@media screen and (max-width: 480px) {
  .grid-list-sec {
    flex-direction: column;
    margin: 10px auto;
    align-items: baseline;
    /* Further reduce margin */
  }

  .billboard-btn-sec {
    width: 220px;
    display: flex;
    justify-content: center;
    gap: 30px;
    padding: 30px 5px 10px;
    flex-direction: column;
  }
}


.widthChange {
  max-width: 1000px;
}

.swal2-popup .swal2-title {
  font-size: 24px;
  font-family: "Century-Bold";
  font-style: normal;
}

.swal2-popup .swal2-confirm.swal2-styled {
  max-width: 150px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #005eaa;
  min-width: 100px;
}

.swal2-popup .swal2-deny.swal2-styled {
  max-width: 150px;
  width: 100%;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #fff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #800b0f;
  min-width: 100px;
}

.swal2-actions .btn-danger,
.swal2-actions .btn-success {
  margin: 0 5px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background: #131313;
}

::-webkit-scrollbar-thumb {
  background: #fef6f2;
  border-radius: 30px;
}

.buttonGroup {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 15px;
}

.buttonGroup>div {
  margin: 0;
  flex: 0 calc(33.33% - 15px);
}

.buttonGroup>div>button {
  margin-bottom: 0;
  max-width: 230px;
  width: 100%;
}

.header-title {
  font-size: 21px;
  margin-bottom: 0;
}

.news-header-pv {
  margin-bottom: 45px;
}

.todas-card .left-sec .profile-img img {
  width: 100%;
  height: 100%;
}

.dashboardIconCol.col-sm-4.col-6 {
  width: 195px;
}

.sp-page .news-header {
  margin-bottom: 8px;
}

.exp-col {
  max-width: 780px;
  margin: 0 auto;
}

.exp-col .blue-btn,
.exp-col .green-btn,
.exp-col .red-btn {
  margin: 0;
  gap: 15px;
}

.exp-editPage .space-between-btn {
  flex-wrap: wrap;
  gap: 15px;
}

.addLegend-col {
  max-width: 920px;
  margin: 0 auto;
}

/*******new css *******/

/******* Common Responsive Css *******/

@media only screen and (max-width: 1439px) {
  .general-tab-sec .row .col {
    min-width: 50%;
  }

  .apv-page .common-form-control label {
    font-size: 16px;
  }

  .apv-page .common-form-control input {
    font-size: 14px;
  }
}

@media only screen and (max-width: 1199px) {
  .buttonGroup {
    justify-content: center;
  }

  .buttonGroup>div {
    flex: calc(50% - 15px);
  }

  .ed-page .cal-month h4 {
    margin: 20px 0;
  }

  .sp-page .streaming-card-sec {
    gap: 20px;
  }

  .hl-page .historical-legends-2 {
    gap: 20px;
  }

  .ed-page .calendar-list-sec2 {
    padding: 0;
    max-width: 100%;
  }

  .experience-sec21 {
    height: 100%;
    overflow-y: scroll;
  }
}

@media only screen and (max-width: 991px) {
  .general-tab-sec .row .col {
    min-width: 33.33%;
  }

  .dashboard-container .first-sec {
    order: 3;
    width: 100%;
    flex: unset;
  }

  .dashboard-container .second-sec {
    order: 1;
    width: 100%;
  }

  .dashboard-container .third-sec {
    order: 2;
    width: 100%;
  }

  .dashboard-container .camino-seg {
    margin: 0 auto !important;
  }

  .paveb-page .two-option-tags .div-with-drop>p {
    width: 100%;
  }

  .paveb-page .two-option-tags {
    flex-direction: column;
  }

  .paveb-page .two-option-tags .div-with-drop:first-child {
    z-index: 999999;
  }

  body .header-title {
    font-size: 18px;
  }

  .blue-btn,
  .green-btn,
  .red-btn {
    font-size: 12px;
  }

  .second-sec .bboardSec .lista-content h3 {
    font-size: 14px;
  }

  .second-sec .bboardSec .lista-content p {
    font-size: 12px;
  }

  .bb-page .cuadricula-content .billboard-sec-inner2 .row .col-lg-4 {
    flex: 0 33.33%;
    width: 33.33%;
  }

  .exp-col {
    max-width: 100%;
  }

  .exp-editPage .space-between-btn {
    flex-direction: column;
    max-width: 230px;
    margin: 0 auto;
  }

  .bb-page .dashboard-container .first-sec,
  .bb-page .dashboard-container .third-sec {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  .general-tab-sec .row .col {
    min-width: 50%;
  }

  .common-date-field>div {
    flex: 0 100%;
  }

  .bb-page .grid-list-sec {
    flex-direction: column;
  }

  .bb-page .grid-list-sec .div-with-drop {
    align-items: flex-start;
  }

  .common-form-control {
    margin-bottom: 20px;
  }

  .err-spn {
    font-size: 16px;
  }

  .sp-page .streaming-card-sec {
    gap: 10px;
  }

  .editExp-content {
    padding: 50px 0 30px !important;
  }

  .editExp-page .container-fluid {
    padding: 0;
  }

  .streaming-card .card-img {
    max-width: 100%;
  }

  .dashboard.ed-page .event-date-page .container-fluid {
    padding: 0;
  }

  .no-data-thing {
    margin-left: 0;
  }

  .Digital-sec>.container-fluid {
    padding: 0;
  }

  .tab-content {
    font-size: 16px;
  }

  .todas-card-list {
    min-width: 750px;
  }

  .todas-card {
    overflow: auto;
  }

  .dashboardIconCol.col-sm-4.col-6 {
    width: 50%;
  }
}

@media only screen and (max-width: 575px) {
  .general-tab-sec .row .col {
    min-width: 100%;
  }

  .dashboard-container .third-sec {
    width: 100%;
    margin-bottom: 35px;
  }

  .dashboard-container .first-sec {
    width: 100%;
  }

  .buttonGroup>div {
    flex: 100%;
  }

  .blankDiv {
    display: none;
  }

  .bb-page .cuadricula-content .billboard-sec-inner2 .row .col-lg-4 {
    flex: 0 1 100%;
    width: 100%;
    max-width: 100%;
    margin: 0 auto 20px auto;
  }

  .bboardSec .custom-add-card {
    padding: 30px !important;
  }

  .bboardSec .billboard-cards {
    max-width: 100%;
  }
}

/******* Common Responsive Cs *******/