.experience-card-sec {
  margin-top: 50px;
}

.experience-card-sec .exp-card {
  max-width: 350px;
  width: 100%;
  margin: 0 auto 20px;
  cursor: pointer;
}

.exp-card .card-heading h3 {
  font-size: 16px;
}

.experience-sec {
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.exp-btn-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0 10px;
  gap: 30px;
}

.editExp-content {
  padding: 50px 20px 30px;
  height: 600px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.edit-exp-card {
  max-width: 350px;
  width: 100%;
  margin: 0 auto 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

}

.edit-exp-card .card-heading h3 {
  font-size: 16px;
  font-family: 'Francois One' !important;
  text-transform: uppercase;
}

.edit-exp-data .desc {
  font-size: 12px;
  line-height: 16px;
  font-family: 'Century-Italic';
  margin-bottom: 5px;
}

.req-sec {
  margin-top: 30px;
}

.req-sec .req-heading {
  font-size: 12px;
  line-height: 16px;
  font-family: 'Century-Italic';
  margin-bottom: 25px;
  text-transform: uppercase;
}

.req-sec .req-list {
  padding: 0;
}

.req-sec .req-list li p {
  font-size: 12px;
  line-height: 16px;
  font-family: 'Century-Italic';
  position: relative;
  padding-left: 20px;
}

.req-sec .req-list li p::before {
  content: "*";
  position: absolute;
  left: 0px;
  font-size: 20px;
  top: 5px;
}

.inpts-edit-exp {
  outline: none;
  margin-bottom: 20px;
  font-size: 20px;
  padding: 6px;
  border-radius: 8px;
}

.textarea-edit-exp {
  width: 100%;
  height: auto;
  font-size: 15px;
  padding: 10px;
  outline: none;
  border-radius: 10px;
}

.preview-imgCon-ae {
  width: 100%;
  height: 100%;
  padding: 50px;
  padding-left: 50px;
  padding-right: 50px;
}

.preview-imgCon img,
.preview-imgCon video {
  width: 65%;
  height: 100%;
}

@media only screen and (max-width: 767px) {
  .experience-card-sec .exp-card {
    max-width: 100%;
  }

  .imgage-exp {
    max-width: 100%;
    min-height: 185px;
    max-height: 185px;
  }
}