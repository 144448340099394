.table-container {
    display: flex;
    border: 1px solid #000; /* Add border to the entire table */
  }
  
  .left-section {
    flex: 2; /* 40% width */
    padding: 20px;
    border: 1px solid white; /* White border */
  }
  
  .right-section {
    flex: 3; /* 60% width */
    padding: 20px;
    border: 1px solid white; /* White border */
  }
  
  .main-heading {
    margin-bottom: 20px;
  }
  
  .sub-heading {
    margin-bottom: 10px;
  }
  
  .left-part-table,
  .right-part-table {
    padding: 0;
  }
  
  .list-item,
  .checkbox-item {
    margin-bottom: 10px;
    border: 1px solid white; /* White border for each box */
  }
  .right-list{
    display: flex;
    flex-direction: column;
  }
  