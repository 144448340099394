.tab-section-tab{
    background: transparent;
    color: white;
    border-top-color: transparent;
    border-left-color: transparent;
    border-right-color: transparent;
    border-bottom-color: yellow;
    font-family: 'Francois One';
}




.nav-tabs .nav-link.active{
    color: #fff !important;
    background-color: #fff0 !important;
    border-top-color: #fff0  !important;
    border-left-color:  #fff0  !important;
    border-right-color:  #fff0  !important;
}

