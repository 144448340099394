.message-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 594px;
  border-color: white;
  border-width: 2px;
  border-style: solid;
  padding: 29px;
}

.left-chat {
  display: flex;
  justify-content: flex-start;
}

.right-chat {
  display: flex;
  justify-content: flex-end;
}

/* new */

.billboard-sec {
  padding: 50px 0;
}

.calendar-list-sec {
  padding: 5px 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 400px;
  width: 1000px;
}

.cal-list-card {
  height: 72px;
  max-width: 891px;
  width: 100%;
}

.ed-page .cal-list-card h4 {
  line-height: 1.1 !important;
}

.ed-page .cal-list-card p {
  line-height: 1.1 !important;
}

.ed-page .event-data {
  width: calc(100% - 70px);
}


.ed-page .event-data p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ed-page .event-data h4 {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.ed-page .cal-list-card .left-sec {
  flex-wrap: wrap;
  overflow: hidden;
}




.myTeamBanner {
  text-align: center;
}

.billboard-cards {
  max-width: 350px;
  width: 100%;
  margin-bottom: 40px;
}

.billboard-cards h3 {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}

.lista-content {
  /* overflow: auto; */
  margin-bottom: 20px;
}

.billboard-sec-inner {
  height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
  /* min-width: 991px; */
}

.billboard-btn-sec {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px 5px 10px;
}

.two-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 35px;
  margin-top: 35px;
}

.box-bm-1 {
  width: 76px;
  background-color: transparent;
  height: 88px;
  font-size: 9px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0px;
  margin-right: 7px;
}

.box-bm-2 {
  width: 76px;
  background-color: transparent;
  height: 88px;
  font-size: 9px;
  padding: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0px;
  margin-right: 7px;
}

.logo-content {
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
}

.header-title-bm {
  font-family: "Felix Titling";
  color: #fef6f2;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 21px;
}

.cal-list-card .left-sec .event-data h4 {
  font-size: 19px;
  line-height: 14px;
  font-family: "Century-BoldItalic";
  letter-spacing: 0px;
  font-weight: 200;
  color: #131313;
}

.cal-list-card .left-sec .event-data p {
  font-size: 16px;
  line-height: 13px;
  font-family: "Century-Italic";
  margin: 0;
}

.news-header-bm {
  display: flex;
  justify-content: space-between;
  max-width: 908px;
  width: 100%;
  margin: 0 auto;
}

.calendar-list-sec-bm {
  padding: 0px 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 433px;
  margin-top: 0px;
  max-width: 950px;
  width: 100%;
  margin-bottom: 100px;
}

.select-bm {
  width: 233px;
  height: 40px;
  background: #131313 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #fef6f2;
  color: white;
  border-radius: 20px;
  opacity: 1;
  font-size: 16px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  z-index: 999;
  cursor: pointer;
}

.option-bm {
  width: 233px;
  height: 244px;
  background: #131313 0% 0% no-repeat padding-box;
  border: 1px solid #fef6f2;
  border-radius: 5px;
  opacity: 1;
}

.search-icon-bm {
  margin-right: 22px;
  cursor: pointer;
}

.arr-down-bm {
  width: 30px;
  color: white;
}

.comments-sec-bm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 0;
  margin-left: 135px;
}

.options-bm {
  width: 233px;
  height: auto;
  background: #131313 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #fef6f2;
  border-radius: 5px;
  opacity: 1;
  margin-top: -22px;
  z-index: 0;
  padding-top: 36px;
  line-height: 32px;
  padding-left: 22px;
}

.options-bm p {
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  font-style: italic;
}

::-webkit-scrollbar.calendar-list-sec-bm {
  width: 12px;
  /* Set the width of the scrollbar */
  background-color: #f1f1f1;
  /* Set the background color of the scrollbar track */
  border-radius: 6px;
  /* Set the border radius of the scrollbar track */
}

::-webkit-scrollbar-thumb.calendar-list-sec-bm {
  background-color: #131313;
  /* Set the color of the scrollbar thumb */
  border-radius: 6px;
  /* Set the border radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover.calendar-list-sec-bm {
  background-color: #555;
  /* Set the color of the scrollbar thumb on hover */
}

.blur-form-sec {
  border-radius: 26px;
  opacity: 1;
  backdrop-filter: blur(21px);
  -webkit-backdrop-filter: blur(21px);
  border: 1px solid #fef6f2;
  padding: 10px 30px;
}

.blur-form-sec form {
  width: 100%;
}

.blur-form-sec label {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.blur-form-control {
  margin-bottom: 15px;
}

.blur-form-sec .blur-input {
  width: 100%;
  border-radius: 50px;
  background-color: #131313;
  border: 0;
  padding: 5px;
}

.blur-btn-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blur-btn-sec .green-btn {
  background: linear-gradient(180deg, #096a31 0%, #0a9042 100%) 0% 0% no-repeat padding-box;
  max-width: 180px;
  border-radius: 50px;
}

.blur-btn-sec .red-btn {
  max-width: 180px;
  border-radius: 50px;
}

.chatPage-sec {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 460px;
}

.msger {
  max-width: 100%;
  width: 100%;
  background-color: #131313;
  margin-left: 0;
  margin-top: 40px;
}

.partcpt-section {
  border: 0;
}

.chatPage-sec p {
  margin: 0;
}

.msger .msger-header-options button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  text-align: left;
  border-width: 0px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 8px;
  padding: 0;
  cursor: pointer;
  border-style: solid;
}

.participants-list {
  padding: 0;
  margin: 0;
}

.participants-list li .partcp-profile {
  padding: 15px 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.partcp-profile .partcp-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}

.partcp-profile .partcp-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.partcp-profile .partcp-name {
  font-size: 13px;
  line-height: 20px;
  margin: 0 5px;
}

.partcp-profile .partcp-admin {
  font-size: 11px;
}

.partcp-profile .partcp-profile {
  width: 17px;
}

.partcp-profile .partcp-profile img {
  width: 100%;
  height: 100%;
}

.msger .input-form {
  border-top: none;
  width: 100%;
}

.msger .input-form>p {
  font-size: 12px;
  margin-bottom: 10px;
}

.msger .input-form>p a {
  text-transform: none;
  color: #3c80d2;
}

.msger .input-form-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  border-top: 1px solid #fef6f2;
  align-items: center;
}

.msger .input-form-wrap form {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  position: relative;
  margin-top: -1px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.msger .input-form-wrap form input[type="text"] {
  background-color: #131313;
  padding: 13px 13px 13px 50px;
  border: none;
  width: 100%;
  box-sizing: border-box;
}

.msger .input-form-wrap form input[type="text"]:focus {
  outline: none;
  border: none;
}

.msger .input-form-wrap form .msger-attachment-btn {
  position: absolute;
  top: 13px;
  border: none;
  background: none;
}

.msger .input-form-wrap form .msger-attachment-btn.left-btn {
  left: 10px;
  top: 10px;
}

.msger .input-form-wrap form .msger-attachment-btn.left-btn svg {
  color: #fff;
  font-size: 30px;
}

.msger .input-form-wrap form .msger-attachment-btn.right-btn {
  right: 25px;
}

.msger .input-button-wrap button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eeedf1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
}

.msger-header {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  padding: 15px 15px;
  border-bottom: var(--border);
  border-bottom: 1px solid #fef6f2;
}

.msger-chat .msg-img {
  width: 40px;
  height: 40px;
}

.msger-chat .msg-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.msger-chat .msg {
  position: relative;
  margin-bottom: 10px;
}

.msger-chat .msg-img {
  width: 40px;
  height: 40px;
  background: #ddd;
  border-radius: 50%;
  position: absolute;
  top: 0;
  max-width: 650px;
}

.msger-chat .msg-time {
  font-size: 12px;
  color: #8a8d91;
  display: block;
  margin-bottom: 3px;
}

.msger-chat .msg-bubble .msg-text {
  padding: 10px 12px;
  margin-bottom: 1px;
  font-size: 14px;
  display: inline-block;
  width: max-content;
}

.msger-chat .msg-bubble .msg-text p {
  margin: 0;
}

.msger-chat .msg-bubble .msg-text a {
  color: inherit;
}

.msger-chat .msg-bubble .msg-quote p {
  font-style: italic;
  padding-left: 15px;
}

.msger-chat .msg-bubble .msg-quote span {
  font-size: 12px;
  display: block;
  padding-left: 15px;
  font-weight: 300;
}

.msger-chat .msg-item {
  margin-bottom: 15px;
}

.msger-chat .left-msg {
  padding-left: 60px;
  text-align: left;
}

.msger-chat .left-msg .msg-img {
  left: 0;
}

.msger-chat .left-msg .msg-item .msg-bubble .msg-text {
  background: #25762e;
  border-radius: 0 10px 10px 0;
}

.msger-chat .left-msg .msg-item .msg-bubble:last-of-type .msg-text {
  border-bottom-left-radius: 10px;
}

.msger-chat .right-msg {
  text-align: right;
  padding-right: 40px;
}

.msger-chat .right-msg .msg-img {
  right: 0;
  margin-right: 0;
}

.msger-chat .right-msg .msg-item .msg-bubble .msg-text {
  background: #fff;
  border-radius: 10px 0 0 10px;
  color: #000;
}

.msger-chat .right-msg .msg-item .msg-bubble:first-of-type .msg-text {
  border-top-right-radius: 10px;
  margin-right: 11px;
}

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.participatns-sec {
  max-width: 230px;
  width: 100%;
  position: relative;
  flex-flow: column wrap;
  height: 100%;
  background-color: #131313;
  border: 1px solid #fef6f2;
}

.tabGallerySec {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
}

.tabGallery-sec {
  display: grid;
  grid-template-columns: repeat(8, 100px);
  gap: 5px;
  padding: 30px 0px;
  overflow-y: hidden;
}

.tabGallery-sec img {
  width: 100%;
  height: 100%;
}

.tabGallerySec .tabgalleryList {
  max-width: 260px;
  width: 100%;
  margin: 20px auto;
  border: 0;
  border-bottom: 0;
  background-color: #cb8b15;
  border-radius: 50px;
}

.tabgalleryList .nav-link.active {
  background: #fef6f2;
  color: #000;
  border-radius: 50px;
  border: 0;
  font-size: 15px;
  line-height: 20px;
}

.tabgalleryList.nav-tabs .nav-link.active,
.tabgalleryList.nav-tabs .nav-item.show .nav-link {
  border: 0;
  font-size: 15px;
  line-height: 20px;
}

.triangle-rot {
  rotate: z 90deg !important;
  cursor: pointer !important;
  margin-right: 10px;
}

.tabGallerySec .tabgalleryList .nav-link {
  background: transparent;
  font-size: 15px !important;
  line-height: 20px !important;
  font-family: "Century-Bold";
  padding: 5px 20px;
  color: #fff;
  border: 0;
  border-radius: 50px;
}

.tabGallerySec .tabgalleryList .nav-link:hover {
  font-size: 15px;
  line-height: 20px;
  border-color: none;
  background: #fef6f2;
  color: #000;
  border-radius: 50px;
}

.name-display {
  font-size: 10px;
  /* border-bottom-style: solid;
    border-bottom-width: 1px; */
  font-size: 11px;

  font-weight: bold;
  margin-bottom: 4px;
}

.msg-text-wrapper {
  display: flex;
  flex-direction: column;
}

.msg-text-wrapper-right {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: self-end;
}

section.msger {
  justify-content: unset;
}

.msger-chat {
  padding: 30px 15px;
}

.msger-chat {
  padding: 30px 15px;
  height: calc(100% - 45px);
  border-bottom: 1px solid #fef6f2;
  padding: 30px 15px;
  /* max-height: 530px; */
  overflow: auto;
}

.name-display {
  font-size: 10px;
  font-weight: bold;
}

.msger-chat .msg-img {
  top: 70%;
  transform: translateY(-50%);
}

.audio-recorder {
  margin-right: 10px;
}


@media (max-width: 767px) {
  .profile-img24 img {
    width: 40px;
    height: 40px;
  }

  .msger .input-form-wrap form {
    font-size: 17px;
  }
}

@media (max-width: 600px) {
  .msger .input-form-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .msger-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    padding-top: 20px;
  }
}

@media (max-width: 575px) {
  .chatPage-sec {
    width: 100%;
  }
}