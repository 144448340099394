.editExp-content {
  padding: 50px 20px 30px;
  height: auto;
  overflow-y: hidden;
}

.common-form-control.img-video-form-control {
  position: relative;
  border-radius: 50px;
  margin: 0 auto 20px;
}

.common-form-control.img-video-form-control .fileUpload.btn.btn--browse {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 30%;
  opacity: 0;
}

.common-form-quater-control {
  max-width: 890px;
  width: 20%;
  background-color: #fff;
  border-radius: 39px;
  text-align: center;
  padding: 8px;
  margin-bottom: 35px;
  color: #131313;
}

.common-form-half-control {
  max-width: 890px;
  width: 45%;
  background-color: #fff;
  border-radius: 39px;
  text-align: center;
  padding: 8px;
  margin-bottom: 35px;
  color: #131313;
}

.file-input-sec-text {
  height: 180px;
}

.file-input-sec .f-input {
  width: 100%;
  max-width: 806px;
  padding: 5px 76px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
}

.file-input-sec-text .f-text {
  width: 100%;
  max-width: 806px;
  text-align: start;
  font-family: "Century-Gothic";
  height: 90%;
  border-color: #00000033;
  border-style: solid;
  overflow: auto;
  font-size: 16px;
  outline: none;
  padding: 10px;
  max-height: 150px;
  color: #131313b8;
}

.file-input-sec .input-quater {
  width: 100%;
  max-width: 823px;
  padding: 0px 20px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
  border-color: transparent;
  border-radius: 20px;
  font-size: 15px;
}

.file-input-sec .input-half {
  width: 100%;
  max-width: 823px;
  padding: 0px 20px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
  border-color: transparent;
  border-radius: 20px;
  font-size: 15px;
}

.file-input-sec input {
  width: 100%;
  max-width: 400px;
  padding: 5px 76px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
}

.common-form-quater-control label {
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
  display: block;
  margin-bottom: 5px;
}

.common-form-half-control label {
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
  display: block;
  margin-bottom: 5px;
}

.common-form-quater-control {
  max-width: 890px;
  width: 90%;
  background-color: #fff;
  border-radius: 39px;
  text-align: center;
  padding: 8px;
  margin-bottom: 35px;
  color: #131313;
}

.price-quant-pair {
  display: flex;
  flex-direction: column;
}

.five-pair {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.four-item-goup>div {
  max-width: unset;
  width: unset;
  flex: 0 calc(33.33% - 15px);
}

/* product details */
/* .product-view-container {
    display: flex;
    max-width: 800px;
    flex-direction: row;
    margin: auto;
    padding: 20px;
    background-color: #000;
    color: #fff;
  } */

.slick-slider {
  width: 300px;
}

.product-image {
  width: 100%;
  height: auto;
}

/* .product-details {
    flex-grow: 1;
    margin-left: 20px;
  } */

.product-name {
  font-size: 20px;
}

.product-category {
  color: #ccc;
  margin-right: 30px;
}

.product-category-image {
  width: 17px;
  border-radius: 50%;
  margin-left: 30px;
}

.product-description {
  color: #ddd;
  margin-bottom: 20px;
}

.more-details {
  color: #ccc;
  margin-bottom: 20px;
}

.seller-details {
  color: #fff;
}

h3 {
  color: #fff;
  margin-top: 20px;
}

/* Customize slick carousel dots */
.slick-dots li button:before {
  color: #fff;
}

/* Override slick carousel arrow color */
.slick-prev,
.slick-next {
  color: #fff;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .product-details {
  padding: 30px 0;
} */

.product-details .product-name,
.seller-details h3 {
  font-size: 26px;
}

.product-details p {
  font-size: 14px;
  margin-bottom: 10px;
  line-height: 1.4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.product-view-container {
  padding: 30px 0;
}

.product-details .product-name,
.seller-details h3 {
  font-size: 26px;
}

select#colorSelect {
  margin-left: 15px;
  background: none;
  border-left: none;
  border-right: none;
  border-top: none;
  border-bottom: solid;
  color: white;
  font-size: 16px;
  margin-top: 18px;
  outline: none;
}

.product-details p span {
  font-weight: 700;
  font-size: 16px;
  margin-right: 2px;
}

.details-slider {
  max-width: 400px;
  margin: 0 auto;
}

.size-cat {
  display: flex;
  flex-direction: row;
  align-items: center;
}

label {
  font-size: 16px;
}

.upload-photo-billboard {
  width: 100%;
  height: 100%;
}

.p-tag-ref-input {
  font-size: 15px;
  display: flex;
  justify-content: center;
  color: #5e5959;
  margin-bottom: 8px;
  margin-left: 32px;
  cursor: pointer;
}

.color-circles {
  display: flex;
  gap: 30px;
  flex-direction: row;
  margin: 30px 0px 30px 30px;
}

.circle-colour-select {
  border-style: solid;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.sizesMap {
  display: flex;
}

.size-available-units {
  border-style: solid;
  padding: 6px;
  margin-right: 15px;
  border-radius: 50%;
}

.twosections-proDetails {
  display: flex;
  flex-direction: row;
}

.rightsecProdetail {
  max-width: 50%;
  width: 100%;
  padding: 15px;
}

.product-description {
  color: #ddd;
  margin-bottom: 20px;
  font-size: 18px;
  line-height: 32px;
  max-height: 340px;
  overflow-y: scroll;
}

.leftsectionProDetail {
  max-width: 50%;
  width: 100%;
}

.image-color {
  display: flex;
  flex-direction: column;
}

.name-colour {
  font-size: 14px;
  margin-top: 10px;
}

.pro-Image-aligned {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image-color img {
  width: 30px;
  height: 37px;
  object-fit: contain;
}

.name-color {
  display: flex;
}

.colour-card-showdetails {
  padding: 5px;
  margin: 10px;
  cursor: pointer;
}

.colour-card-showdetails.selected {
  padding: 5px 5px 10px;
  cursor: pointer;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
}

.pd-table {
  background: transparent radial-gradient(closest-side at 50% 50%, #131313 0%, #000000 100%) 0% 0% no-repeat padding-box;
  border: 1px solid;
}

.pd-table table.table td,
.pd-table table.table th {
  background: unset;
  color: #fff;
  font-size: 15px;
}

.details-slider .swiper-slide img {
  height: 400px;
  object-position: top;
  width: 100%;
  object-fit: contain;
}

.rightsecProdetail p {
  font-size: 16px;
}

.swal2-actions button {
  max-width: 100px !important;
  width: 100% !important;
  margin: 0 5px !important;
  border-radius: 5px !important;
  height: 40px;
}

@media (max-width: 1500px) {
  .twosections-proDetails {
    flex-direction: column;
  }

  .leftsectionProDetail,
  .leftsectionProDetail {
    max-width: 100%;
  }

  .details-slider,
  .rightsecProdetail {
    max-width: 100%;
  }

  .color-circles {
    margin: 0;
  }

  .product-details {
    padding: 0;
  }
}

@media (max-width: 767px) {
  .four-item-goup {
    flex-direction: column;

    >div {
      flex-basis: 100%;
      width: 100%;
    }
  }
}