@font-face {
    font-family: 'Century-Bold';
    src: url('../public/fonts/CenturyGothic-Bold.eot');
    src: url('../public/fonts/CenturyGothic-Bold.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/CenturyGothic-Bold.woff2') format('woff2'),
        url('../public/fonts/CenturyGothic-Bold.woff') format('woff'),
        url('../public/fonts/CenturyGothic-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: 'Century-BoldItalic';
    src: url('../public/fonts/CenturyGothic-BoldItalic.eot');
    src: url('../public/fonts/CenturyGothic-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/CenturyGothic-BoldItalic.woff2') format('woff2'),
        url('../public/fonts/CenturyGothic-BoldItalic.woff') format('woff'),
        url('../public/fonts/CenturyGothic-BoldItalic.ttf') format('truetype'); 
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Century-Italic';
    src: url('../public/fonts/CenturyGothic-Italic.eot');
    src: url('../public/fonts/CenturyGothic-Italic.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/CenturyGothic-Italic.woff2') format('woff2'),
        url('../public/fonts/CenturyGothic-Italic.woff') format('woff'),
        url('../public/fonts/CenturyGothic-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: 'Century-Gothic';
    src: url('../public/fonts/CenturyGothic.eot');
    src: url('../public/fonts/CenturyGothic.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/CenturyGothic.woff2') format('woff2'),
        url('../public/fonts/CenturyGothic.woff') format('woff'),
        url('../public/fonts/CenturyGothic.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Felix-Titling';
    src: url('../public/fonts/FelixTitlingMT.eot');
    src: url('../public/fonts/FelixTitlingMT.eot?#iefix') format('embedded-opentype'),
        url('../public/fonts/FelixTitlingMT.woff2') format('woff2'),
        url('../public/fonts/FelixTitlingMT.woff') format('woff'),
        url('../public/fonts/Felixti.TTF') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


