
.streaming-card .card-img {
  position: relative;
  max-width: 115px;
  width: 100%;
  border-radius: 5px;
}

.streaming-card .card-img .blurImg-sec {
  box-shadow: inset 30px 30px 50px #000000cc;
  opacity: 1;
  max-height: 172px !important;
  max-width: 112px !important;
  position: relative;
  overflow: hidden;
  border-radius: 5px;
}

.streaming-card .card-img .blurImg-sec:before {
  content: "";
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
.streaming-card .card-img .profile-img {
  position: absolute;
  width: 87px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9;
}
.streaming-card .card-img .profile-img img {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.streaming-card .card-content .user-name {
  font-size: 14px;
  line-height: 18px;
  font-family: "Century-Gothic";
  word-break: break-all;
  margin: 5px 0 10px;
}
.streaming-card .card-content .user-hrs {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}
.addstreaming {
  padding: 50px 0 30px;
}
.delete-img-streaming {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 999;
  background: #000000ba;
  padding: 3px;
  border-radius: 13px;
  cursor: pointer;
  height: 26px;
  width: 26px;
}


.messege {
  line-height: 33px;
  width: 100%;
  text-align: center;
}


.todays-sec {
  padding-top: 40px;
}

.sp-page .streaming-card-sec {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 72px;
}

.streaming-card .card-img .blurImg-sec img {
  max-width: 100%;
  min-height: 172px;
  object-fit: cover;
}


.streaming-card  .card-content {
  max-width: 115px;
}

.sp-page .streaming-card-sec .streaming-card {
  cursor: pointer;
  max-width: 120px;
  width: 100%;
  margin: unset;
}

.sp-page .tab-content {
  padding: 0;
}