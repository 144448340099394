.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  margin-bottom: 40px;
  flex-wrap: wrap;
}
.dashboardChart {
  height: 500px;
}
.user-profile-sec {
  display: flex;
  align-items: center;
  flex: 0 calc(50% - 31px);
  justify-content: end;
}

.notification {
  margin-right: 30px;
  position: relative;
}

.notification .red-dot {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}

.user-profile {
  margin-right: 30px;
  position: relative;
}

.user-profile .user-name {
  font-size: 15px;
  line-height: 20px;
  margin-left: 6px;
}

.select-lang select {
  background: #000;
  border: 0.5px solid #fef6f2;
  border-radius: 10px;
  color: #fef6f2;
  padding: 5px;
  font-size: 13px;
}

.menu-bar {
  flex: 0 calc(50% - 31px);
}

.logo-sec {
  position: static;
}

@media only screen and (max-width: 1199px) {
  .user-profile .user-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 25px;
    max-width: 100px;
  }

  .user-img {
    display: inline-flex;
    align-items: center;
  }
}

@media only screen and (max-width: 991px) {
  .notification {
    margin-right: 15px;
  }

  .user-profile {
    margin-right: 15px;
  }
}

@media only screen and (max-width: 767px) {
  .user-profile .user-name {
    display: none;
  }
}

@media only screen and (max-width: 575px) {
  .user-profile {
    margin-right: 7px;
  }
  .logo-sec img {
    width: 45px;
  }
  .notification {
    margin-right: 9px;
  }

  .select-lang select {
    font-size: 10px;
    padding: 5px;
    width: 55px;
  }
}
