.check-box-list {
  padding: 0;
}

.check-box-list li label {
  display: flex;
  align-items: center;
  color: #9e9e9e;
  position: relative;
  font-size: 13px;
  line-height: 19px;
  font-family: "Century-Bold";
}

.ordenarPorOuter {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ordenarPor {
  margin-bottom: 13px;
  z-index: 999;
  position: relative;
  border-style: solid;
  max-width: 230px;
  border-width: 1px;
  padding: 10px 15px;
  border-radius: 30px;
  background: #131313;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  text-transform: capitalize;
  width: 100%;
  font-family: "Century-Bold";
}

.options-bm2 {
  width: 230px;
  height: auto;
  background: #131313 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #fef6f2;
  border-radius: 5px;
  opacity: 1;
  margin-top: -40px;
  z-index: 0;
  padding: 30px 15px 10px;
  line-height: 2.2;
  font-size: 16px;
}


.swal2-actions {
  display: flex;
  width: 100%;
}

.check-box-list li input {
  width: 0;
  height: 0;
  opacity: 0;
}

.check-box-list li label>span {
  min-width: 18px;
  height: 16px;
  display: flex;
  justify-content: center;
  border: 0.5px solid #707070;
  margin-right: 15px;
  border-radius: 3px;
  transition: all 0.3s;
  background-color: #fff;
}

.check-box-list li input:checked+label>span {
  animation: bounce 250ms;
}

.check-box-list li input:checked+label>span::before {
  content: "";
  position: absolute;
  top: 8px;
  left: 5px;
  border-right: 3px solid transparent;
  border-bottom: 3px solid transparent;
  transform: rotate(45deg);
  transform-origin: 0% 100%;
  animation: checked-box 125ms 250ms forwards;
}

@keyframes checked-box {
  0% {
    width: 0;
    height: 0;
    border-color: #212121;
    transform: translate(0, 0) rotate(45deg);
  }

  33% {
    width: 4px;
    height: 0;
    border-color: #212121;
    transform: translate(0, 0) rotate(45deg);
  }

  100% {
    width: 4px;
    height: 8px;
    border-color: #212121;
    transform: translate(0, -8px) rotate(45deg);
  }
}

@keyframes bounce {
  0% {
    transform: scale(1);
  }

  33% {
    transform: scale(0.7);
  }

  100% {
    transform: scale(1);
  }
}

.general-card {
  background: #000000 0% 0% no-repeat padding-box;
  border: 0.3px solid #fef6f261;
  border-radius: 16px;
  overflow: hidden;
  padding: 15px 20px;
  max-width: 310px;
  width: 100%;
  margin-top: 130px;
  cursor: pointer;
  height: 80% !important;
}

.general-card .card-dates-sec .created-date-sec p {
  font-size: 9px;
  line-height: 12px;
  text-align: left;
  color: #0a7a38;
  margin: 0;
  font-family: "Century-Italic";
}

.general-card .card-dates-sec {
  display: flex;
  justify-content: space-between;
  padding-left: 40px;
  position: relative;
}

.general-card .card-dates-sec .wins-date-sec p {
  font-size: 9px;
  line-height: 12px;
  text-align: right;
  color: #800b0f;
  margin: 0;
  font-family: "Century-Italic";
}

.general-card .content-sec {
  position: relative;
}

.general-card .content-sec .content-data {
  background-color: #fff;
  color: #000;
  padding: 20px 10px;
  border-radius: 5px;
  margin: 5px 0 10px;
}

.general-card .content-sec .content-data p {
  font-size: 13px;
  line-height: 17px;
  margin: 0;
  font-family: "Century-Italic";
  max-width: 170px;
  width: 100%;
  margin: 0 auto;
}

.general-card .content-sec .like-sec {
  background-color: #fff;
  color: #000;
  padding: 4px;
  border-radius: 5px;
  max-width: 31px;
  height: 34px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: -15px;
  left: 20px;
  border: 1px solid #131313;
}

.general-card .content-sec .dislike-sec {
  background-color: #fff;
  color: #000;
  padding: 4px;
  border-radius: 5px;
  max-width: 31px;
  height: 34px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: absolute;
  bottom: -15px;
  right: 20px;
  border: 1px solid #131313;
}

.general-card .content-sec svg {
  width: 25px;
  height: 25px;
  margin-bottom: 2px;
}

.general-card .content-sec span {
  font-size: 5px;
  line-height: 7px;
  display: block;
}

.general-card .card-logo {
  position: absolute;
  z-index: 9;
  left: -15px;
}

.chart-content {
  width: 100%;
  height: 100%;
  max-width: 280px;
}

.chart-content img {
  width: 100%;
  height: 100%;
}

.proposal-btns {
  margin: 30px auto 10px;
  text-align: center;
}

.bar-chart-sec {
  margin: 20px 0 10px;
}

.chart-heading {
  font-size: 13px;
  line-height: 18px;
  font-family: "Century-BoldItalic";
}

.news-header-pv {
  font-family: Felix-Titling;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 1px;
  margin-bottom: 10px;
  align-items: center;
}

.options-bm {
  width: 260px;
  height: auto;
  color: white;
  background: #131313 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #fef6f25c;
  border-radius: 21px;
  opacity: 1;
  margin-top: -22px;
  z-index: 0;
  line-height: 32px;
  padding: 7px;
  padding-left: 22px;
  outline: none;
}

.options-bm option {
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  font-style: italic;
  position: absolute;
  top: 40px;
  left: 40px;
}

.camino-seg {
  max-width: 200px;
  width: 100%;
  border: 1px solid #ffffff61;
  background: #131313 0% 0% no-repeat padding-box;
  margin: 169px auto 0;
}

.camino-head {
  display: flex;
  justify-content: center;
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 16px;
  font-family: "Century-Bold" !important;
}

.camino-items {
  padding: 13px;
  cursor: pointer;
  font-style: italic;
  font-size: 16px;
}

.camino-items.selected {
  cursor: pointer;
  background-color: #aa6e04;
}

.tab-content {
  overflow-y: scroll;
  max-height: 400px;
  /* Enable vertical scrolling */

  /* Customize scrollbar */
  scrollbar-width: thin;

  /* Webkit (Chrome, Safari, newer versions of Opera) */
  &::-webkit-scrollbar {
    width: 10px;
    /* Width of the scrollbar */
  }

  &::-webkit-scrollbar-thumb {
    background-color: #aca7a5;
    /* Color of the thumb */
    border-radius: 5px;
    /* Rounded corners of the thumb */
  }

  &::-webkit-scrollbar-track {
    background-color: #131313;
    /* Color of the track */
  }
}

.two-option-tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  gap: 20px;
  margin-top: -40px;
}

.down-arrow {
  position: absolute;
  right: 20px;
}




.tags-option-insignia {
  font-size: 13px;
  border-style: solid;
  border-width: 1px;
  border-radius: 18px;
  padding-top: 10px;
  padding-right: 15px;
  padding-bottom: 10px;
  padding-left: 25px;
  font-family: "Francois One";
  display: flex;
  flex-direction: row;
  border-color: #ffffff5e;
  z-index: 999;
  background: #181818;
  cursor: pointer;
}

.div-with-drop {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
}

.not-available-notice {
  margin-top: 10%;
}

.dropdown-1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  border-style: solid;
  border-width: 1px;
  margin-top: -34px;
  border-top-color: rgb(0 0 0 / 29%);
  border-right-color: rgba(255, 255, 255, 0.29);
  border-bottom-color: rgba(255, 255, 255, 0.29);
  border-left-color: rgba(255, 255, 255, 0.29);
  width: 100%;
  padding-top: 35px;
  z-index: 100;
  position: absolute;
  padding-bottom: 0px;
  padding-left: 0px;
  background: #67676717;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(11px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  top: 50px;
  animation: fadeIn 0.2s ease-in;
  font-family: "Francois One";
}

.dropdown-new1 {
  font-size: 15px;
  border-style: solid;
  border-width: 1px;
  margin-top: -34px;
  border-top-color: rgb(0 0 0 / 29%);
  border-right-color: rgba(255, 255, 255, 0.29);
  border-bottom-color: rgba(255, 255, 255, 0.29);
  border-left-color: rgba(255, 255, 255, 0.29);
  width: 100%;
  padding-top: 35px;
  z-index: 100;
  position: absolute;
  padding-bottom: 7px;
  padding-left: 20px;
  background: #67676717;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(11px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  top: 50px;
  animation: fadeIn 0.2s ease-in;
  font-family: "Francois One";
}

.dropdown-new2 {
  font-size: 11px;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  top: 50px;
  margin-top: -34px;
  border-top-color: rgb(0 0 0 / 29%);
  border-right-color: rgba(255, 255, 255, 0.29);
  border-bottom-color: rgba(255, 255, 255, 0.29);
  border-left-color: rgba(255, 255, 255, 0.29);
  width: 100%;
  padding-top: 31px;
  z-index: 100;
  padding-bottom: 15px;
  padding-left: 6px;
  background: #67676717;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(11px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  animation: fadeIn 0.2s ease-in;
  font-family: "Francois One";
}

.dropdown-2 {
  font-size: 11px;
  position: absolute;
  border-style: solid;
  border-width: 1px;
  top: 329px;
  margin-top: -34px;
  border-top-color: rgb(0 0 0 / 29%);
  border-right-color: rgba(255, 255, 255, 0.29);
  border-bottom-color: rgba(255, 255, 255, 0.29);
  border-left-color: rgba(255, 255, 255, 0.29);
  width: 140px;
  padding-top: 31px;
  z-index: 100;
  padding-bottom: 15px;
  padding-left: 6px;
  background: #67676717;
  -webkit-backdrop-filter: blur(3px);
  backdrop-filter: blur(11px);
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  cursor: pointer;
  animation: fadeIn 0.2s ease-in;
  font-family: "Francois One";
}






@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.input-class {
  width: 100% !important;
  background-color: white !important;
  height: 30px !important;
  opacity: 1 !important;
  outline: none;
}

.date-input-am {
  max-width: 60px;
  max-height: 20px;
  font-size: 8px;
}

.p-tag {
  color: #9e9e9e;
  font-size: 13px;
  line-height: 19px;
  font-family: "Century-Bold";
  margin-bottom: 1px;
}

.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.loading-spinner-container p {
  margin-bottom: 0px;
}

.loading-spinner {
  border: 5px solid #f3f3f3;
  border-top: 5px solid #000000;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin-right: 12px;
  display: flex;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.proposal-page .general-card {
  height: 100% !important;
  margin-top: 20px;
  margin-bottom: 20px;
  max-height: 440px;
}

.general-tab-sec .row {
  justify-content: center;
}


.general-card {
  margin: auto;
}

.proposal-btn-sec>.row {
  justify-content: center;
}


.toi-dropdown {
  min-width: 140px;
}


.div-with-drop>p {
  margin: 0;
  min-width: 130px;
  padding: 10px 42px 10px 12px;
  position: relative;
  min-height: 36px;
  width: 100%;
}