.billboard-sec {
  padding: 50px 0 20px;
}

.myTeamBanner {
  text-align: center;
  cursor: pointer;
}

.input-for-edit {
  background: transparent;
  color: white;
  padding: 5px;
  font-size: 14px;
  width: 300px;
  border-color: transparent;
  text-transform: uppercase;
  font-weight: 600;
  margin-bottom: 20px;
}

.billboard-cards {
  max-width: 350px;
  width: 100%;
  margin-bottom: 40px;
}

video.video-grid-view {
  height: 400px !important;
  width: auto !important;
  object-fit: none !important;
  border-radius: 0% !important;
  border-style: none !important;
  object-fit: contain !important;
}

video.video-grid-view2 {
  height: 400px !important;
  width: 300px !important;
  object-fit: contain !important;
  border-radius: 0% !important;
  border-style: none !important;
}

.billboard-cards .card-img {
  height: 190px;
  border-radius: 13px;
  overflow: hidden;
}

.billboard-cards .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.billboard-cards h3 {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}

.billboard-sec-inner2 {
  height: 700px;
  overflow-x: hidden;
  overflow-y: auto;
}

.billboard-btn-sec {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px 5px 10px;
  width: 100%;
}

.file-input-sec .fileUpload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.file-input-sec .fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: 0;
  left: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  max-width: 100%;
}

.file-input-sec .btn--browse {
  border: 1px solid #3a3838;
  outline: none;
  cursor: pointer;
}

.file-input-sec .btn--browse:hover {
  border: 1px solid #3a3838;
  cursor: pointer;
}

.file-input-sec .f-input {
  width: 100%;
  max-width: 400px;
  padding: 10px 14px;
  text-align: center;
  cursor: pointer;
  background: transparent;
}

.file-input-sec .f-input::placeholder {
  text-align: center;
}

.editorBannerSec {
  height: 510px;
}

.editorBannerSec .billboard-cards .card-img {
  border-radius: 12px;
  overflow: hidden;
}

.editorBannerSec .billboard-cards .card-img img {
  width: 100%;
}

.editorBannerSec .myTeamBanner .card-body {
  margin: 20px 0;
}

.editorBannerSec .myTeamBanner .card-body h5 {
  font-size: 16px;
  line-height: 20px;
  font-family: "Century-Bold";
}

.editorBannerSec .myTeamBanner .card-body p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}

.card-heading1 {
  font: normal normal normal 16px/21px Francois One;
  letter-spacing: 0px;
  display: flex;
  justify-content: center;
}

.current-banner {
  background: #cb8b15;
  opacity: 1;
}

/* -------- edit section --------- */
.editable-sec {
  background-color: #bdbdbd;
  color: #131313;
  padding: 10px 15px;
  border: 1px solid #000;
  border-radius: 8px;
  overflow: hidden;
  font-family: "Century-Bold" !important;
}

.editnewscard-img {
  max-width: 350px;
  height: 200px;
  margin: 20px auto 0;
  position: relative;
  border-radius: 13px;
  overflow: hidden;
}

.editnewscard-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.placeholder2 {
  font-size: 16px;
  color: #3e3434b3;
  margin-top: 14px;
  margin-bottom: 7px;
}

.loadmorebutton {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  cursor: pointer !important;
  text-decoration: underline !important;
}

.search-tag-bar {
  max-width: 160px;
  border-radius: 30px;
  padding: 8px 15px;
  font-size: 13px;
  font-family: "Century-Gothic";
}

.search-icon-click {
  cursor: pointer;
  display: flex;
}

.img-edit-icon {
  margin-top: 30px;
}

.custom-add-img {
  height: 140px;
}

.custom-add-img img {
  height: 135px !important;
  object-fit: contain !important;
}

.custom-video-grid {
  min-height: 140px !important;
}

.custom-add-card {
  height: 190px;
}

.custom-add-card h3 {
  font-size: 20px;
  text-transform: uppercase;
}

.bboardSec {
  padding: 50px 0 !important;
}

.bboardSec .lista-content {
  overflow: auto;
}

.bboardSec .billboard-cards {
  max-width: 350px;
  width: 100%;
  margin: 0 auto 20px;
  cursor: pointer;
}

.bboardSec .lista-content .billboard-sec-inner2 {
  max-height: 500px !important;
  width: 900px;
}

.bboardSec .lista-name .card-img {
  min-width: 70px;
}

.bboardSec .current-banner .swiper-slide {
  background: unset;
}

.bboardSec .custom-add-card {
  background: unset;
  border: unset;
}

.bboardSec .swiper-button-prev:after,
.bboardSec .swiper-button-next:after {
  font-weight: 500;
  font-size: 20px;
}

body .bboardSec .swiper-slide .video-grid-view.custom-video-grid {
  height: 100% !important;
  width: 100% !important;
  min-height: unset !important;
  object-fit: cover !important;
}

.bboardSec .custom-add-card {
  background: unset;
  border: unset;
  padding: 34px;
}

.bboardSec .current-banner {
  padding: 0;
}

.bboardSec .custom-add-card h3 {
  font-size: 14px;
  color: #fff;
}

.bb-page .billboard-cards h3 {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  top: var(--swiper-navigation-top-offset, 50%);
  width: calc(var(--swiper-navigation-size) / 44 * 27);
  height: var(--swiper-navigation-size);
  margin-top: calc(0px - (var(--swiper-navigation-size) / 2));
  z-index: 10;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white !important;
}

.swiper-pagination-bullet-active {
  opacity: var(--swiper-pagination-bullet-opacity, 1);
  background: #ffffff !important;
}