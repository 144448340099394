.billboard-sec {
  padding: 50px 0;
}

.calendar-list-sec {
  padding: 5px 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 400px;
  width: 1000px;
}

.cal-list-card {
  /* height: 72px; */
  width: 891px;
}

.myTeamBanner {
  text-align: center;
}

.billboard-cards {
  max-width: 350px;
  width: 100%;
  margin-bottom: 40px;
}

.billboard-cards h3 {
  font-size: 16px;
  line-height: 21px;
  font-weight: 600;
}

.billboard-sec-inner {
  height: 700px;
  overflow-x: hidden;
  overflow-y: scroll;
}

.billboard-btn-sec {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 30px 5px 10px;
}

.two-box {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 35px;
  margin-top: 35px;
}

.box-bm-1 {
  width: 85px;
  background-color: transparent;
  height: 88px;
  font-size: 9px;
  padding: 8px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0px;
  margin-right: 7px;
}

.box-bm-2 {
  width: 85px;
  background-color: transparent;
  height: 88px;
  font-size: 9px;
  padding: 8px 3px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 0px;
  margin-right: 7px;
}

.logo-content {
  display: flex;
  justify-content: center;
  padding-left: 16px;
  padding-right: 16px;
  flex-direction: column;
}

.header-title-bm {
  font-family: "Felix Titling";
  color: #fef6f2;
  text-transform: uppercase;
  font-weight: 100;
  font-size: 21px;
}

.cal-list-card .left-sec .event-data h4 {
  font-size: 19px;
  line-height: 14px;
  font-family: "Century-BoldItalic";
  letter-spacing: 0px;
  font-weight: 200;
  color: #131313;
}

.cal-list-card .left-sec .event-data p {
  font-size: 16px;
  line-height: 13px;
  font-family: "Century-Italic";
  margin: 0;
}

.news-header-bm {
  display: flex;
  justify-content: space-between;
  max-width: 908px;
  width: 100%;
  margin: 0 auto;
}

.calendar-list-sec-bm {
  padding: 0px 0px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 433px;
  margin-top: 0px;
  width: 950px;
  margin-bottom: 100px;
}

.select-bm {
  width: 233px;
  height: 40px;
  background: #131313 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #fef6f2;
  color: white;
  border-radius: 20px;
  opacity: 1;
  font-size: 16px;
  display: flex;
  justify-content: left;
  align-items: center;
  padding-left: 20px;
  z-index: 999;
  cursor: pointer;
}

.option-bm {
  width: 233px;
  height: 244px;
  background: #131313 0% 0% no-repeat padding-box;
  border: 1px solid #fef6f2;
  border-radius: 5px;
  opacity: 1;
}

.search-icon-bm {
  margin-right: 22px;
  cursor: pointer;
}

.arr-down-bm {
  width: 30px;
  color: white;
}

.comments-sec-bm {
  display: flex;
  justify-content: center;
  flex-direction: column;
  z-index: 0;
  margin-left: 135px;
}

.options-bm {
  width: 233px;
  height: auto;
  background: #131313 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #fef6f2;
  border-radius: 5px;
  opacity: 1;
  margin-top: -22px;
  z-index: 0;
  padding-top: 6px;
  line-height: 32px;
  padding-left: 22px;
}

.options-bm p {
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  font-style: italic;
}

::-webkit-scrollbar.calendar-list-sec-bm {
  width: 12px;
  /* Set the width of the scrollbar */
  background-color: #f1f1f1;
  /* Set the background color of the scrollbar track */
  border-radius: 6px;
  /* Set the border radius of the scrollbar track */
}

::-webkit-scrollbar-thumb.calendar-list-sec-bm {
  background-color: #131313;
  /* Set the color of the scrollbar thumb */
  border-radius: 6px;
  /* Set the border radius of the scrollbar thumb */
}

::-webkit-scrollbar-thumb:hover.calendar-list-sec-bm {
  background-color: #555;
  /* Set the color of the scrollbar thumb on hover */
}

/* .blur-form-sec{
    border-radius: 26px;
    opacity: 1;
    backdrop-filter: blur(21px);
    -webkit-backdrop-filter: blur(21px);
    border: 1px solid #FEF6F2;
    padding: 10px 30px;
} */
.blur-form-sec form {
  width: 100%;
}

.blur-form-sec label {
  display: block;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 5px;
}

.blur-form-control {
  margin-bottom: 15px;
}

.blur-form-sec .blur-input {
  width: 100%;
  border-radius: 50px;
  background-color: #131313;
  border: 0;
  padding: 5px 20px;
  color: #fff;
  font-size: 14px;
}

.blur-btn-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.blur-btn-sec .green-btn {
  background: linear-gradient(180deg, #096a31 0%, #0a9042 100%) 0% 0% no-repeat padding-box;
  max-width: 180px;
  border-radius: 50px;
}

.blur-btn-sec .red-btn {
  max-width: 180px;
  border-radius: 50px;
}

.msger {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-flow: column wrap;
  flex-flow: column wrap;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  max-width: 595px;
  width: 100%;
  /* height: calc(100% - 50px); */
  background-color: #131313;
  border: 1px solid #fef6f2;
}

.partcpt-section {
  border: 0;
}

.chatPage-sec p {
  margin: 0;
}

.msger .msger-header-options button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex-positive: 0;
  flex-grow: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  overflow: hidden;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  background-color: transparent;
  border-color: transparent;
  text-align: left;
  border-width: 0px;
  width: 40px;
  height: 40px;
  border-radius: 20px;
  margin-right: 8px;
  padding: 0;
  cursor: pointer;
  border-style: solid;
}

.participants-list {
  padding: 0;
  margin: 0;
}

.participants-list li .partcp-profile {
  padding: 15px 5px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.partcp-profile .partcp-img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  overflow: hidden;
}

.partcp-profile .partcp-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.partcp-profile .partcp-name {
  font-size: 13px;
  line-height: 20px;
  margin: 0 5px;
}

.partcp-profile .partcp-admin {
  font-size: 11px;
}

.partcp-profile .partcp-profile {
  width: 17px;
}

.partcp-profile .partcp-profile img {
  width: 100%;
  height: 100%;
}

.msger .input-form {
  border-top: 1px solid #fef6f2;
}

.msger .input-form>p {
  font-size: 12px;
  margin-bottom: 10px;
}

.msger .input-form>p a {
  text-transform: none;
  color: #3c80d2;
}

.msger .input-form-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  gap: 15px;
  border-top: 1px solid #fef6f2;
  align-items: center;
}

.msger .input-form-wrap form {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  flex: auto;
  position: relative;
  margin-top: -1px;
}

.msger .input-form-wrap form input[type="text"] {
  background-color: #131313;
  padding: 13px 13px 13px 50px;
  border: none;
  width: 100%;
  box-sizing: border-box;
  color: white;
}

.msger .input-form-wrap form input[type="text"]:focus {
  outline: none;
  border: none;
}

.msger .input-form-wrap form .msger-attachment-btn {
  position: absolute;
  top: 13px;
  border: none;
  background: none;
}

.msger .input-form-wrap form .msger-attachment-btn.left-btn {
  left: 10px;
  top: 10px;
}

.msger .input-form-wrap form .msger-attachment-btn.left-btn svg {
  color: #fff;
  font-size: 30px;
}

.msger .input-form-wrap form .msger-attachment-btn.right-btn {
  right: 10px;
}

.msger .input-button-wrap button {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eeedf1;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  border: none;
}

.msger-header {
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: center;
  padding: 15px 15px;
  border-bottom: var(--border);
  border-bottom: 1px solid #fef6f2;
  gap: 25px;
}

.msger-chat {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  overflow-y: auto;
  padding: 10px 70px 10px 25px;
  position: relative;
  max-height: 60vh;
}

.msger-chat .msg-img {
  width: 40px;
  height: 40px;
}

.msger-chat .msg-img img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}

.msger-chat .msg {
  position: relative;
  margin-bottom: 10px;
}

.msger-chat .msg-img {
  width: 40px;
  height: 40px;
  background: #ddd;
  border-radius: 50%;
  position: absolute;
  top: 0;
  max-width: 650px;
}

.msger-chat .msg-time {
  font-size: 12px;
  color: #8a8d91;
  display: block;
  margin-bottom: 3px;
}

.msger-chat .msg-bubble .msg-text {
  padding: 10px 12px;
  margin-bottom: 1px;
  font-size: 14px;
  display: inline-block;
}

.msger-chat .msg-bubble .msg-text p {
  margin: 0;
}

.msger-chat .msg-bubble .msg-text a {
  color: inherit;
}

.msger-chat .msg-bubble .msg-quote p {
  font-style: italic;
  padding-left: 15px;
}

.msger-chat .msg-bubble .msg-quote span {
  font-size: 12px;
  display: block;
  padding-left: 15px;
  font-weight: 300;
}

.msger-chat .msg-item {
  margin-bottom: 15px;
}

.msger-chat .left-msg {
  padding-left: 60px;
  text-align: left;
}

.msger-chat .left-msg .msg-img {
  left: 0;
}

.msger-chat .left-msg .msg-item .msg-bubble .msg-text {
  background: #1b6fb7;
  border-radius: 10px;
}

/* .msger-chat .left-msg .msg-item .msg-bubble:last-of-type .msg-text {
    border-top-left-radius: 10px;
} */

.msger-chat .right-msg {
  text-align: right;
}

.msger-chat .right-msg .msg-img {
  right: 0;
  margin-right: -55px;
}

.msger-chat .right-msg .msg-item .msg-bubble .msg-text {
  background: #fff;
  border-radius: 10px;
  color: #000;
}

/* .msger-chat .right-msg .msg-item .msg-bubble:first-of-type .msg-text {
    border-top-right-radius: 10px;
} */

.msger-chat::-webkit-scrollbar {
  width: 6px;
}

.msger-chat::-webkit-scrollbar-track {
  background: #ddd;
}

.msger-chat::-webkit-scrollbar-thumb {
  background: #bdbdbd;
}

.participatns-sec {
  max-width: 235px;
  width: 100%;
  position: relative;
  flex-flow: column wrap;
  height: 100%;
  background-color: #131313;
  border: 1px solid #fef6f2;
}

.tabGallerySec {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
}

.tabGallery-sec {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 30px 0px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 400px;
}

.tabGallery-sec img,
.tabGallery-sec video {
  width: 100%;
  height: 100%;
}

.tabGallerySec .tabgalleryList {
  max-width: 310px;
  width: 100%;
  margin: 20px auto;
  border: 0;
  border-bottom: 0;
  background-color: #cb8b15;
  border-radius: 50px;
}

.tabgalleryList .nav-link.active {
  background: #fef6f2;
  color: #000;
  border-radius: 50px;
  border: 1;
  font-size: 15px;
  line-height: 20px;
}

.tabgalleryList.nav-tabs .nav-link.active,
.tabgalleryList.nav-tabs .nav-item.show .nav-link {
  border: 0;
  font-size: 15px;
  line-height: 20px;
}

.tabGallerySec .tabgalleryList .nav-link {
  background: transparent;
  font-size: 15px;
  line-height: 20px;
  font-family: "Century-Bold";
  padding: 5px 20px;
  color: #fff;
  border: 0;
  border-radius: 50px;
}

.tabGallerySec .tabgalleryList .nav-link:hover {
  font-size: 15px;
  line-height: 20px;
  border-color: none;
  background: #fef6f2;
  color: #000;
  border-radius: 50px;
}

@media (max-width: 600px) {

  .msger .input-form-wrap {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }

  .msger-header {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    gap: 15px;
    padding-top: 20px;
  }
}

.boardMeeting-popup .modal-content {
  max-width: 900px;
}

.boardMeeting-popup .modal-footer .form-group {
  justify-content: space-between !important;
  width: 100%;
}

.boardMeeting-popup .modal-footer .form-group button {
  max-width: 130px;
  font: 12px;
}

.blur-form-list>img:first-child {
  margin-right: 5px;
}

.profile-img {
  border-radius: 20px;
  padding: 0 20px;
  width: 25%;
}

.profile-img24 {
  border-radius: 20px;
  padding: 0 0px;
  width: 15%;
  margin-right: 15px;
}

.profile-img24 img {
  width: 65px;
  height: 65px;
  border-radius: 50%;
  border-width: 0px;
  object-fit: cover;
}

.profile-img2 {
  border-radius: 20px;
  padding: 0 20px;
  width: 15%;
  padding-right: 78px;
}

.event-data {
  margin-left: 50px;
}

.add-users-list.add-list-active {
  display: block;
  position: absolute;
  top: auto;
  left: 50%;
  transform: translateX(-50%);
  z-index: 9999999999;
}

.add-users-list {
  max-width: 330px;
  width: 100%;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: 1s;
  z-index: 99;
  background: #00000096;
  border-radius: 1px 0px 26px 26px;
  box-shadow: 1px 1px 1px #000;
  display: none;
  height: 250px;
}

.two-box img {
  width: 44px;
  height: 23px;
  object-fit: contain;
  margin: 0 auto 5px;
}

.logo-content {
  padding: 0;
  text-align: center;
  font-size: 8px;
  margin: 0;
}

.chat_img {
  width: 180px;
}

.add-member-icon {
  border: 1px solid white;
  border-radius: 50%;
}

.add-users-list.add-member-active {
  display: block;
  position: absolute;
  top: 60%;
  left: 50%;
  transform: translateX(-50%);
}

.debate-page .cal-list-card {
  margin-bottom: 0;
}

.debate-page .cal-list-card {
  width: 100%;
  padding: 10px;
}

.debate-page .chatPage-sec p {
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
}

.debate-page .participants-list li .partcp-profile:not(:first-child) {
  padding: 20px 10px 0;
}

.debate-page .member-searchBox input {
  background: #131313;
  color: #fff;
  padding: 5px 10px;

  font-size: 16px;
  width: 100%;
  border: 1px solid #fff;
  border-width: 0 0px 1px 0px;
}

.debate-page .add-member-icon svg {
  width: 18px;
  height: 18px;
}

.debate-page .partcp-profile .partcp-img {
  overflow: auto;
  width: unset;
  border-radius: unset;
  height: unset;
}

.debate-page .partcp-name {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  overflow: hidden;
  width: 130px;
  font-size: 13px;
}

.debate-page .partcp-profile .partcp-img>img {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
}

.partcp-profile .partcp-img {
  width: auto;
  height: auto;
  border-radius: unset;
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.debate-page .participants-list {
  padding: 0;
  margin: 0;
  overflow: auto;
  height: calc(100% - 82px);
  padding-bottom: 5px;
}

.debate-page .msger-chat .msg-img {
  top: auto;
  bottom: 0;
  width: 24px;
  height: 24px;
  margin: 0;
}

.debate-page .msg-text p {
  text-align: left;
}

.debate-page .msger-chat .left-msg {
  padding-left: 40px;
  text-align: left;
}

.debate-page .msger-chat {
  padding: 10px 25px 10px 25px;
  height: calc(100% - 107px);
  max-height: 100%;
}

.msger-chat .right-msg {
  padding-right: 40px;
}

.debate-page .msger {
  display: unset;
  position: relative;
  max-width: 595px;
  width: 100%;
  height: calc(100% - 50px);
  background-color: #131313;
  height: 600px;
}

.debate-page .msger .input-form {
  height: 55px;
  position: absolute;
  width: 100%;
  bottom: 0;
}

.debate-page h3.header-title {
  font-size: 21px;
  font-weight: 700;
}

.debate-page .legend-tabs>.nav-tabs li {
  max-width: 208px;
}

.debate-page .legend-tabs>.nav-tabs {
  border-bottom: unset;
  justify-content: center;
  gap: 20px;
}

body .debate-page .legend-tabs>.nav-tabs li button {
  border-bottom: 3px solid #131313 !important;
  font-size: 19px;
  font-weight: 700;
}

body .debate-page .legend-tabs>.nav-tabs li button.active {
  border-bottom: 3px solid #eba51a !important;
}

.legend-tabs .nav-tabs .nav-link.active::before {
  content: unset;
}

.debate-page .add-users-list {
  border-radius: 26px;
  max-width: 400px;
  height: 350px;
}

.debate-page .legend-box ul {
  height: 100%;
  overflow: auto;
}

.debate-page .legendBtn {
  height: 50px;
}

.debate-page .legend-box {
  height: 100%;
  padding: 15px 15px 50px 15px;
}

.highlight {
  border-radius: 5px;
  background: #ffffff29;
}

.previewMedia {
  width: 80px;
  min-width: 80px;
  height: 80px;
  object-fit: cover;
  object-position: top;
  border-radius: 10px;
  padding: 1px;
  background: #fff;
  margin: 0 5px;
}

.previewMedia {
  position: absolute;
  bottom: 54px;
  width: calc(100% - 7px);
  padding: 10px;
  background: #131313;
  box-shadow: rgb(255 255 255 / 28%) 0px 0px 2px;
  border-radius: 10px 10px 0 0;
  display: flex;
  overflow: auto;
  max-width: 600px;
}

.msger-chat .right-msg .msg-text {
  position: relative;
}

.msger-chat .right-msg .msg-text:before {
  display: block;
  clear: both;
  content: "";
  position: absolute;
  bottom: 10px;
  right: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 9px 15px 8px;
  border-color: transparent transparent #fff transparent;
  -webkit-transform: rotate(37deg);
  -ms-transform: rotate(37deg);
  transform: rotate(2deg);
}

.msger-chat .left-msg .msg-text {
  position: relative;
}

.msger-chat .left-msg .msg-text:before {
  display: block;
  clear: both;
  content: "";
  position: absolute;
  bottom: 10px;
  left: -9px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 8px 15px 9px;
  border-color: transparent transparent #25762e transparent;
  transform: rotate(2deg);
}

.msger-chat .msg-bubble .msg-text p {
  word-break: break-all;
}

.user-add-members img {
  width: 40px;
  border-radius: 50%;
}

.group-icon {
  width: 60px;
  height: 10vh;
}

.tabGallerySec .tabgalleryList>li {
  max-width: 33.33%;
  width: 100%;
  text-align: center;
}

.tabGallerySec .tabgalleryList>li button {
  width: 100%;
}

.tabGallerySec .tabgalleryList>li button.active {
  background-color: #fff !important;
  color: #000 !important;
}

.tabGallery-sec .img-card>img,
.tabGallery-sec .img-card>video {
  width: 80px;
  height: 80px;
  padding: 0 3px;
}

.group-user-list {
  margin: 225px auto;
  height: 500px !important;
  overflow: auto;
}

/* modal */

.todas-card {
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  align-items: center;
  padding: 5px;
  margin-bottom: 30px;
}

.todas-card .left-sec {
  display: flex;
}

.todas-card .left-sec .profile-img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  overflow: hidden;
}

.todas-card .left-sec .profile-content {
  margin-left: 20px;
  padding: 8px 0;
  margin-right: 20px;
}

.todas-card .left-sec .profile-content h5 {
  font-size: 19px;
  line-height: 22px;
  margin: 0 0 3px 0;
  color: #131313;
  font-family: "Century-BoldItalic";
}

.todas-card .left-sec .profile-content p {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #131313;
  font-family: "Century-Italic";
}

.digital-heading {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  align-items: center;
  margin-bottom: 30px;
}

.digital-heading h3 {
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
}

.tabs-cards-sec .nav-tabs .nav-item .nav-link {
  color: #fff;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  border: 0;
}

.tabs-cards-sec .nav-tabs .nav-link.active,
.tabs-cards-sec .nav-tabs .nav-item.show .nav-link {
  background: transparent;
  border-bottom: 3px solid #eba51a;
}

.tabs-cards-sec .nav-tabs {
  border: 0;
  margin-bottom: 50px;
}

.back-sec {
  cursor: pointer;
}

.gems {
  display: flex;
  align-items: center;
  color: #fff;
}

.membersias-sec {
  margin: 0 auto;
  max-width: 240px;
  width: 100%;
}

.gems>img {
  padding-right: 15px;
}

.available-membership {
  margin-top: 100px;
  max-width: 235px;
  background-color: #131313;
  color: #fff;
}

.available-membership>h6 {
  text-align: center;
  padding: 10px 15px;
  border: 1px solid #fef6f2;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0;
}

.available-body {
  border: 1px solid #fef6f2;
  padding: 20px 25px;
  list-style-type: none;
  margin-bottom: 0;
}

.available-body>li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.available-body>li>span {
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
}

.available-body>li>span>img {
  width: 16px;
  margin-right: 11px;
}

.available-body>li:not(:last-child) {
  margin-bottom: 30px;
}

.digital-leader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100%;
}

.digital-leader>h6 {
  font-style: italic;
  color: #fff;
}

.digital-leader> :nth-child(1),
.digital-leader> :last-child {
  width: 20%;
  text-align: right;
  font-style: italic;
}

.digital-leader>div:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.digital-leader .center-dl {
  width: 80%;
}

.center-head {
  text-align: center;
}

.center-head>img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 50%;
}

.center-head>h3 {
  font-size: 19px;
  line-height: 20px;
  margin-top: 13px;
}

.personal-info {
  max-width: 350px;
  margin: 70px auto 0;
}

.personal-info>h4 {
  font-size: 19px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.personal-data {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fef6f2;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.personal-data>span {
  width: 50%;
}

.personal-data>span {
  font-size: 11px;
  font-style: italic;
}

.personal-data>span:nth-child(2) {
  color: #fef6f2;
}

.available-badge {
  text-align: center;
  color: #fff;
}

.available-badge>h4 {
  font-size: 19px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.available-badge h6 {
  font-size: 9px;
  line-height: 15px;
  color: #eba51a;
  text-transform: uppercase;
}

.pr-hier {
  margin-bottom: 35px;
}

.available-badge .row>div {
  margin-bottom: 30px;
  padding: 0 5px;
  position: relative;
}

.first-row {
  position: relative;
}

.row.first-row>div {
  margin-bottom: 45px;
}

.row.first-row>div:before {
  content: "";
  position: absolute;
  bottom: -27px;
  left: -1px;
  right: 0;
  background: #fff;
  width: 1px;
  height: 18px;
  margin: 0 auto;
}

.sec-row {
  position: relative;
}

.row.sec-row:before {
  content: "";
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
  background: #fff;
  width: calc(100% - 33.33%);
  height: 1px;
  margin: 0 auto;
}

.available-badge .row.sec-row>div:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1px;
  height: 18px;
  background: #fff;
}

.available-badge .row.sec-row>div:nth-child(1):before,
.available-badge .row.sec-row>div:nth-child(2):before,
.available-badge .row.sec-row>div:nth-child(3):before {
  top: -17px;
}

.disable {
  opacity: 0.6;
}

.digi-profile-sec {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.digi-profile-sec .gems {
  justify-content: center;
  padding: 20px 0;
}

.gems-movements-sec .gems-movements {
  text-align: center;
  margin: 5px 0 20px;
}

.gems-movements-sec {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

.gems-movements-sec .gems-card {
  display: flex;
  margin: 0 0 10px 0;
  justify-content: space-between;
}

.gems-movements-sec .gems-card .left-side {
  display: flex;
}

.gems-movements-sec .gems-card .left-side .img-sec {
  width: 40px;
  height: 40px;
}

.gems-movements-sec .gems-card .left-side img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gems-movements-sec .gems-card .details-sec {
  margin-left: 20px;
}

.gems-movements-sec .gems-card .details-sec h5 {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 3px 0;
}

.gems-movements-sec .gems-card .details-sec p {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.gems-movements-sec .gems-card .increasing {
  font-size: 19px;
  line-height: 20px;
  color: #1dbe2e;
  margin-bottom: 5px;
}

.decreasing {
  font-size: 19px;
  line-height: 20px;
  color: #ff0707;
  margin-bottom: 5px;
}

.gems-movements-sec .gems-card .sub-hed {
  font-size: 12px;
  color: #fef6f2;
  opacity: 0.6;
  text-align: center;
}

.dis-fade .lock-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  left: 50%;
}

.mt-34 {
  margin-top: 34px;
}

.Chat-box {
  display: none;
}

.ActiveClass {
  display: block;
}

.acc-head {
  display: flex;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  padding: 11px 18px;
  background-image: linear-gradient(#cb8b15, #fdd300);
  text-transform: uppercase;
}

.acc-head .country-assets {
  display: flex;
  align-items: center;
}

.acc-head p {
  margin-bottom: 0;
  font-size: 19px;
  line-height: 32px;
}

.acc-head .country-assets p {
  font-size: 23px;
}

.club-list li {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border: 1px solid #fef6f2;
  margin: 2px 0;
  border-left: unset;
  border-right: unset;
  position: relative;
}

.club-list li:hover {
  border-color: #cb8b15;
}

.club-list li:hover::before {
  content: "";
  position: absolute;
  left: 1px;
  width: 1px;
  height: 100%;
  top: 0;
  background-color: #cb8b15;
  bottom: 0;
  z-index: -1;
}

.club-list li:hover::after {
  content: "";
  position: absolute;
  right: 1px;
  width: 1px;
  height: 100%;
  top: 0;
  background-color: #cb8b15;
  bottom: 0;
  z-index: -1;
}

.cardInfo span {
  font-size: 12px;
  margin-left: 5px;
}

.cardDate span {
  font-size: 12px;
  margin-left: 5px;
}

.digitales-list {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 400px;
  margin: 0 auto 20px;
  flex-wrap: wrap;
  padding: 0;
}

.digitales-list .dl-text {
  max-width: 80px;
  color: #fff;
  font-size: 8px;
  margin: 10px auto 0;
}

.digitales-list li {
  flex: 0 1 20%;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 80px;
  height: 95px;
}

.digitales-list li:hover {
  background-color: #cb8b15;
}

.club-planes a {
  color: #cb8b15;
  font-size: 16px;
  font-style: italic;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.cardInfo {
  margin-bottom: 7px;
}

.first-list {
  border-radius: 0 0 20px 20px;
}

.club-centerText {
  /* background: #916E1C; */
  /* filter: blur(4px); */
  /* z-index: -1; */
  position: relative;
}

.club-centerText::before {
  content: "";
  position: absolute;
  background: #916e1c;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(20px);
  transform: scale(1.2);
}

.second-list .club-centerText {
  width: calc(100% - 50px);
  text-align: center;
}

.second-list .club-centerText::before {
  display: none;
}

.diamond-member span,
.membership-price span {
  font-size: 14px;
}

.first-list {
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border: 1px solid #fef6f2;
  border-top: unset;
}

.acc-head-outer.active .club-planes {
  display: none;
}

.acc-head-outer.active .club-centerText {
  width: 100%;
  text-align: center;
}

.acc-head-outer.active .club-centerText::before {
  display: none;
}

.club-list {
  padding: 0;
  overflow: hidden;
  width: 0;
  max-height: 0;
  transition: max-height 0.15s ease-out;
}

.acc-head-outer.active+.acc-body .club-list {
  width: 100%;
  max-height: 100%;
  transition: max-height 0.25s ease-in;
}

.acc-head-outer.active .first-list {
  border-radius: 0;
}

.black-bg {
  font-size: 10px;
  background: #000000;
  border: 1px solid #fef6f2;
  border-radius: 5px;
  color: #fef6f2;
  margin-bottom: 20px;
}

.comment-box {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  align-items: center;
}

span.profile-desc {
  display: block;
}

.reciver-left img {
  width: 28px;
  height: 28px;
}

.profile-text {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  justify-content: center;
}

.profile-time {
  width: 40px;
}

.social-comment {
  display: flex;
  gap: 10px;
  font-size: 13px;
  background: #fef6f2;
  margin: 0;
  padding: 7px 15px;
  color: #000;
  margin-top: 30px;
}

.social-comment li span {
  margin-left: 5px;
}

.social-comment li img {
  width: 15px;
}

.transparent-bg {
  padding-left: 50px;
  background: transparent;
  border: unset;
}

.transparent-bg .social-comment {
  background: transparent;
  color: #fef6f2;
  margin-top: 0;
}

.diamond-acc-head .acc-head {
  background: linear-gradient(#1b6fb7, #4b9fe8);
}

.club-list {
  border-left: 1px solid #fef6f2;
  border-right: 1px solid #fef6f2;
}

.club-list li:last-child {
  margin: 0;
}

.token-box {
  padding: 15px 30px;
}

.green-text p,
.red-text p {
  color: #25762e;
  margin: 0;
  font-size: 12px;
}

.red-text p {
  color: #800b0f;
}

.white-box {
  background: #fff;
  color: #000;
  padding: 15px 20px;
  border-radius: 7px;
  font-size: 17px;
  margin-bottom: 10px;
  line-height: 1.1;
  font-style: italic;
  position: relative;
}

.tb-top-sec {
  padding-left: 50px;
}

.token-box button.red-btn {
  min-width: 120px;
  padding: 5px;
}

.dt-logo {
  display: inline-block;
  position: absolute;
  top: -40px;
  left: -30px;
}

.tb-bottom-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.green-clr {
  color: #25762e;
  font-style: italic;
}

.digital-tabel {
  border: 1px solid #fff;
}

.digital-tabel ul {
  padding: 0;
}

.digital-tabel {
  border: 1px solid #fff;
}

.digital-tabel ul {
  padding: 0;
}

.digital-table {
  display: flex;
  border: 1px solid #fff;
  flex-wrap: wrap;
  background: #131313;
  height: 500px;
  overflow: auto;
}

.digitalTable-left {
  width: 30%;
  border-right: 1px solid #fff;
}

.digital-right {
  width: 70%;
}

.digitalTable-head {
  padding: 12px;
  border-bottom: 1px solid #fff;
  min-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.digitalTable-left ul.digital-tudo-list {
  padding: 20px 30px;
}

.digitalTable-left ul.digital-tudo-list li a {
  font-size: 13px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.digitalTable-left ul.digital-tudo-list li {
  margin-bottom: 20px;
}

span.tudo-counter {
  padding: 3px 10px;
  border: 1px solid #fef6f2;
  border-radius: 8px;
}

.digitalRight-body {
  font-size: 12px;
}

span.tudo-counter {
  padding: 3px 10px;
  border: 1px solid #fef6f2;
  border-radius: 8px;
}

ul.Digiright-table li {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.digitalRight-body {
  font-size: 12px;
}

.digiRight-content-outer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding: 10px;
}

ul.Digiright-table li:last-child.digiRight-content-outer {
  border-bottom: unset;
}

.digiRight-content {
  display: flex;
  gap: 8px;
}

.checkbox-div {
  padding: 12px;
  min-height: 40px;
  border-right: 1px solid #fff;
}

.digihead-left,
.digitalId-div {
  min-width: 90px;
  text-align: left;
}

.digihead-right {
  width: calc(100% - 90px);
  text-align: left;
}

.digitalInner-table .digitalId-div {
  padding: 12px;
  border-bottom: 1px solid #fff;
}

.digipost-sec {
  padding: 22px 15px;
}

.digitalRight-body .comment-box-outer {
  max-width: 350px;
  margin: 0 auto;
}

.ds-text {
  margin-bottom: 35px;
}

.white-comment-box {
  max-width: 350px;
  margin: 0 auto;
  background: #fff;
  color: #000;
  border-radius: 30px;
  padding: 10px;
  font-size: 10px;
}

.white-comment-box span.profile-name {
  font-size: 12px;
  font-weight: 600;
}

.white-comment-box .profile-text {
  font-style: italic;
}

.white-comment-box .profile-time span {
  font-weight: 700;
}

.digitalInner-table {
  margin-top: 20px;
}

.digitalInner-table .comment-box-outer {
  margin-bottom: 20px;
}

.right-img {
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.todas-card-list .todas-card-content {
  height: 0;
  overflow: hidden;
  z-index: -99;
  position: relative;
}

.todas-card-list {
  margin-bottom: 30px;
}

.todas-card-list.active {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
  left: 0;
  right: 0;
  bottom: 0;
}

.todas-card-main {
  position: relative;
  height: 100px;
}

.todas-card-list.active .todas-card-content {
  display: flex;
  box-shadow: 2px 2px 3px #000000;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  transition: 1s;
  z-index: 99;
  background: #0000007d;
  border: 1px solid #fef6f2;
  border-radius: 15px;
  margin-top: -36px;
  padding: 136px 85px;
  z-index: -1;
  position: relative;
  height: 439px;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 15px);
  transition: 0.5s all;
}

.todas-card-content .tc-Maintitle p {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-style: italic;
}

.tc-Semititle {
  margin: 30px 0;
}

ul.verification-requirements li {
  font-size: 13px;
  margin-bottom: 15px;
}

.tc-profile {
  display: inline-block;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
}

.tc-profile img {
  cursor: pointer;
}

.tc-footer {
  font-size: 10px;
}

.tc-footer p {
  margin-bottom: 5px;
}

.tc-conditions {
  display: flex;
  position: absolute;
  bottom: 18px;
  gap: 30px;
  right: 40px;
}

.tc-conditions img {
  min-width: 50px !important;
  height: 50px !important;
}

.tc-conditions p {
  display: block;
  text-align: center;
}

.todas-card-list .todas-three-item .right-sec img {
  opacity: 1;
}

.todas-card-list.active .todas-three-item .right-sec img {
  opacity: 0;
}

.todas-three-item .left-sec .profile-img {
  padding: 0 !important;
}

.todas-img img {
  cursor: pointer;
}

.right-sec {
  margin-bottom: 0px;
  display: flex;
}

.tabs-cards-sec .nav-tabs .nav-item .nav-link {
  color: #fff;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  border: 0;
}

.tabs-cards-sec .nav-tabs .nav-link.active,
.tabs-cards-sec .nav-tabs .nav-item.show .nav-link {
  background: transparent;
  border-bottom: 3px solid #eba51a;
}

.tabs-cards-sec .nav-tabs {
  border: 0;
}

.tab-content {
  overflow-y: scroll !important;
  max-height: 520px !important;
  height: 520px !important;
  /* scrollbar-width: thin; */
}

.dashboard-container {
  padding-top: 0px !important;
  padding-right: 15px !important;
  padding-bottom: 50px !important;
  padding-left: 15px !important;
}

.tabs-section {
  overflow: auto;
}

.right-sec h3 {
  font-size: 19px;
  line-height: 14px;
  font-family: "Century-BoldItalic";
  margin: 0;
  color: #000;
}

.loading-adjust {
  background: transparent;
  display: flex;
  height: 32px;
  justify-content: center;
}

.event-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 30px 5px 10px;
}

.debateroom-popup .modal-dialog {
  max-width: 900px;
  width: 100%;
}

.Digital-sec .right-sec .left-sec {
  justify-content: end;
  text-align: right;
}

.debateroom-popup .modal-body input {
  background-color: #131313;
  border: 1px solid #4f4f4f;
  border-radius: 15px;
  width: 100%;
  min-height: 40px;
  color: white;
}

.preview-img-icon {
  width: 100px;
}

.debateroom-popup .modal-body label {
  margin-bottom: 15px;
  display: block;
}

.mb-30 {
  margin-bottom: 30px;
}

.debateroom-popup .modal-body {
  padding: 20px 25px;
}

.debateroom-popup .modal-content {
  background: #fef6f2 0% 0% no-repeat padding-box;
  box-shadow: 1px 1px 1px #000000;
  border: 0.30000001192092896px solid #fef6f2;
  border-radius: 26px;
  opacity: 1;
  backdrop-filter: blur(21px) !important;
  -webkit-backdrop-filter: blur(21px);
}

.cover-photo {
  display: flex;
  flex-wrap: wrap;
}

.cover-photo>div {
  flex: 0 50%;
}

.members-box {
  background-color: #131313;
  border-radius: 17px;
  max-width: 350px;
  padding: 10px 18px;
  height: 200px;
}

.members-list {
  margin: 8px 0;
  padding: 0;
  height: 110px;
  overflow: auto;
  cursor: pointer;
}

.memberlistItem.selected {
  background-color: #555425;
}

.memberlistItem {
  width: 100%;
  height: 32px;
  object-fit: cover;
  cursor: pointer;
  font-size: 13px;
  padding-left: 10px;
}

.members-list img {
  width: 25px;
  height: 32px;
  object-fit: cover;
  cursor: pointer;
}

.members-head h6 {
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  padding-bottom: 6px;
  border-bottom: 1px solid #fff;
}

.members-list li span {
  font-size: 13px;
  padding-left: 10px;
}

.members-body .blue-btn {
  font-size: 12px;
  text-transform: lowercase;
  padding: 3px;
}

.debateroom-popup .news-btn-sec button {
  font-size: 14px;
  padding: 5px;
  max-width: 125px;
  width: 100%;
  height: 40px;
}

.add-file {
  position: relative;
  width: 105px;
}

.add-file input {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.enlaces-box {
  max-width: 355px;
  width: 100%;
  background: #131313;
  border-radius: 5px;
  border: 1px solid #fff;
}

.enlaces-first-box {
  display: flex;
  color: #fff;
  align-items: center;
  border-style: solid;
  width: 70%;
}

.campass-img {
  padding: 13px;
  background-color: #000;
  border-right: 1px solid #fff;
}

.enlaces-title h6 {
  font-size: 15px;
  line-height: 21px;
  font-weight: 700;
}

.enlaces-content p {
  font-size: 15px;
  line-height: 21px;
  font-style: italic;
}

.enlaces-text {
  padding: 6px 9px;
}

.enlaces-second-box {
  background: #fef6f2;
  padding: 11px;
  text-align: center;
  width: 70%;
}

.enlaces-second-box a {
  text-decoration: none;
  color: #131313b3;
  font-style: italic;
  font-size: 15px;
  line-height: 21px;
}

.enlaces-second-box a {
  display: flex;
  justify-content: space-between;
}

.send-message-button-style {}

.profile-img26 {
  border-radius: 20px;
  padding: 0 0px;
  width: 15%;
  padding-right: 75px;
}

.profile-img26 img {
  width: 59px;
  height: 7vh;
  border-radius: 50%;
}

.card_sec_list .cal-list-card {
  height: auto;
}

@media screen and (max-width: 1399px) {
  .card_sec_list .event-data {
    text-align: center !important;
  }

  .card_sec_list .left-sec,
  .card_sec_list .right-sec {
    gap: 10px;
  }

  .card_sec_list .profile-img24 {
    margin-right: 15px;
    margin: 0;
  }

  .card_sec_list .profile-img24 {
    width: auto;
  }
}