.todas-card {
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  align-items: center;
  padding: 5px;
  margin-bottom: 30px;
  margin-right: 15px;
  position: relative;
  z-index: 999;
}

.todas-card .left-sec {
  display: flex;
}

.todas-card .left-sec .profile-img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  overflow: hidden;
  border-color: #000000a3;
  border-style: solid;
  /* padding: 5px; */
  border-width: 1.5px;
}
.profile-img img {
  width: 100%;
  height: 7vh;
  border-radius: 50%;
  border-width: 0px;
  object-fit: cover;
}

.todas-card .left-sec .profile-content {
  margin-left: 20px;
  padding: 8px 0;
}

.todas-card .left-sec .profile-content h5 {
  font-size: 19px;
  line-height: 22px;
  margin: 0 0 3px 0;
  color: #131313;
  font-family: "Century-BoldItalic";
}

.todas-card .left-sec .profile-content p {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #131313;
  font-family: "Century-Italic";
}

.digital-heading {
  display: flex;
  justify-content: space-between;
  padding-top: 90px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-left: 10px;

  align-items: center;
  margin-bottom: 30px;
}

.digital-heading h3 {
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
}

.tabs-cards-sec .nav-tabs .nav-item .nav-link {
  color: #fff;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  border: 0;
}

.tabs-cards-sec .nav-tabs .nav-link.active,
.tabs-cards-sec .nav-tabs .nav-item.show .nav-link {
  background: transparent;
  border-bottom: 3px solid #eba51a;
}

.tabs-cards-sec .nav-tabs {
  border: 0;
  margin-bottom: 50px;
}

.back-sec {
  cursor: pointer;
}

.gems {
  display: flex;
  align-items: center;
  color: #fff;
}

.membersias-sec {
  margin: 0 auto;
  max-width: 240px;
  width: 100%;
}

.gems > img {
  padding-right: 15px;
}

.available-membership {
  margin-top: 100px;
  max-width: 235px;
  background-color: #131313;
  color: #fff;
}

.available-membership > h6 {
  text-align: center;
  padding: 10px 15px;
  border: 1px solid #fef6f2;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0;
}

.available-body {
  border: 1px solid #fef6f2;
  padding: 20px 25px;
  list-style-type: none;
  margin-bottom: 0;
}

.available-body > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.available-body > li > span {
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
}

.available-body > li > span > img {
  width: 16px;
  margin-right: 11px;
}

.available-body > li:not(:last-child) {
  margin-bottom: 30px;
}

.digital-leader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100%;
}

.digital-leader > h6 {
  font-style: italic;
  color: #fff;
}

.digital-leader > :nth-child(1),
.digital-leader > :last-child {
  width: 20%;
  text-align: right;
  font-style: italic;
}

.digital-leader > div:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.digital-leader .center-dl {
  width: 80%;
}

.center-head {
  text-align: center;
}

.center-head > img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 50%;
}

.center-head > h3 {
  font-size: 19px;
  line-height: 20px;
  margin-top: 13px;
}

.personal-info {
  max-width: 350px;
  margin: 70px auto 0;
}

.personal-info > h4 {
  font-size: 19px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.personal-data {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fef6f2;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.personal-data > span {
  width: 50%;
}

.personal-data > span {
  font-size: 11px;
  font-style: italic;
}

.personal-data > span:nth-child(2) {
  color: #fef6f2;
}

.available-badge {
  text-align: center;
  color: #fff;
}

.available-badge > h4 {
  font-size: 19px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.available-badge h6 {
  font-size: 9px;
  line-height: 15px;
  color: #eba51a;
  text-transform: uppercase;
}

.pr-hier {
  margin-bottom: 35px;
}

.available-badge .row > div {
  margin-bottom: 30px;
  padding: 0 5px;
  position: relative;
}

.first-row {
  position: relative;
}

.row.first-row > div {
  margin-bottom: 45px;
}

.row.first-row > div:before {
  content: "";
  position: absolute;
  bottom: -27px;
  left: -1px;
  right: 0;
  background: #fff;
  width: 1px;
  height: 18px;
  margin: 0 auto;
}

.sec-row {
  position: relative;
}

.row.sec-row:before {
  content: "";
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
  background: #fff;
  width: calc(100% - 33.33%);
  height: 1px;
  margin: 0 auto;
}

.available-badge .row.sec-row > div:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1px;
  height: 18px;
  background: #fff;
}

.available-badge .row.sec-row > div:nth-child(1):before,
.available-badge .row.sec-row > div:nth-child(2):before,
.available-badge .row.sec-row > div:nth-child(3):before {
  top: -17px;
}

.disable {
  opacity: 0.6;
}

.digi-profile-sec {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.digi-profile-sec .gems {
  justify-content: center;
  padding: 20px 0;
}

.gems-movements-sec .gems-movements {
  text-align: center;
  margin: 5px 0 20px;
}

.gems-movements-sec {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

.gems-movements-sec .gems-card {
  display: flex;
  margin: 0 0 10px 0;
  justify-content: space-between;
}

.gems-movements-sec .gems-card .left-side {
  display: flex;
}

.gems-movements-sec .gems-card .left-side .img-sec {
  width: 40px;
  height: 40px;
}

.gems-movements-sec .gems-card .left-side img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gems-movements-sec .gems-card .details-sec {
  margin-left: 20px;
}

.gems-movements-sec .gems-card .details-sec h5 {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 3px 0;
}

.gems-movements-sec .gems-card .details-sec p {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.gems-movements-sec .gems-card .increasing {
  font-size: 19px;
  line-height: 20px;
  color: #1dbe2e;
  margin-bottom: 5px;
}

.decreasing {
  font-size: 19px;
  line-height: 20px;
  color: #ff0707;
  margin-bottom: 5px;
}

.gems-movements-sec .gems-card .sub-hed {
  font-size: 12px;
  color: #fef6f2;
  opacity: 0.6;
  text-align: center;
}

.dis-fade .lock-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  left: 50%;
}

.mt-34 {
  margin-top: 34px;
}

.Chat-box {
  display: none;
}

.ActiveClass {
  display: block;
}

.acc-head {
  display: flex;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  padding: 11px 18px;
  background-image: linear-gradient(#cb8b15, #fdd300);
  text-transform: uppercase;
}

.acc-head .country-assets {
  display: flex;
  align-items: center;
}

.acc-head p {
  margin-bottom: 0;
  font-size: 19px;
  line-height: 32px;
}

.acc-head .country-assets p {
  font-size: 23px;
}

.club-list li {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border: 1px solid #fef6f2;
  margin: 2px 0;
  border-left: unset;
  border-right: unset;
  position: relative;
}

.club-list li:hover {
  border-color: #cb8b15;
}

.club-list li:hover::before {
  content: "";
  position: absolute;
  left: 1px;
  width: 1px;
  height: 100%;
  top: 0;
  background-color: #cb8b15;
  bottom: 0;
  z-index: -1;
}

.club-list li:hover::after {
  content: "";
  position: absolute;
  right: 1px;
  width: 1px;
  height: 100%;
  top: 0;
  background-color: #cb8b15;
  bottom: 0;
  z-index: -1;
}

.cardInfo span {
  font-size: 12px;
  margin-left: 5px;
}

.cardDate span {
  font-size: 12px;
  margin-left: 5px;
}

.club-planes a {
  color: #cb8b15;
  font-size: 16px;
  font-style: italic;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.cardInfo {
  margin-bottom: 7px;
}

.first-list {
  border-radius: 0 0 20px 20px;
}

.club-centerText {
  /* background: #916E1C; */
  /* filter: blur(4px); */
  /* z-index: -1; */
  position: relative;
}

.club-centerText::before {
  content: "";
  position: absolute;
  background: #916e1c;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(20px);
  transform: scale(1.2);
}

.second-list .club-centerText {
  width: calc(100% - 50px);
  text-align: center;
}

.second-list .club-centerText::before {
  display: none;
}

.diamond-member span,
.membership-price span {
  font-size: 14px;
}

.first-list {
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border: 1px solid #fef6f2;
  border-top: unset;
}

.acc-head-outer.active .club-planes {
  display: none;
}

.acc-head-outer.active .club-centerText {
  width: 100%;
  text-align: center;
}

.acc-head-outer.active .club-centerText::before {
  display: none;
}

.club-list {
  padding: 0;
  overflow: hidden;
  width: 0;
  max-height: 0;
  transition: max-height 0.15s ease-out;
}

.acc-head-outer.active + .acc-body .club-list {
  width: 100%;
  max-height: 100%;
  transition: max-height 0.25s ease-in;
}

.acc-head-outer.active .first-list {
  border-radius: 0;
}

.black-bg {
  font-size: 10px;
  background: #000000;
  border: 1px solid #fef6f2;
  border-radius: 5px;
  color: #fef6f2;
  margin-bottom: 20px;
}

.comment-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  align-items: center;
  height: fit-content;
}

span.profile-desc {
  display: block;
}

.reciver-left img {
  width: 28px;
  height: 28px;
  border-radius: 30px;
}

.profile-text {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  font-size: 12px;
}

.profile-time {
  width: 40px;
}

.social-comment {
  display: flex;
  gap: 10px;
  font-size: 13px;
  background: #fef6f2;
  margin: 0;
  padding: 7px 15px;
  color: #000;
  margin-top: 30px;
}

.social-comment li span {
  margin-left: 5px;
}

.social-comment li img {
  width: 15px;
}

.transparent-bg {
  padding-left: 50px;
  background: transparent;
  border: unset;
}

.transparent-bg .social-comment {
  background: transparent;
  color: #fef6f2;
  margin-top: 0;
}

.diamond-acc-head .acc-head {
  background: linear-gradient(#1b6fb7, #4b9fe8);
}

.club-list {
  border-left: 1px solid #fef6f2;
  border-right: 1px solid #fef6f2;
}

.club-list li:last-child {
  margin: 0;
}

.token-box {
  padding: 15px 30px;
}

.green-text p,
.red-text p {
  color: #25762e;
  margin: 0;
  font-size: 9px;
  margin-bottom: 5px;
}

.red-text p {
  color: #800b0f;
}

.white-box {
  background: #fff;
  color: #000;
  padding: 15px 20px;
  border-radius: 7px;
  font-size: 17px;
  margin-bottom: 10px;
  line-height: 1.1;
  font-style: italic;
  position: relative;
}

.tb-top-sec {
  padding-left: 50px;
}

.token-box button.red-btn {
  min-width: 100px;
  padding: 4px;
  font-size: 12px;
}

.dt-logo {
  display: inline-block;
  position: absolute;
  top: -40px;
  left: -30px;
}

.green-clr {
  color: #25762e;
  font-style: italic;
}
.verification-badge {
  height: 55px !important;
  width: 60px !important;
  padding: 11px !important;
  position: absolute !important;
  top: 348px !important;
  left: 968px !important;
}
.tb-bottom-sec {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-top: 20px;
}
.dropdown {
  background: #5b58581f;
  width: 98%;
  height: 400px;
  margin-top: -94px;
  border-radius: 28px;
  backdrop-filter: blur(23px);
  position: relative;
  z-index: 100;
}
