.check-box-list {
    padding: 0;
}

.check-box-list li label {
    display: flex;
    align-items: center;
    color: #9e9e9e;
    position: relative;
    font-size: 13px;
    line-height: 19px;
    font-family: 'Century-Bold';
}

.check-box-list li input {
    width: 0;
    height: 0;
    opacity: 0;
}

.check-box-list li label>span {
    min-width: 18px;
    height: 16px;
    display: flex;
    justify-content: center;
    border: 0.5px solid #707070;
    margin-right: 15px;
    border-radius: 3px;
    transition: all .3s;
    background-color: #fff;
}

.check-box-list li input:checked+label>span {
    animation: bounce 250ms;
}

.check-box-list li input:checked+label>span::before {
    content: '';
    position: absolute;
    top: 8px;
    left: 5px;
    border-right: 3px solid transparent;
    border-bottom: 3px solid transparent;
    transform: rotate(45deg);
    transform-origin: 0% 100%;
    animation: checked-box 125ms 250ms forwards;
}



@keyframes checked-box {
    0% {
        width: 0;
        height: 0;
        border-color: #212121;
        transform: translate(0, 0) rotate(45deg);
    }

    33% {
        width: 4px;
        height: 0;
        border-color: #212121;
        transform: translate(0, 0) rotate(45deg);
    }

    100% {
        width: 4px;
        height: 8px;
        border-color: #212121;
        transform: translate(0, -8px) rotate(45deg);
    }
}

@keyframes bounce {
    0% {
        transform: scale(1);
    }

    33% {
        transform: scale(.7);
    }

    100% {
        transform: scale(1);
    }
}


.general-card {
    background: #000000 0% 0% no-repeat padding-box;
    border: 0.3px solid #FEF6F2;
    border-radius: 16px;
    overflow-x: hidden;
    padding: 15px 20px;
    max-width: 310px;
    width: 100%;
    height: 420px;
    overflow-y: auto;
}

.general-card .card-dates-sec .created-date-sec p {
    font-size: 9px;
    line-height: 12px;
    text-align: left;
    color: #0A7A38;
    margin: 0;
    font-family: 'Century-Italic';
}

.general-card .card-dates-sec {
    display: flex;
    justify-content: space-between;
    padding-left: 40px;
    position: relative;
}

.general-card .card-dates-sec .wins-date-sec p {
    font-size: 9px;
    line-height: 12px;
    text-align: right;
    color: #800B0F;
    margin: 0;
    font-family: 'Century-Italic';
}

.general-card .content-sec {
    position: relative;
}

.general-card .content-sec .content-data {
    background-color: #fff;
    color: #000;
    padding: 20px 10px;
    border-radius: 5px;
    margin: 5px 0 10px;
}

.general-card .content-sec .content-data p {
    font-size: 13px;
    line-height: 17px;
    margin: 0;
    font-family: 'Century-Italic';
    max-width: 170px;
    width: 100%;
    margin: 0 auto;
}

.general-card .content-sec .like-sec {
    background-color: #fff;
    color: #000;
    padding: 4px;
    border-radius: 5px;
    max-width: 31px;
    height: 34px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: -15px;
    left: 20px;
    border: 1px solid #131313;
}

.general-card .content-sec .dislike-sec {
    background-color: #fff;
    color: #000;
    padding: 4px;
    border-radius: 5px;
    max-width: 31px;
    height: 34px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: absolute;
    bottom: -15px;
    right: 20px;
    border: 1px solid #131313;
}

.general-card .content-sec svg {
    width: 25px;
    height: 25px;
    margin-bottom: 2px;
}

.general-card .content-sec span {
    font-size: 5px;
    line-height: 7px;
    display: block;
}

.general-card .card-logo {
    position: absolute;
    z-index: 9;
    left: -15px;
}

.chart-content {
    width: 100%;
    height: 100%;
    max-width: 280px;
}

.chart-content img {
    width: 100%;
    height: 100%;
}

.proposal-btns {
    margin: 30px auto 10px;
    text-align: center;
}

.bar-chart-sec {
    margin: 20px 0 10px;
}

.chart-heading {
    font-size: 13px;
    line-height: 18px;
    font-family: 'Century-BoldItalic';
}


.proposal-dropdown {
    text-align: center;
    margin-top: 80px;
}

.proposal-dropdown button#dropdown-basic {
    max-width: 235px;
    width: 100%;
    font-size: 16px;
    text-align: left;
    color: #fff;
    z-index: 2;
    position: relative;
}

body .proposal-dropdown .dropdown-menu {
    max-width: 235px;
    min-width: 235px;
    width: 100%;
    text-align: left;
    top: -21px !important;
    z-index: 1;
    padding-top: 25px;
    background: #131313;
}

.proposal-dropdown button#dropdown-basic::after {
    display: none;
}

body .proposal-dropdown a:hover {
    color: #CB8B15 !important;
}

.mt-80 {
    margin-top: 80px;
}

.mt-100 {
    margin-top: 100px;
}

.lock-icon-wrapper {
    position: relative;
}

.general-card .card-img img {
    width: calc(100% + 40px);
    margin: -15px -20px 25px;
}


.propuestas-dropdown {
    display: flex;
    justify-content: center;
    padding-bottom: 40px;
    gap: 20px;
}

body .propuestas-dropdown .dropdown-toggle {
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

body .propuestas-dropdown .dropdown-item {
    white-space: break-spaces;
}


.propuestas-dropdown .dropdown-toggle::after {
    display: none;
}


ul.praposal-options-list li {
    max-width: 350px;
    margin: 0 auto 17px;

}

ul.praposal-options-list li input {
    width: 100%;
    border: 0;
    background: transparent;
    font-size: 13px;
    background: #FEF6F2;
    border: 1px solid #707070;
    border-radius: 5px;
    box-shadow: inset 0px 3px 6px #00000029;
    min-height: 40px;
    padding: 12px 20px;
    font-weight: 700;
    font-style: italic;
}

ul.praposal-options-list li input::placeholder {
    color: #131313;

    font-weight: 400;
}


ul.praposal-options-list li input:focus-visible {
    outline: none;
}
.card-imgs img {
    width: 100%;
    margin-bottom: 10px;
    height: 130px;
    object-fit: cover;
    object-position: center center;
}

.voteAndProposal .general-card {
    max-height: 620px;
    height: 100%;
}
.card-imgs {
    margin: -15px -20px 10px;
}
.vp-height{
    width: 110%;
    height: 620px;
}
span.edit-sec {
    font-family: 'Century-Gothic';
    font-size: small;
}
.modal-title-pv{
    font-size: 21px;
    font-family: 'Francois One';
}
.modal-content{
    background-color: #00000051 !important;
    backdrop-filter: blur(15px) !important;
    border-style: solid !important;
    border-color: rgba(255, 255, 255, 0.429) !important;
}
.modal-header {
    border-bottom: var(--bs-modal-header-border-width) solid transparent !important;
}
.btn-close {
    color: white !important;
}
.common-form-control input {
    font-size: 16px;
    line-height: 20px;
    font-family: 'Century-Italic';
    margin: 0;
    max-width: 640px;
    width: 100%;
    border: 0;
    padding: 5px 10px;
    outline: 0;
    text-align: center;
    border-radius: 10px;
}
.common-form-control-options {
    max-width: 890px;
    width: 100%;
    background-color: #fff;
    border-radius: 39px;
    text-align: center;
    padding: 8px;
    margin-bottom: 35px;
    color: #131313;
    cursor: pointer;
}