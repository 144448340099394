.billboard-page .billboard-sec {
  padding: 50px 60px;
}

.table-transparent {
  background-color: transparent !important;
}

.myTeamBanner.billboard-cards:hover {
  background: #3232326b;
  transition-duration: 0.2s;
  border-radius: 1rem;
}

.billboard-page .myTeamBanner {
  text-align: left;
  transition-duration: 0.2s;
}

.images-display-separate {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.input-changecat-img {
  width: 1px;
  height: 1px;
  opacity: "0";
}

.upload-img-form svg {
  cursor: pointer;
}

.delete-sec-table.cat-action svg {
  cursor: pointer;
}

.upload-img-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-right-style: solid;
  padding-right: 22px;
  border-width: 1px;
}

.billboard-cards {
  transition-duration: 0.2s;
  max-width: 350px;
  width: 100%;
  margin-bottom: 40px;
}

.virtual-store-head-3 {
  margin-top: -43px !important;
}

.billboard-cards .card-img {
  height: 190px;
  border-radius: 13px;
  overflow: hidden;
}

.billboard-cards .card-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.billboard-cards h3 {
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
  text-transform: uppercase;
}

.billboard-sec-inner {
  height: auto !important;
  overflow-x: hidden !important;
  overflow-y: hidden !important;
  max-height: 700px !important;
}

.head-desc {
  max-width: 360px;
  padding: 10px 21px;
}

.billboard-btn-sec {
  display: flex;
  justify-content: center;
  gap: 30px;
  padding: 18px 5px 10px;
}

.file-input-sec .fileUpload {
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.file-input-sec .fileUpload input.upload {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: block;
  margin: 0;
  left: 0;
  padding: 0;
  font-size: 20px;
  cursor: pointer;
  opacity: 0;
  height: 100%;
  width: 100%;
  z-index: 1;
  max-width: 100%;
}

.delete-sec-table {
  max-width: 100px;
  width: 10%;
}

.file-input-sec .btn--browse {
  border: 1px solid #3a3838;
  outline: none;
  cursor: pointer;
}

.file-input-sec .btn--browse:hover {
  border: 1px solid #3a3838;
  cursor: pointer;
}

.file-input-sec .f-input {
  width: 100%;
  max-width: 400px;
  padding: 10px 14px;
  text-align: center;
  cursor: pointer;
}

.file-input-sec .f-input::placeholder {
  text-align: center;
}

.editorBannerSec {
  height: 510px;
}

.editorBannerSec .billboard-cards .card-img {
  border-radius: 12px;
  overflow: hidden;
}

.editorBannerSec .billboard-cards .card-img img {
  width: 100%;
}

.editorBannerSec .myTeamBanner .card-body {
  margin: 20px 0;
}

.editorBannerSec .myTeamBanner .card-body h5 {
  font-size: 16px;
  line-height: 20px;
  font-family: "Century-Bold";
}

.editorBannerSec .myTeamBanner .card-body p {
  margin: 0;
  font-size: 14px;
  line-height: 18px;
}

.card-heading {
  font: normal normal normal 16px/21px Francois One;
  letter-spacing: 0px;
}

.current-banner {
  background: #cb8b15;
  opacity: 1;
  margin-bottom: 20px;
}

/* -------- edit section --------- */
.editable-sec {
  background-color: #bdbdbd;
  color: #131313;
  padding: 10px 15px;
  border: 1px solid #000;
  border-radius: 8px;
  overflow: hidden;
  font-family: "Century-Bold" !important;
}

.editnewscard-img {
  max-width: 350px;
  height: 200px;
  margin: 20px auto 0;
  position: relative;
  border-radius: 13px;
  overflow: hidden;
}

.editnewscard-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.lista-content .myTeamBanner {
  width: 100%;
  max-width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
}

.lista-content .myTeamBanner {
  padding: 4px;
  margin-bottom: 0;
  align-items: center;
}

.lista-content .myTeamBanner .card-img img {
  width: 50px;
  height: 51px;
  object-fit: contain;
  padding-left: 6px;
}

.edit-icon-table svg {
  width: 25px;
  height: 25px;
}

.billboard-sec-inner4 {
  height: 700px !important;
  overflow-y: hidden;
  overflow-x: hidden;
}

.lista-content .myTeamBanner .card-img {
  width: auto;
  height: auto;
}

.lista-content .lista-titles {
  display: flex;
  /* flex-wrap: wrap; */
  font-size: 12px;
  border-top: 1px solid #fff;
  border-bottom: 1px solid #fff;
  border-right: 1px solid #fff;
  position: sticky;
  width: 100%;
  top: 0;
  z-index: 9;
  background: #060606;
  margin-bottom: 6px;
  max-width: 100%;
  overflow: auto;
}

.lista-content .lista-titles>div {
  padding: 13px 20px;
  border-left: 1px solid #fff;
  display: flex;
  font-style: italic;
}

.lista-content .lista-name {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 35%;
  padding: 0 20px;
}

.lista-creation {
  width: 25%;
  padding: 0 20px;
}

.lista-modification {
  width: 17%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
}

.lista-right {
  width: 20%;
  padding: 0 20px;
}

.lista-content .billboard-cards h3 {
  text-transform: uppercase;
  font-weight: 500;
}

.second-sec .bboardSec .lista-content h3 {
  font-size: 16px;
}

.second-sec .bboardSec .lista-content p {
  font-size: 14px;
}

.lista-content h3,
.lista-content p {
  margin: 0;
}

.card-description {
  font-size: 12px;
}

.add-offer {
  cursor: pointer;
  text-align: end;
  width: 5%;
  padding-right: 10px;
}

.lista-right select {
  outline: none;
  border-color: black;
  border-radius: 0px;
  font-size: 16px;
  padding: 6px;
  font-family: "Francois One";
}

.select-tag-offer {
  /* width: 420px; */
  scroll-behavior: smooth;
  outline: none;
  display: grid;
  grid-template-columns: auto auto auto;
  margin-bottom: 10px;
  max-height: 133px;
  overflow-y: scroll;
}

.seperate-option-tag {
  margin-bottom: 10px;
  color: white;
}

.dialog-box-mui {
  background: black;
}

.btton-remove-offer {
  width: 100%;
}

.delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-img-product-list {
  width: 87px !important;
  height: 87px !important;
  min-width: 87px !important;
  overflow: hidden;
  border-radius: 50%;
  border-style: solid;
}

.card-img-product-list img {
  width: 100%;
  /* Make the image fill the container */
  height: 100%;
  /* Make the image fill the container */
  object-fit: cover;
  /* Maintain the aspect ratio and cover the container */
  border-radius: 50%;
}

.selectedOffer-percentage {
  border-bottom: solid;
  border-bottom-color: black;
  width: 30px;
}

.lista-content .myTeamBanner {
  width: 100%;
  max-width: 100%;
  display: flex;
  /* flex-wrap: wrap; */
}

.lista-content .myTeamBanner {
  padding: 10px;
  margin-bottom: 0;
  align-items: center;
}

.lista-content .myTeamBanner .card-img img {
  width: 70px;
  height: 70px;
  object-fit: cover;
  /* border-style: solid; */
}

.lista-content .myTeamBanner .card-img {
  width: auto;
  height: auto;
}

.lista-content .lista-name {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 33%;
  padding: 0 20px;
}

.lista-creation {
  width: 25%;
  padding: 0 20px;
}

.lista-modification {
  width: 17%;
  padding: 0 20px;
  display: flex;
  justify-content: center;
}

.lista-right {
  width: 20%;
  padding: 0 20px;
}

.lista-content .billboard-cards h3 {
  text-transform: uppercase;
  font-weight: 500;
}

.card-description {
  font-size: 12px;
}

.lista-right select {
  outline: none;
  border-color: black;
  border-radius: 0px;
  font-size: 16px;
  padding: 6px;
  font-family: "Francois One";
}

.seperate-option-tag {
  margin-bottom: 10px;
}

.dialog-box-mui {
  background: black;
}

.btton-remove-offer {
  width: 100%;
}

.delete-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 25px;
}

.card-img-product-list img {
  width: 100%;
  /* Make the image fill the container */
  height: 100%;
  /* Make the image fill the container */
  object-fit: cover;
  /* Maintain the aspect ratio and cover the container */
  border-radius: 50%;
}

.selectedOffer-percentage {
  border-bottom: solid;
  border-bottom-color: black;
  width: 30px;
}

.store-popup {
  font-family: "Francois One" !important;
}

.store-popup .green-btn,
.store-popup .green-btn:hover {
  background: linear-gradient(#096a31, #0a9042);
  font-family: "Francois One";
  color: #fff;
}

.store-popup .red-btn,
.store-popup .red-btn:hover {
  background: linear-gradient(#800b0f, #af0b11);
  font-family: "Francois One";
  color: #fff;
}

.input-div {
  margin-bottom: 15px;
}

.input-div input {
  font-family: "Francois One";
}

.input-div label {
  font-family: "Francois One";
  border-bottom-color: white;
  color: white;
}

.add-offer-outer .blue-btn {
  margin-bottom: 25px;
  display: inline-block !important;
  max-width: unset;
  width: auto;
  font-size: 14px !important;
  text-transform: capitalize;
  letter-spacing: 1px;
  border-radius: 4px;
  margin-left: 0px;
  background: linear-gradient(#096a31, #0a9042);
  font-family: "Francois One" !important;
}

.offers-section-display {
  display: flex;
  flex-direction: column;
}

.btton-remove-offer .red-btn {
  font-size: 14px !important;
  text-transform: capitalize;
  letter-spacing: 1px;
  border-radius: 4px;
  width: 100%;
  max-width: 100%;
  background: linear-gradient(#800b0f, #af0b11);
}

.css-348fk2.Mui-focused {
  color: rgb(255, 255, 255) !important;
}

.css-12rx5qu::after {
  border-bottom: 2px solid rgb(255, 255, 255) !important;
  color: white;
  left: 0px;
  bottom: 0px;
  content: "";
  position: absolute;
  right: 0px;
  transform: scaleX(0);
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.store-popup .MuiPaper-elevation {
  max-width: 550px;
  width: 100%;
  background: #0000008b;
  color: #fff;
  box-shadow: 2px 2px 3px #000000;
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
  transition: 1s;
  z-index: 99;
  background: #ffffff14;
  border: 1px solid #fef6f2;
  border-radius: 30px;
  padding: 30px;
}

.store-popup input {
  color: #fff;
  border-color: #fff;
}

.store-popup .css-7obqij-MuiInputBase-root-MuiInput-root::after {
  border-bottom: 2px solid #fff;
}

.store-popup .css-7obqij-MuiInputBase-root-MuiInput-root::before {
  border-bottom: 1px solid #fff;
}

.store-popup .css-l4u8b9-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):before {
  border-bottom: 1px solid #fff;
}

.store-popup .css-l4u8b9-MuiInputBase-root-MuiInput-root:hover:not(.Mui-disabled, .Mui-error):after {
  border-bottom: 2px solid #fff;
}

.store-popup .css-9npbnl-MuiFormLabel-root-MuiInputLabel-root,
.store-popup .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
  color: #fff;
}

.store-popup .selectedOffer-percentage {
  border-bottom-color: #fff;
}

.store-popup input::placeholder {
  color: #fff;
}

.select-tag-offer>button {
  background: unset;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 90px;
  text-align: center;
  margin-right: 10px;
  padding: 5px;
  color: white;
}

.select-tag-offer>button.selected {
  background-color: #cb8b15;
  /* Replace with your desired background color */
  color: #fff;
  /* Change text color for better visibility, if needed */
}

.select-tag-offer {
  display: flex;
  flex-wrap: wrap;
}

.yello-bg {
  background: transparent;
  margin-bottom: 15px;
  padding: 7px 16px;
  border-radius: 5px;
  font-size: 16px;
  color: #fff;
  display: block;
  width: 100%;
  text-align: center;
}

.select-tag-offer {
  overflow-y: auto;
  scrollbar-width: thin;
  /* Firefox */
  scrollbar-color: #999 transparent;
  /* Firefox */
}

.select-tag-offer::-webkit-scrollbar {
  width: 8px;
  /* width of the scrollbar */
}

.select-tag-offer::-webkit-scrollbar-thumb {
  background-color: #999;
  /* color of the thumb */
  border-radius: 4px;
  /* round corners of the thumb */
}

.select-tag-offer::-webkit-scrollbar-track {
  background-color: transparent;
  /* color of the track */
}

.running-offers-dialog {
  color: white;
  text-transform: capitalize;
  border-bottom: 1px solid;
  font-size: 18px;
  font-family: "Francois One";
}

.button-date-show {
  font-size: 9px;
  margin: 0px;
}

.no-offer-notification {
  color: white;
  font-size: 14px;
  font-family: "Francois One";
}

.text-field-mui {
  font-family: "";
  border-bottom-color: white;
}

.seperate-option-tag.active {
  background-color: #cb8b15;
}

.loading-adjust {
  background: transparent;
  display: flex;
  height: 32px;
  justify-content: center;
}

.delete-offer-button {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.delete-offer-button :hover {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  background-color: red;
}

.separate-option-tag {
  margin-top: 10px;
}

.css-1c2i806-MuiFormLabel-root-MuiInputLabel-root.Mui-focused {
  color: #ffffff !important;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
  border-bottom: 2px solid #ffffff !important;
  left: 0;
  bottom: 0;
  content: "";
  position: absolute;
  right: 0;
  -webkit-transform: scaleX(0);
  -moz-transform: scaleX(0);
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -webkit-transition: -webkit-transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transition: transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
  border-bottom: 1px solid rgb(255, 255, 255) !important;
  left: 0;
  bottom: 0;
  content: "\00a0";
  position: absolute;
  right: 0;
  -webkit-transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  pointer-events: none;
}

.action-right {
  width: 13%;
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

#adlt-table .lista-creation {
  width: 17%;
}

.lista-modification2 {
  width: 13%;
}

#adlt-table .lista-right {
  width: 13%;
}

#adlt-table .lista-right .card-heading {
  display: flex;
  flex-wrap: wrap;
  column-gap: 10px;
}

#adlt-table .lista-modification {
  text-align: left;
  justify-content: start;
}

.pagination-for-virtualstore {
  display: flex;
  justify-content: center;
  padding: 10px;
  align-items: center;
  gap: 15px;
}

.pagination-for-virtualstore>button {
  padding: 10px;
  background-color: #000;
  border: 1px solid #fff;
  color: #fff;
  border-radius: 50%;
  font-size: 18px;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pagination-for-virtualstore>button:hover {
  background-color: #fff;
  color: #000;
}

#adlt-table .cat-name {
  padding-left: 20px;
}

#adlt-table .cat-action {
  width: 20%;
}

.cat-name ps-0 {
  display: flex;
  gap: 30px;
  align-items: center;
}

.data-collection {
  overflow: auto;
  height: 700px;
  padding-bottom: 25px;
}

.product-list-seperate {
  overflow: auto;
  max-height: 700px;
  padding-bottom: 5px;
  height: auto;
}

.product-list-seperate .lista-right {
  width: 18% !important;
}

.react-bootstrap-table-CSS td table tr thead {
  background-color: transparent !important;
}

.billboard-sec-inner2 {
  height: 700px !important;
  overflow-x: hidden !important;
  overflow-y: scroll !important;
}

.EditCategory-heading {
  margin: 0 auto;
}

.img-w-delete {
  display: flex;
}

.img-w-prev img {
  width: 130px;
  height: auto;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: var(--bs-modal-header-padding);
  border-bottom: var(--bs-modal-header-border-width) solid var(--bs-modal-header-border-color);
  border-top-left-radius: var(--bs-modal-inner-border-radius);
  border-top-right-radius: var(--bs-modal-inner-border-radius);
  font-family: "Felix-Titling" !important;
}

.input-and-icon {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.img-w-prev {
  display: flex;
  justify-content: space-between;
}

.billboard-sec-inner4 th {
  background: transparent !important;
  color: white !important;
  padding: 21px !important;
  font-size: 17px;
  font-family: "Century-Gothic";
  font-style: italic;
}

.billboard-sec-inner4 td {
  background: transparent !important;
  color: white !important;
  padding: 0px !important;
  margin: 0 auto !important;
}

.bb-page .billboard-sec-inner2 {
  height: 100% !important;
  overflow-x: unset !important;
  overflow-y: unset !important;
}

@media screen and (max-width: 1199px) {
  .billboard-page .billboard-sec {
    padding: 50px 15px;
  }
}

@media screen and (max-width: 767px) {
  .lista-content .lista-name {
    width: 50% !important;
    padding: 0 15px;
  }

  .lista-modification-2 {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .store-popup .MuiPaper-elevation {
    padding: 0;
    margin: 0 15px;
  }

  .card-heading,
  .lista-content .billboard-cards h3 {
    font-size: 14px;
    line-height: 1.2;
  }

  .edit-icon-table svg {
    width: 20px;
    height: 20px;
  }

  .billboard-page .billboard-sec {
    padding: 50px 0px;
  }
}

@media screen and (max-width: 576px) {
  .billboard-sec-inner4 {
    min-width: 576px;
  }
}

.lista-content .MuiGrid-container .MuiGrid-item {
  flex-basis: 50%;
  max-width: 100%;
}

.lista-content .MuiGrid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
  width: 100%;
}

@media screen and (max-width: 576px) {
  .billboard-sec-inner4 {
    min-width: 100%;
  }
}