.cookie-policy{
    background-color: #131313;
}
.cookie-policy-inner .title-two{
    font-size: 37px;
    line-height: 49px;
    font-family: 'Francois One';
}
.cookie-policy-inner .subtitle{
    font-size: 19px;
    line-height: 26px;
    color: #fff;
    margin-bottom: 20px;
}
.cookie-policy-inner{
    padding: 40px 20px;
    max-width: 986px;
    width: 100%;
    margin: 0 auto;
    margin-top: 1rem;
}
.cookie-policy p{
    font-size: 13px;
    line-height: 1rem;
    color: #fff;
    margin-bottom: 20px;
}
.cookie-policy .quetion-sec p{
    margin-bottom: 0;
}
.cookie-policy .quetion-sec{
    margin-bottom: 20px;
}