.addMultimedia-sec-inner {
  padding: 50px 0 30px;
}
.multimediaImg-sec {
  margin-top: 50px;
}
.multimediaImg-sec .multimediaImg-card {
  max-width: 350px;
  width: 100;
  margin: 0 auto;
  text-align: center;
}
.multimediaImg-sec .multimediaImg-card p {
  font-size: 12px;
  line-height: 16px;
  margin-top: 10px;
}
.mulImg-btn-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 80px;
}

.common-form-control.img-video-form-control {
  position: relative;
  border-radius: 50px;
}
.common-form-control.img-video-form-control .fileUpload.btn.btn--browse {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 30%;
  opacity: 0;
}
.preview-imgCon {
  max-width: 300px;
  width: 100%;
}
.preview-imgCon img,
.preview-imgCon video {
  width: 100%;
  height: 100%;
}

.header-title {
  font-family: "Felix Titling";
}
.file-input-sec .f-input {
  width: 100%;
  max-width: 400px;
  padding: 0px 14px;
  text-align: center;
  cursor: pointer;
}
.news-header-2 {
  font-family: Felix-Titling;
  font-size: 21px;
  display: flex;
  justify-content: space-between;
  max-width: 890px;
  width: 100%;
  margin: 0 auto;
  letter-spacing: 1px;
  margin-bottom: 45px !important;
  /* margin-bottom: 100px; */
}
.hl-page .legend-tabs .tab-content {
  padding: 0;
}

.hl-page .historical-legends-2 {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 72px;
  margin-top: 100px;
}

.imgage-exp {
  vertical-align: middle;
  max-width: 353px;
  max-height: 179px;
  width: 100%;
  height: auto;
  object-fit: cover;
  border-radius: 15px;
}

.card-heading {
  font-family: "Francois One";
  font-size: 16px;
  text-transform: uppercase;
}

.deletebutton-videosimages {
  position: absolute;
  padding: 3px;
}
.delete-button-img- {
  position: absolute;
}

.file-input-sec .f-input {
  width: 100%;
  max-width: 400px;
  padding: 5px 76px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
}
.upload-multi {
  cursor: pointer;
}
/* font-family: 'Francois One'; */

.loader-container2 {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.loader2 {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 24px;
  height: 24px;
  animation: spin2 1s linear infinite;
}

@keyframes spin2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.img-show-position {
  display: flex;
  justify-content: center;
  align-items: center;
}
.img-show-position img {
  max-width: 400px;
  width: 50%;
}

.img-show-position video {
  max-width: 400px;
  width: 100%;
}

@media screen and (max-width: 1199px) {
  .historical-legends-2 {
    grid-template-columns: 2fr 2fr;
  }
}
