.legend-tabs {
    padding: 0px 0 10px;
}

.legend-tabs .nav-tabs {
    border-bottom: 3px solid #131313;
}

.legend-tabs .tab-content {
    padding: 0px 30px 10px;
    max-height: 550px;
    scroll-behavior: smooth;
    width: 100%;
    overflow-x: hidden;
}

.legend-tabs .nav-tabs .nav-link {
    font-size: 19px;
    line-height: 25px;
    color: #FEF6F2;
    text-transform: uppercase;
    border: 0;
    position: relative;
    font-family: 'Francois One';
}

.legend-tabs .nav-tabs .nav-link.active {
    background-color: transparent;
    color: #FEF6F2;
    transition: all 0s;

}

.legend-tabs .nav-tabs .nav-link::before {
    transition: all 0.3s;
    content: "";
    position: absolute;
    width: 0%;
    height: 3px;
    background-color: #CB8B15;
    left: 0;
    bottom: 0;
    transition: all 0.3s;
}

.legend-tabs .nav-tabs .nav-link.active::before {
    transition: all 0s;
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #CB8B15;
    left: 0;
    bottom: 0;
}

/* .legend-tabs .nav-tabs .nav-link::before{
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: #CB8B15;
} */
.legend-tabs .nav-tabs .nav-link.active,
.legend-tabs .nav-tabs .nav-item.show .nav-link {

    border-bottom: solid;
    border-bottom-color: #cb8b15;

}


.historical-legends {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
}

.legend-card {
    max-width: 120px;
    width: 100%;
    margin: 0 auto 20px;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    height: 260px;
    cursor: pointer;
}

.legend-card .legend-img {
    max-width: 100%;
    height: 100%;
    object-fit: cover;
}

.legend-card .legend-profile {
    position: absolute;
    bottom: 0;
    display: flex;
    padding: 5px 2px;
    align-items: center;
}

.legend-card .legend-profile p {
    font-size: 10px;
    line-height: 13px;
    font-family: 'Century-Bold';
    margin: 0;
    word-wrap: break-word;
    margin-left: 5px;
}

.center-btn-sec {
    display: flex;
    justify-content: center;
    padding: 30px 0 10px;
}

.center-btn-sec button {
    text-transform: uppercase;
}

.preview-imgCon {
    margin: 0 auto;
    text-align: center;

}

.preview-imgCon .previewImg {
    max-width: 300px;
    margin: 0 auto;
    width: 100%;
    height: 200px;
    text-align: center;
    object-fit: contain;
}

.preview-imgCon img {
    height: auto;
}



@media only screen and (max-width: 991px) {

    .nav-fill .nav-item .nav-link,
    .nav-justified .nav-item .nav-link {
        font-size: 16px;
    }

}