.todas-card {
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  align-items: center;
  padding: 5px;
  margin-bottom: 30px;
}

.added-msger-chat {
  width: 100% !important;
}

.msg-text {
  width: 95% !important;
}
.msg-img {
  top: 93% !important;
}
.voice-recorder {
  cursor: pointer;
  display: flex;
  /* margin-right: 14px;
  margin-bottom: 10px; */
}

.input-form {
  width: 403px;
}

.voice-recorder svg {
  width: 30px;
  height: 30px;
}

svg.active-mic {
  fill: rgba(255,
      0,
      0,
      0.683);
  /* Change the fill color to red (or any other color you prefer) */
  transform: scale(1.1);
  /* Scale the size of the SVG element to make it slightly larger */
}

.blinking-dot {
  width: 8px;
  height: 8px;
  background-color: red;
  /* Change the color of the dot */
  border-radius: 50%;
  /* Make the dot round */
  animation: blink 1s infinite alternate;
  /* Blinking animation */
  display: inline-block;
}

@keyframes blink {
  from {
    opacity: 1;
    /* Fully visible */
  }

  to {
    opacity: 0;
    /* Fully transparent */
  }
}

.gem-profile-mi-palco {
  width: 13px !important;
  height: 11px !important;
  margin-top: -25px;
  margin-left: 20px;
}

.todas-card .left-sec {
  display: flex;
}

.todas-card .left-sec .profile-img {
  width: 62px;
  height: 62px;
  border-radius: 50%;
  overflow: hidden;
}

.todas-card .left-sec .profile-content {
  margin-left: 20px;
  padding: 8px 0;
}

.todas-card .left-sec .profile-content h5 {
  font-size: 19px;
  line-height: 22px;
  margin: 0 0 3px 0;
  color: #131313;
  font-family: "Century-BoldItalic";
}

.todas-card .left-sec .profile-content p {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #131313;
  font-family: "Century-Italic";
}

.digital-heading {
  display: flex;
  justify-content: space-between;
  padding: 20px 10px;
  align-items: center;
  margin-bottom: 30px;
}

.digital-heading h3 {
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
}

.tabs-cards-sec .nav-tabs .nav-item .nav-link {
  color: #fff;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  border: 0;
}

.tabs-cards-sec .nav-tabs .nav-link.active,
.tabs-cards-sec .nav-tabs .nav-item.show .nav-link {
  background: transparent;
  border-bottom: 3px solid #eba51a;
}

.tabs-cards-sec .nav-tabs {
  border: 0;
  margin-bottom: 50px;
}

.back-sec {
  cursor: pointer;
}

.gems {
  display: flex;
  align-items: center;
  color: #fff;
}

.membersias-sec {
  margin: 0 auto;
  max-width: 240px;
  width: 100%;
}

.gems>img {
  padding-right: 15px;
}

.available-membership {
  margin-top: 100px;
  max-width: 235px;
  background-color: #131313;
  color: #fff;
}

.available-membership>h6 {
  text-align: center;
  padding: 10px 15px;
  border: 1px solid #fef6f2;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0;
}

.available-body {
  border: 1px solid #fef6f2;
  padding: 20px 25px;
  list-style-type: none;
  margin-bottom: 0;
}

.available-body>li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.available-body>li>span {
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
}

.available-body>li>span>img {
  width: 16px;
  margin-right: 11px;
}

.available-body>li:not(:last-child) {
  margin-bottom: 30px;
}

.digital-leader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100%;
}

.digital-leader>h6 {
  font-style: italic;
  color: #fff;
}

.digital-leader> :nth-child(1),
.digital-leader> :last-child {
  width: 20%;
  text-align: right;
  font-style: italic;
  align-self: flex-start;
  padding-top: 50px;
}

.digital-leader>div:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.digital-leader .center-dl {
  width: 80%;
}

.center-head {
  text-align: center;
}

.center-head>img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 50%;
}

.center-head>h3 {
  font-size: 19px;
  line-height: 20px;
  margin-top: 13px;
}

.personal-info {
  max-width: 350px;
  margin: 70px auto 0;
}

.personal-info-pol {
  background: #0c0c0c4f;
  -webkit-backdrop-filter: blur(34px);
  backdrop-filter: blur(34px);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  padding: 21px;
  height: 60%;
  width: 100%;
  margin-top: 30px;
  max-width: 400px;
  scroll-behavior: auto;
  overscroll-behavior-y: contain;
  overflow-y: scroll;
  overflow-x: hidden;
  border: 1px solid #fff;
}

.personal-info>h4 {
  font-size: 19px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.personal-data {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fef6f2;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.personal-data>span {
  width: 50%;
}

.personal-data>span {
  font-size: 11px;
  font-style: italic;
}

.personal-data>span:nth-child(2) {
  color: #fef6f2;
}

.available-badge {
  text-align: center;
  color: #fff;
}

.available-badge img {
  width: 53px;
}

.available-badge>h4 {
  font-size: 19px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.available-badge h6 {
  font-size: 9px;
  line-height: 15px;
  color: #eba51a;
  text-transform: uppercase;
}

.pr-hier {
  margin-bottom: 35px;
}

.available-badge .row>div {
  margin-bottom: 30px;
  padding: 0 5px;
}

.first-row {
  position: relative;
}

.row.first-row>div {
  margin-bottom: 45px;
}

.row.first-row>div:before {
  content: "";
  position: absolute;
  bottom: -27px;
  left: -1px;
  right: 0;
  background: #fff;
  width: 1px;
  height: 18px;
  margin: 0 auto;
}

.sec-row {
  position: relative;
}

.row.sec-row:before {
  content: "";
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
  background: #fff;
  width: calc(100% - 33.33%);
  height: 1px;
  margin: 0 auto;
}

.available-badge .row.sec-row>div:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1px;
  height: 18px;
  background: #fff;
}

.available-badge .row.sec-row>div:nth-child(1):before,
.available-badge .row.sec-row>div:nth-child(2):before,
.available-badge .row.sec-row>div:nth-child(3):before {
  top: -17px;
}

.disable {
  opacity: 0.6;
}

.digi-profile-sec {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.digi-profile-sec .gems {
  justify-content: center;
  padding: 20px 0;
}

.gems-movements-sec .gems-movements {
  text-align: center;
  margin: 5px 0 20px;
}

.gems-movements-sec {
  max-width: 450px;
  width: 100%;
  font-family: "Francois One" !important;
  margin: 0 auto;
}

.gems-movements-sec .gems-card {
  display: flex;
  margin: 0 0 10px 0;
  justify-content: space-between;
}

.gems-movements-sec .gems-card .left-side {
  display: flex;
}

.gems-movements-sec .gems-card .left-side .img-sec {
  width: 40px;
  height: 40px;
}

.gems-movements-sec .gems-card .left-side img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gems-movements-sec .gems-card .details-sec {
  margin-left: 20px;
}

.gems-movements-sec .gems-card .details-sec h5 {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 3px 0;
}

.gems-movements-sec .gems-card .details-sec p {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.gems-movements-sec .gems-card .increasing {
  font-size: 19px;
  line-height: 20px;
  color: #1dbe2e;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.decreasing {
  font-size: 19px;
  line-height: 20px;
  color: #ff0707;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.gems-movements-sec .gems-card .sub-hed {
  font-size: 12px;
  color: #fef6f2;
  opacity: 0.6;
  text-align: center;
}

.two-buttons-sec {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 50px;
}

.button-view-member {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  outline: none;
  border-radius: 13px;
  border-color: transparent;
  font-family: "Francois One";
  background-color: #cb8b15;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-shadow: 1px 1px #29282891;
}

.button-view-member.active {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  outline: none;
  border-radius: 15px;
  border-color: #cb8b15;
  border-style: solid;
  border-width: 3px;
  font-family: "Francois One";
  background-color: #000000;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-shadow: 1px 1px #29282891;
}

.button-send-message {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  outline: none;
  border-radius: 13px;
  border-color: transparent;
  font-family: "Francois One";
  background-color: #25762e;
  color: white;
  text-transform: uppercase;
  cursor: pointer;
  text-shadow: 1px 1px #29282891;
}

.button-ban-user {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  outline: none;
  border-radius: 15px;
  border-color: #800b0f;
  border-style: solid;
  border-width: 3px;
  font-family: "Francois One";
  background-color: #800b0f;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-shadow: 1px 1px #29282891;
}

.button-ban-user.active {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  outline: none;
  border-radius: 15px;
  border-color: #800b0f;
  border-style: solid;
  border-width: 3px;
  font-family: "Francois One";
  background-color: transparent;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-shadow: 1px 1px #29282891;
}

.button-verify-member {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  outline: none;
  border-radius: 13px;
  border-color: transparent;
  font-family: "Francois One";
  background-color: #005eaa;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-shadow: 1px 1px #29282891;
}

.button-verify-member.active {
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 14px;
  outline: none;
  border-radius: 13px;
  border-color: #005eaa;
  border-style: solid;
  border-width: 3px;
  font-family: "Francois One";
  background-color: transparent;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-shadow: 1px 1px #29282891;
}

.button-verified-member {
  padding-top: 8px;
  padding-bottom: 8px;
  width: 100%;
  font-size: 14px;
  outline: none;
  border-radius: 13px;
  border-color: transparent;
  font-family: "Francois One";
  background-color: #800b0f;
  color: white;
  cursor: pointer;
  text-transform: uppercase;
  text-shadow: 1px 1px #29282891;
}

.fol-fol-num {
  /* width: 20% !important; */
  text-align: right !important;
  font-style: italic !important;
  align-self: flex-start !important;
  padding-top: 50px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.fol-fol-num-2 {
  width: 20% !important;
  text-align: right !important;
  font-style: italic !important;
  align-self: flex-start !important;
  padding-top: 50px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
  margin-top: 50px !important;
}

.img-ver-dia img {
  width: 7%;
  position: relative;
  top: -29px;
  left: 39px;
}

.show-div-on-click {
  width: 100%;
  background: white;
  height: 39px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom-right-radius: 22px;
  border-bottom-left-radius: 22px;
  margin-top: -10px;
  padding-top: 10px;
}

.img-ban-dia {
  position: relative;
  top: -127px;
}

.img-ban-dia.unveri {
  position: relative;
  top: -90px;
}

/* SweetAlert Styles */

.swal2-container {
  z-index: 10000;
  font-family: "Francois One";
}

.swal2-popup {
  border-radius: 50px;
  display: grid;
  outline: none;
  background-color: #00000005;
  backdrop-filter: blur(17px);
  border-width: 1px;
  width: 450px;
  padding: 30px;
  font-family: "Francois One";
}

.swal2-title {
  font-size: 1.5em;
  margin: 0px;
  font-family: "Francois One";
}

.swal2-content {
  font-size: 1.2em;
}

.swal2-buttons {
  display: flex;
}

.swal2-styled.swal2-confirm {
  margin: 0.5em;
  padding: 0.5em 1em;
  font-size: 1em;
}

.swal2-styled.swal2-cancel {
  margin: 0.5em;
  padding: 0.5em 1em;
  font-size: 1em;
}

.swal2-popup.swal2-toast {
  border-radius: 10px;
}

.swal2-popup.swal2-toast .swal2-title {
  font-size: 1.2em;
}

.swal2-popup.swal2-toast .swal2-content {
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-close {
  font-size: 1em;
}

.swal2-popup.swal2-toast .swal2-close:hover {
  background-color: transparent;
}

.swal2-popup.swal2-show {
  transform: scale(1);
  opacity: 1;
}

.gems-card-list {
  display: flex;
  gap: 28px;
  flex-direction: column;
  margin-top: 50px;
  height: 400px;
  overflow: auto;
  padding-right: 20px;
}

.view-more-sec {
  font-size: 12px;
  font-family: "Century Gothic";
  font-weight: normal;
  color: #68b6f5;
  cursor: pointer;
}

.username-mi-palco {
  font-style: italic;
  color: #ffffffdb;
}

.post-sec-border-details {
  border-style: solid;
  border-radius: 7px;
  border-width: 1px;
  border-color: #ffffff6b;
  margin-bottom: 28px;
}

.mi-poly {
  margin-top: 200px;
  position: absolute;
}

.mi-poly-statement {
  font-size: 12px;
  font-style: normal;
  cursor: pointer;
}

.lock-icon img {
  width: 20px;
  position: absolute;
  top: -13px !important;
  left: -10px !important;
}

.dt-logo img {
  width: 50px;
}

.green-clr-mi-palco {
  color: green;
  font-style: italic;
}

.green-clr-mi-palco img {
  color: green;
}

.profile-time {
  width: 80px !important;
}

.debate-logo-mipalco img {
  width: 35px;
}

.header-c {
  display: flex;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  padding: 11px 18px;
  background-image: linear-gradient(#cb8b15, #fdd300);
  text-transform: uppercase;
}

.img-mi-palco {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.tick-button {
  width: 21px;
  height: 21px;
}

.date-day-display {
  font-size: 13px;
  color: grey;
  font-family: "Century-Gothic";
}

.postmediaDiv {
  width: 20px;
  height: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto auto auto;
}

.postMediaImage {
  width: 41px;
  height: 41px;
  margin-bottom: 40px;
}

.postMediaVideo {
  width: 20px;
  height: 20px;
}

.fist-lock {
  width: 20px !important;
  position: absolute !important;
  top: 11px !important;
  left: 225px !important;
}

.lock-icon-first img {
  width: 20px;
  position: absolute;
  top: 13px !important;
  left: 226px !important;
}

.club-centerText2 {
  z-index: 999;
  filter: blur(0px) !important;
  background: transparent;
}

.club-centerText2::before {
  content: "";
  position: absolute;
  background: #916e1c;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(20px);
  transform: scale(1.2);
}

.club-centerTextD {
  z-index: 999;
  filter: blur(0px) !important;
  background: transparent;
}

.club-centerTextD::before {
  content: "";
  position: absolute;
  background: #ffffff7e;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(20px);
  transform: scale(1.2);
}

.club-centerTextG {
  z-index: 999;
  filter: blur(0px) !important;
  background: transparent;
}

.club-centerTextG::before {
  content: "";
  position: absolute;
  background: #a6f68664;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(20px);
  transform: scale(1.2);
}

.club-planes {
  display: flex;
  align-items: flex-end;
  font-size: 13px;
  color: #cb8b15;
  font-style: italic;
  cursor: pointer;
}

.club-img {
  max-width: 50px;
  max-height: 50px;
}

.club-img img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}

@media screen and (max-width: 767px) {
  .personal-info-pol {
    top: 28%;
  }
}