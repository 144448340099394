.event-page-content {
  padding: 0px 0 20px;
}

.event-calendar-sec {
  padding: 80px 0 20px;
}

.event-calendar .react-calendar {
  background-color: black;
  padding: 5px;
  max-width: 530px;
  width: 100%;
  border-radius: 30px;
}

.event-calendar .react-calendar button {
  color: #fff;

}

.event-calendar .react-calendar .react-calendar__tile {
  background-color: transparent;
}

.event-calendar .react-calendar .react-calendar__tile abbr {
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}

.event-calendar .react-calendar .react-calendar__tile--active abbr {
  background-color: rgb(255, 171, 171);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}

.event-calendar .react-calendar .react-calendar__tile:enabled:hover,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus {
  background-color: transparent;
}

.event-calendar .react-calendar .react-calendar__tile:enabled:hover abbr,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus abbr {
  background-color: red;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}

.event-page-content {
  padding: 0px 0 20px;
}

.event-calendar-sec {
  padding: 0px 0 20px;
}

.event-calendar .react-calendar {
  background-color: black;
  padding: 5px;
  max-width: 530px;
  width: 100%;
  border-radius: 30px;
}

.event-calendar .react-calendar button {
  color: #fff;

}

.event-calendar .react-calendar .react-calendar__tile {
  background-color: transparent;
}

.event-calendar .react-calendar .react-calendar__tile abbr {
  width: 40px;
  height: 40px;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}

.event-calendar .react-calendar .react-calendar__tile--active abbr {
  background-color: rgb(255, 171, 171);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}

.event-calendar .react-calendar .react-calendar__tile:enabled:hover,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus {
  background-color: transparent;
}

.event-calendar .react-calendar .react-calendar__tile:enabled:hover abbr,
.event-calendar .react-calendar .react-calendar__tile:enabled:focus abbr {
  background-color: red;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: inline-block;
  padding: 8px 5px;
  text-align: center;
}

.cal-list-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  border-radius: 50px;
  padding: 10px 20px 10px 8px;
  color: #131313;
  margin-bottom: 15px;
}

.cal-list-card .left-sec {
  display: flex;
  align-items: center;
}

.cal-list-card .left-sec .date {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #800B0F;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}

.cal-list-card .left-sec .event-data h4 {
  font-size: 13px;
  line-height: 14px;
  font-family: 'Century-BoldItalic';
}

.cal-list-card .left-sec .event-data p {
  font-size: 10px;
  line-height: 13px;
  font-family: 'Century-Italic';
  margin: 0;
}

.cal-list-card .right-sec h3 {
  font-size: 19px;
  line-height: 14px;
  font-family: 'Century-BoldItalic';
  margin: 0;
}

.event-btns {
  display: flex;
  justify-content: center;
  gap: 20px;
  padding: 30px 5px 10px;
}

.calendar-list-sec {
  padding: 5px 30px;
  overflow-x: hidden;
  overflow-y: scroll;
  height: 478px;
}

.calendar-list-sec .cal-month h4 {
  font-size: 16px;
  line-height: 20px;
  font-family: 'Century-Bold';
  margin-bottom: 20px;
}

.event-calendar-sec .react-calendar__navigation button:enabled:hover,
.event-calendar-sec .react-calendar__navigation button:enabled:focus {
  background-color: transparent;
}

.op-31 {
  opacity: 31%;
}



.custom-container {
  width: 100%;
}

.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #000;
}

.custom-calendar span {
  color: #fff;
}

.rmdp-shadow {
  box-shadow: none;
  border-radius: 15px !important;
}


.custom-calendar.rmdp-wrapper,
.rmdp-container .custom-calendar.ep-arrow::after {
  background-color: #000;
  box-shadow: unset;
  border: 1px solid #fef6f257;
}

.rmdp-container .custom-calendar.ep-arrow[direction="bottom"] {
  border-top: 1px solid bisque;
}

.rmdp-container .custom-calendar.ep-arrow[direction="top"] {
  border-bottom: 1px solid bisque;
}

.custom-calendar,
.custom-calendar .rmdp-calendar,
.custom-calendar .rmdp-day-picker,
.custom-calendar .rmdp-day-picker>div {
  width: 100% !important;
}

.custom-calendar .rmdp-day,
.custom-calendar .rmdp-week-day {
  color: #fff;
  height: 41px !important;
  width: 41px !important;
}

.custom-calendar .rmdp-arrow {
  border: solid #fff;
  border-width: 0px 2px 2px 0px;
  display: inline-block;
  height: 10px;
  margin-top: 5px;
  padding: 2px;
  width: 10px;
}

.custom-calendar .rmdp-arrow-container {
  background: transparent;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  height: 40px;
  justify-content: center;
  margin: 0 5px;
  padding: 0;
  width: 40px;
  align-items: center;
}

/* 
.custom-calendar .rmdp-border-bottom {
  border-bottom: 1px solid #000;
} */


.eventColor_list ul li a {
  font-size: 11px;
  color: #fff;
}

.eventColor_list ul li span {
  font-size: 12px;
}

.clr-circle {
  width: 19px;
  height: 19px;
  display: inline-block;
  margin-right: 10px;
  background: transparent;
  border-radius: 50%;
}

.eventColor_list ul li {
  display: flex;
  align-items: center;
}

.yellow-clr {
  background-color: #DF9825;
}

.red-clr {
  background-color: #800B0F;
}

.green-clr {
  background-color: #25762E;
}

.blue-clr {
  background-color: #54A3C9;
}

.custom-calendar .rmdp-border-bottom {
  border-bottom: 0;
}

.custom-calendar .rmdp-colors {
  width: 0;
  height: 0;
  padding: 0;
}

.custom-calendar .rmdp-color.active {
  box-shadow: unset;
}

.dates.today {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #800B0F;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}

.dates.events {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #DF9825;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}

.dates.exclusive {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #DF9825;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}

.dates.exclusive-events {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #54A3C9;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dates.experiences-messice {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #25762E;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}

.dates.general {
  width: 50px;
  height: 50px;
  display: inline-block;
  background-color: #25762E;
  border-radius: 50%;
  text-align: center;
  padding: 15px 5px;
  font-size: 17px;
  line-height: 17px;
  color: #fff;
  margin-right: 15px;
}

/* calender */
/* Calendar.css */

.app-cal-cont {
  width: 100%;
  margin: 0 auto;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.23);
  border-style: solid;
  padding-top: 2px;
  padding-right: 20px;
  padding-bottom: 20px;
  padding-left: 20px;
  border-radius: 25px;
  background-color: black;
}

.app-cal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.app-cal {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 25px;
  font-size: 12px;
}

.app-cal-day {
  border: 1px solid #dddddd00;
  padding: 10px;
  text-align: center;
  border-radius: 50%;
  max-width: 36px;
  height: 36px;
}

.today {
  background-color: #800b0e;
  /* Today's color */
}

.yesterday {
  background-color: #ffe0e0;
  /* Yesterday's color */
}

.empty {
  visibility: hidden;
}

.month-name-app {
  font-size: 18px !important;
  font-family: 'Francois One';
}

.button-arrow {
  border-color: transparent;
  outline: none;
  background-color: transparent;
  color: white;
}