.dashboard-card-secs {
  padding: 40px 0 0;
  max-width: 960px;
  margin: 0 auto;
}

.homepage-chart {
  display: flex;
  justify-content: normal;
  align-items: flex-end;
}
.custom_marginRight {
  margin-right: 17px;
}
.control-card {
  cursor: pointer;
  max-width: 166px;
  width: 100%;
  height: 157px;
  border: 0.30000001192092896px solid #61605f9e;
  border-radius: 5px 5px 5px 5px;
  background: #000000 0% 0% no-repeat padding-box;
  overflow: hidden;
  margin-bottom: 18px;
}

.control-card .card-img {
  text-align: center;
  padding: 17px 0px;
  background-color: #000000;
}
.bg-effect {
  background: linear-gradient(264deg, #000000, #a2a2a21a);
  padding: 5px;
  border-radius: 5px;
}

.card-img-vstore {
  text-align: center;
  padding: 17px 0px;
  background-color: #000000;
}

.control-card .card-body {
  background: #fef6f2 0% 0% no-repeat padding-box;
  padding: 10px;
  overflow: hidden;
  height: 46px;
}

.control-card .card-body p {
  margin: 0;
  color: #131313;
  font-size: 8px;
  line-height: 15px;
}

.control-card .card-body h4 {
  margin: 0;
  color: #131313;
  font-size: 13px;
  line-height: 13px;
  margin-bottom: 2px;
  font-family: "Francois One";
}

.control-card .card-img img {
  min-width: 64px;
  height: 64px;
  object-fit: contain;
  max-width: 75px;
}

.card-img-vstore img {
  width: 64px;
  height: 64px;
}

.manage-team-sec {
  background: #131313 0% 0% no-repeat padding-box;
  border: 0.30000001192092896px solid #fef6f2;
  border-radius: 5px;
  max-width: 210px;
  width: 100%;
  margin-left: auto;
  padding: 15px 10px;
  text-align: center;
}

.manage-team-sec h5 {
  font-size: 13px;
  line-height: 18px;
  text-transform: uppercase;
  font-weight: 900;
  margin-bottom: 5px;
}

.manage-team-sec p {
  font-size: 13px;
  line-height: 18px;
  font-family: "Century-Italic";
  margin: 10px 0 0 0;
}

.team-list ul {
  padding: 0;
  margin: 10px 0;
}

.team-list ul .team-list-link {
  font-size: 13px;
  color: #fef6f2;
  font-family: "Century-Italic";
  display: flex;
  text-align: left;
  align-items: center;
  padding: 5px 0;
}

.team-list ul .team-list-link .team-list-img {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.team-list-link {
  cursor: pointer;
}

/* ======================popups===================== */

.swal2-popup {
  display: grid;
  background-color: rgba(0, 0, 0, 0.959);
  border: 2px white solid;
  color: white;
  padding: 50px;
  font-family: "Century-Gothic";
  border-radius: 20px;
  font-weight: 300;
  margin-left: -16px;
}

.swal2-title {
  font-family: "Century-Gothic";
  font-weight: 300;
  font-style: italic;
  line-height: normal;
}

.swal2-confirmswal2-styled {
  background-color: #131313;
}

.swal2-confirm.swal2-styled {
  background-color: rgba(231, 27, 68, 0.88);
  color: white;
  border: none;
  min-width: 60%;
}

.swal2-deny.swal2-styled {
  background-color: #ebb62e;
  color: white;
  border: none;
  min-width: 60%;
}

.swal2-confirm.swal2-styled:active {
  border: none;
}

.manage-team-img {
  width: 80px;
  height: 80px;
  margin: 0 auto;
}

.manage-team-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.team-list-char-sec {
  position: relative;
  height: 79vh;
}

.piechart-sec {
  margin-top: 150px;
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

/* PieChart.css */

/* PieChart.css */
/* PieChart.css */
/* PieChart.css */

.pie-chart {
  position: relative;
  width: 200px;
  height: 200px;
  border-radius: 50%;
}

.slice {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.slice.slice1 {
  clip-path: polygon(50% 50%, 100% 0, 100% 100%);
  /* Additional styles specific to Slice 1 */
}

.slice.slice2 {
  clip-path: polygon(50% 50%, 0 0, 100% 0);
  /* Additional styles specific to Slice 2 */
}

.slice.slice3 {
  clip-path: polygon(50% 50%, 0 0, 0 100%);
  /* Additional styles specific to Slice 3 */
}

.gmail-homepage-card {
  width: 100% !important;
}

.control-card {
  border: none;
}

.control-card .card-body {
  background: #202020;
}

.control-card .card-body p,
.control-card .card-body h4 {
  color: #fef6f2 !important;
}

.control-card .card-body h4 {
  font-size: 15px;
}

.control-card .card-body p {
  font-size: 10px;
}

.blue-add-button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}

@media only screen and (max-width: 1199px) {
  .control-card .card-body h4 {
    font-size: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .navbar-sec .navbar-container {
    margin-bottom: 50px;
  }
}

@media only screen and (max-width: 991px) {
  .piechart-sec {
    position: unset;
    transform: unset;
    max-width: 300px;
    height: 300px;
    margin: 0 auto;
  }

  .dashboard-card-secs {
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 30px 0 0;
  }

  .blue-add-button {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 767px) {
  body .main-title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 575px) {
  .dashboard-card-secs {
    padding: 40px 0 0;
  }

  .col-lg-6:has(.dashboard-main) {
    padding: 0;
  }

  .control-card {
    margin: auto auto 30px auto;
  }

  .blue-add-button {
    margin-top: 20px;
  }
}
