.editExp-content {
  padding: 50px 20px 30px;
  height: auto;
  overflow-y: hidden;
}

.existing-img {
  height: 500px;
  overflow-y: scroll;
  padding-top: 10px;
}

.new-images {
  height: 400px;
  overflow-y: scroll;
  padding-top: 10px;
}

.common-form-control.img-video-form-control {
  position: relative;
  border-radius: 50px;
}

.common-form-control.img-video-form-control .fileUpload.btn.btn--browse {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 30%;
  opacity: 0;
}

.common-form-quater-control {
  max-width: 890px;
  width: 20%;
  background-color: #fff;
  border-radius: 39px;
  text-align: center;
  padding: 8px;
  margin-bottom: 35px;
  color: #131313;
}

.common-form-half-control {
  background-color: #fff;
  border-radius: 39px;
  text-align: center;
  padding: 8px;
  margin-bottom: 35px;
  color: #131313;
}

.file-input-sec-text {
  height: 180px;
}

.file-input-sec .f-input {
  width: 100%;
  max-width: 806px;
  padding: 5px 76px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
}

.file-input-sec-text .f-text {
  width: 100%;
  max-width: 806px;
  text-align: start;
  font-family: "Century-Gothic";
  height: 90%;
  border-color: #00000033;
  border-style: solid;
  overflow: auto;
  font-size: 16px;
  outline: none;
  padding: 10px;
  max-height: 150px;
  color: #131313b8;
}

.file-input-sec .input-quater {
  width: 100%;
  max-width: 823px;
  padding: 0px 20px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
  border-color: transparent;
  border-radius: 20px;
  font-size: 15px;
}

.file-input-sec .input-half {
  width: 100%;
  max-width: 823px;
  padding: 0px 20px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
  border-color: transparent;
  border-radius: 20px;
  font-size: 15px;
}

.file-input-sec input {
  width: 100%;
  max-width: 400px;
  padding: 5px 76px;
  text-align: center;
  cursor: pointer;
  font-family: "Century-Gothic";
}

.common-form-quater-control label {
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
  display: block;
  margin-bottom: 5px;
}

.common-form-half-control label {
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
  display: block;
  margin-bottom: 5px;
}

.common-form-quater-control {
  background-color: #fff;
  border-radius: 39px;
  text-align: center;
  padding: 8px;
  margin-bottom: 35px;
  color: #131313;
}

.four-item-goup {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.price-quant-pair {
  display: flex;
  flex-direction: column;
}

.five-pair {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.form-one {
  border-width: 2px;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 25px;
  padding-left: 50px;
  border-radius: 30px;
}

.button-pair-prev-next {
  display: flex;
  justify-content: space-between;
  margin-top: 30px;
  gap: 15px;
}

.button-pair-prev-next button {
  max-width: fit-content;
  padding: 5px 20px;
  font-size: 12px;
  border-radius: 30px;
}


.card-img-display {
  display: flex;
  flex-wrap: wrap;
}

.delete-colour-selected-img {
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
}

.images-display-seperate {
  position: relative;
}

.images-display-seperate>img {
  width: 120px;
  height: 120px;
  object-fit: cover;
}

.upload-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-icon .upload-logo-button {
  width: 100px;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.upload-icon .upload-logo-button svg {
  width: 60px;
  height: 60px;
  border: 1px solid #fff;
  border-radius: 50%;
  padding: 15px;
}

/* 
.images-display-seperate:not(:last-child) {
  margin-right: 10px;
} */


.submit-btn {
  margin-top: 20px;
  text-align: center;
}

.pagination-sec {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
}

.pagination-sec ul {
  display: flex;
  gap: 10px;
  list-style: none;
}

.pagination-sec ul li a {
  width: 10px;
  height: 10px;
  background: #fff;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.pagination-sec ul li .active-dots {
  opacity: 1;
}

.select-tag-cat {
  border: transparent;
  font-size: 16px;
  text-transform: capitalize;
  outline: none;
}

.forms-divs {
  border-style: solid;
  border-radius: 35px;
}

.sizearray {
  display: flex;
  gap: 10px;
  padding: 30px;
}

/* UniqueTable.css */

.unique-table {
  width: 100%;
  border-collapse: collapse;
}

.unique-table th,
.unique-table td {
  border: 1px solid #ddd;
  padding: 15px;
  text-align: left;
}

.size-header,
.size-cell {
  width: 5%;
}

.color-header,
.color-cell {
  width: 5%;
}

.quantity-header,
.quantity-cell {
  width: 5%;
}

.images-header,
.images-cell {
  width: 60%;
}

.images-cell img {
  max-width: 100%;
  height: auto;
}

.table-data-addproduct {
  /* max-height: 400px; */
  height: 100%;
}

.addItem-page .billboard-sec-inner {
  height: 500px;
}

.addItem-page .lista-name {
  width: 15% !important;
}

.addItem-page .lista-creation {
  width: 15% !important;
}

.addItem-page .lista-modification {
  width: 15% !important;
}

.addItem-page .lista-right {
  width: 40% !important;
  padding: 0 15px;
}

.addItem-page .lista-modification {
  width: 15% !important;
}

.addItem-page .lista-right .images-display-seperate>img {
  width: 40px;
  height: 40px;
  object-fit: cover;
  border-radius: 0;
  object-position: top;
}

.addItem-page .lista-right .card-heading {
  display: flex;
  width: 300px;
  overflow: auto;
  justify-content: center;
}

.table-data-addproduct .images-cell {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  align-items: center;
  position: relative;
  padding-right: 60px;
  width: 100%;
  min-height: 62.5px;
}

.table-data-addproduct .images-cell img {
  min-width: 40px;
  width: 40px;
  min-height: 40px;
  max-height: 40px;
  object-fit: contain;
  border-radius: 0;
  object-position: top;
}

.images-display-seperate>svg {
  fill: #cf0b0b;
  width: 20px;
  height: 20px;
  top: -7px;
  right: -5px;
}

.addItem-popup .modal-footer>.btn {
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  text-transform: capitalize;
}

.gender-input {
  display: flex;
  gap: 20px;
}

.gender-input label {
  cursor: pointer;
}

.gender-input input {
  margin: 0 10px;
}

.colour-palet {
  margin-bottom: 10px;
}

.plus-button-add-img {
  display: flex;
  width: 30px;
  height: 30px;
  border-style: solid;
  align-items: center;
  border-width: 1px;
  justify-content: center;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
}

.variant-heading-h3 {
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
  display: block;
  margin-bottom: 5px !important;
}

.images-display-separate img {
  width: 30px;
  height: 30px;
  object-fit: contain;
}

.addItem-page .lista-content .myTeamBanner>div {
  justify-content: center;
  padding: 5px;
}

.addItem-page .lista-modification>svg:not(:last-child) {
  margin-right: 10px;
}

.lista-content.addItem-page .billboard-sec-inner {
  height: auto !important;
}

.lista-content.addItem-page>div:nth-child(3) {
  padding-top: 0;
}

.unique_wrap {
  overflow: auto;
}

.unique-table td select {
  width: 100% !important;
}

.category-Grid {
  display: grid;
  grid-template-columns: auto auto auto;
  height: 350px;
  max-height: 350px;
  margin-bottom: 50px;
  justify-content: center;
  overflow-y: auto;
  gap: 14px;
}

.options img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.options {
  border: 1px solid grey;
  padding: 8.6px;
  border-radius: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 45px;
  height: 45px;
}


.opt-background {
  background-color: #4b4a4a;
  transition-duration: 0.5s;
}

.input-label {
  display: flex;
  justify-content: center;
  align-items: center;
}


.options label {
  font-size: 15px;
  cursor: pointer;
  font-family: "Century-Gothic" !important;
  padding-left: 10px;
  text-align: left;
}

.options {
  border: 1px solid grey;
  padding: 8.6px;
  border-radius: 50px;
}

.options label {
  font-size: 15px;
  cursor: pointer;
  font-family: "Century-Gothic" !important;
  padding: 9px;
}

.Stepper-options {
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  border: 1px solid;
  padding: 20px;
  border-radius: 20px;
}

/* Base styles */
.stepper-options-selected {
  display: flex;
  margin: 2rem;
  justify-content: space-around;
  align-items: center;
}

.step1-heading,
.step2-heading {
  font-size: 1.4rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 10px;
  border-radius: 5px;
  background-color: #e0e0e000;
  color: #dddcdc;
  font-weight: bold;
  transition: all 0.7s ease;
  font-family: 'Century-BoldItalic';
}

.step1-heading.active,
.step2-heading.active {
  color: #fbff00;
  font-family: 'Century-BoldItalic';
}

.action-table-buttons {
  flex-direction: row;
  display: flex;
  justify-content: center;
  gap: 3px
}

.action-table-buttons button {
  background: transparent;
  border: none;
  color: white;
}

.step1-heading span,
.step2-heading span {
  font-size: 1.3rem;
  margin: 0.6rem;
  border: 1px solid;
  border-radius: 50%;
  padding: 0.4rem;
  display: flex;
  height: 2rem;
  width: 2rem;
  justify-content: center;
  align-items: center;
}

/* Connecting line */
.connecting-line {
  width: 100%;
  height: 4px;
  background: #e0e0e0;
  transition: background-color 0.7s ease;
}

/* Stepper buttons */
.stepper-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1rem;
}

.stepper-buttons button {
  background: transparent;
  color: white;
  outline: none;
  font-weight: bold;
  border: 1px solid;
  padding: 9px;
  cursor: pointer;
  border-radius: 8px;
  font-family: 'Century-BoldItalic';
}

.stepper-buttons button:hover {
  background-color: #fbff00;
  transition-duration: 0.5s;
  color: black;
}


.variant-images-section {
  display: flex;
  border: 1px solid;
  min-height: 410px;
}

.is-left-part {
  display: flex;
  flex-direction: column;

}

.is-lp-img-name {
  width: 100%;
  border: 1px solid;
  line-height: 36px;
  padding: 0.6rem 1.4rem;
  cursor: pointer;
  text-transform: capitalize;
}

.is-lp-img-name:hover {
  background: #474848;
  text-transform: capitalize;
  transition-duration: 0.2s;
}


.is-right-part {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #2c2d2e;
}


.add-icons-images {
  width: 10%;
  height: 100%;
}

.is-rp-img-name input {
  display: none;
}

.add-icons-images svg {
  width: 72%;
  height: 72%;
  border: 1px solid;
  display: flex;
}



.display-img-choosen {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 14px;
  max-height: 28rem;
  overflow-y: auto;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.display-img-choosen img {
  max-width: 15rem;
  height: auto;
  width: 100%;
}

.img-wit-del-icon {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.img-wit-del-icon svg {
  cursor: pointer;
}


.form-with-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}

.input-with-label {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  align-items: center;
  gap: 6px;
}

form.form-variant-submission {
  display: flex;
  flex-direction: column;
  gap: 40px;
  justify-content: center;
  align-items: center;
  margin-top: 3rem;
  margin-bottom: 1rem;
  border: 2px solid;
  padding: 1.4rem;
  border-radius: 20px;
  background: #80808026;
}

.submit-button-form {
  max-width: 230px;
  width: 220px;
  border-radius: 13px;
  padding: 8px 10px;
  font-size: 14px;
  line-height: 20px;
  border: 0;
  color: #ffffff;
  font-weight: 900;
  margin-bottom: 15px;
  background-color: #25762e;
  text-transform: uppercase;
}

.input-with-label input {
  background: transparent;
  color: white;
  padding: 10px;
  border: 1px solid;
  border-radius: 9px;
  font-size: 1rem;
  outline: none;
}

.loader-progress {
  width: 120px;
  height: 20px;
  background: linear-gradient(#000 0 0) 0/0% no-repeat #ddd;
  animation: l1 11s infinite cubic-bezier(0.38, 0.65, 0.82, 0.52);
  border: 1px solid white;
  border-radius: 30px;
}

@keyframes l1 {
  100% {
    background-size: 100%
  }
}

.is-lp-img-name.active.selecte-img-css {
  background: #2c2d2e;
  text-transform: capitalize;
  border-right: none;
}

.form-with-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  gap: 30px;
}

@media (max-width: 1460px) {
  .form-with-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
  }

  .stepper-options-selected {
    display: flex;
    margin: 0rem;
    justify-content: center;
    align-items: center;
  }
}

/* 1460 */


.deletedImage {
  position: relative;
  opacity: 0.5;
  /* Reduced opacity to indicate a "marked for deletion" state */
  border: 2px dashed rgba(255, 0, 0, 0.8);
  /* Dashed red border for a clear visual indicator */
  filter: grayscale(90%);
  /* Add a grayscale effect to further emphasize the deletion */
  /* Prevent interactions with the image itself */
  padding: 11px;
  margin: 5px;
  transition: opacity 0.3s, filter 0.3s;

  /* Smooth transition for better UX */
}

.deletedImage:hover {
  opacity: 0.6;
  /* Slightly brighten on hover to provide subtle feedback */
  filter: grayscale(5%);
  /* Reduce grayscale on hover for better feedback */
}

.undoButton {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 20px;
  height: 20px;
  background-color: rgba(255, 0, 0, 0.8);
  /* Red circular button */
  border: 2px solid white;
  /* White border for contrast */
  border-radius: 50%;
  /* Make it circular */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: transform 0.2s ease, background-color 0.2s ease;
}

.undoButton:hover {
  transform: scale(1.2);
  /* Slight zoom effect on hover */
  background-color: red;
  /* Slightly darker red on hover */
}

.select-base-currency {
  /* width: 100%; */
  max-width: 300px;
  /* padding: 8px 12px; */
  font-size: 16px;
  color: #33333394;
  background-color: #fff;
  border: 1px solid #cccccc00;
  border-radius: 4px;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  outline: none;
  appearance: none;
  transition: border-color 0.3s, box-shadow 0.3s;
}

/* Add styling for focus */
.select-base-currency:focus {
  border-color: #007bff;
  /* Highlighted border color */
  box-shadow: 0 0 6px rgba(0, 123, 255, 0.5);
  /* Highlighted shadow */
}

/* Add styling for hover */
.select-base-currency:hover {
  border-color: #888;
  /* Darken border on hover */
}

/* Ensure compatibility with mobile devices */
.select-base-currency::placeholder {
  color: #aaa;
  /* Subtle placeholder color */
}