.admin-form{
    width: 100%;
}
.admin-form form{
    width: 100%;
}
.admin-form .admin-input{
    width: 100%;
    background-color: #131313;
    border: 0.3px solid #555453;
    border-radius: 5px 5px 5px 5px;
    outline: 0;
    padding: 8px 12px;
    font-size: 12px;
    line-height: 16px;
    color: #FEF6F2 ;
}
.admin-form .admin-input::placeholder{
    font-size: 12px;
    line-height: 16px;
    color: #FEF6F2 ;
}
.admin-form .admin-textarea{
    width: 100%;
    height: 120px;
}
.contact-admin-heading{
    font-size: 21px;
    font-family: 'Francois One';
}
.admin-nav-sec {
    text-align: left;
    margin: 30px 0 50px;
    display: flex;
    justify-content: space-between;
}
.admin-form .admin-form-btn button{
    margin-top: 60px;
    background:#0d873f ;
    width: 100%;
    padding: 8px 15px;
    border-radius: 50px;
    border: 1px solid #0d873f;
    color: #FEF6F2;
    font-family: 'Century-Bold';
    font-size: 16px;
    line-height: 20px;
    transition: all 0.3s;
    cursor: pointer;
}
.admin-form .admin-form-btn button:hover{
    background-color: transparent;
    color: #0d873f;
    transition: all 0.3s;
}