/* CategoryGrid.module.css */

/* Container */
.categoryContainer {
    flex-grow: 1;
    padding: 16px;
    background: transparent;
    border-radius: 8px;
    overflow-y: scroll;
    max-height: 38rem;
}

/* Loader */
.loaderBox {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 300px;
}

.loaderText {
    color: #555;
    margin-left: 8px;
}

/* No Data */
.noDataBox {
    text-align: center;
    min-height: 300px;
}

.noDataText {
    color: #777;
}

/* Card */
.categoryCard {
    position: relative;
    height: 100%;
    box-shadow: 0 8px 12px rgba(0, 0, 0, 0.1);
    border-radius: 16px;
    overflow: hidden;
    border: 1px solid #ffffff3d;
    background: transparent;
    transition: transform 0.3s, box-shadow 0.3s;
}

.categoryCard:hover {
    transform: translateY(-10px);
    box-shadow: 0 16px 24px rgba(0, 0, 0, 0.2);
}

/* Image */
.categoryImage {
    object-fit: contain;
    filter: brightness(1);
    width: 60%;
    margin: 0 auto;
}

/* Content */
.categoryContent {
    position: relative;
    text-align: center;
    padding: 6px;
}

.categoryName {
    font-weight: 500;
    color: #c3c3c3;
    margin-bottom: 8px;
    font-size: 16px;
}

/* Actions */
.actionButton {
    border: 1px solid #ffffff30;
    transition: box-shadow 0.3s;
}

.actionButton:hover {
    box-shadow: 0px 0px 5px #ffffff3d;
    background: transparent;
}

/* Media Queries for Grid Responsiveness */
@media (max-width: 1200px) {
    .categoryCard {
        height: auto;
    }
}

@media (max-width: 900px) {
    .categoryImage {
        height: 120px;
    }
}

@media (max-width: 600px) {
    .categoryImage {
        height: 100px;
    }

    .categoryName {
        font-size: 14px;
    }

    .actionButton {
        padding: 4px;
    }
}

@media (max-width: 480px) {
    .categoryImage {
        height: 80px;
    }
}