.textareaGmail {
  width: 100%;
  height: 325px;
  max-height: 100vh;
  padding: 10px;
  background: transparent;
  outline: none;
  border-color: transparent;
  color: white;
  font-size: 14px;
  font-family: "Century-Gothic";
}
.inputEmails {
  width: 100% !important;
  background: transparent;
  border-color: transparent;
  outline: transparent;
  color: white;
}
.pagination-mailing {
  padding-top: 30px;
  padding-right: 30px;
  padding-bottom: 30px;
  display: flex;
  /* padding-left: 30px; */
}
.prev-but {
  padding: 10px;
  font-size: 25px;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  cursor: pointer;
  color: white;
}
.for-but {
  padding: 10px;
  font-size: 25px;
  background-color: transparent;
  border-color: transparent;
  outline: none;
  cursor: pointer;
  color: white;
}
.page-num {
  font-size: 23px;
  padding: 10px;
}
.blue-email-button {
  background: #0b5eaa;
  border-radius: 8px;
  cursor: pointer;
  padding: 3px;
}
