/* .box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}
.img-preview {
  overflow: hidden;
}

.Crpper-Image img {
  opacity: 1 !important;
}

.mobile-view {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background: #161616;
}
.mobile-view img {
  width: 180px;
}

.croppedImage {
  width: 22% !important;
}

.unique-container {
  display: flex;
}



.unique-box {
  display: inline-block;
  padding: 10px;
  box-sizing: border-box;
}

.unique-img-preview {
  overflow: hidden;
}

.unique-crop-button {
}

.unique-mobile-view {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  background: #161616;
}

.unique-mobile-view img {
  width: 304px;
  height: auto;
}

.unique-cropped-image {
  width: 14% !important;
  position: absolute;
  top: 401px;
  height: auto;
  left: 1356px;
  border-bottom-left-radius: 32px;
  border-bottom-right-radius: 32px;
} */

.unique-img-preview {
  overflow: hidden;
}

/* .unique-mobile-view {
  position: relative;
  max-width: 293px;
  height: 845px;
  margin: 0 auto;
} */
.unique-mobile-view {
  position: relative;
  max-width: 293px;
  margin: 0 auto;
}

/* .unique-box {
  bottom: 38px;
  height: 100%;
  height: 653px;
  left: 50%;
  overflow: hidden;
  position: absolute;
  transform: translateX(-50%);
  width: 100%;
  width: 258px;
} */
.unique-box {
  bottom: unset;
  height: auto;
  top: 63.6%;
  left: 50%;
  overflow: hidden;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 100%;
  padding: 20px;
}

.svg-img-for-preview {
  width: 100%;
  height: 50%;
  opacity: 0.1;
}

.heading-photo-section {
  margin: 0 auto;
  width: 50%;
}

.upload-img {
  width: 120px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #191919;
  border-radius: 13px;
  margin: 0 auto;
  position: relative;
}

.upload-img input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
}

img.unique-cropped-image {
  max-width: 100%;
  object-position: bottom;
  border-radius: 0 0 40px 31px;
  object-fit: contain;
}

.uplaod-icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  background: #ffffff08;
}

.upload-btn-groups {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.upload-btn-groups button {
  font-size: 10px;
}

.upload-wrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 15px;
}

@media screen and (max-width: 1600px) {
  .upload-btn-groups {
    flex-wrap: wrap;
  }
}

@media screen and (max-width: 1199px) {
  .upload-btn-groups {
    gap: 15px;
  }

  .upload-btn-groups .blue-btn,
  .upload-btn-groups .red-btn,
  .upload-btn-groups .green-btn {
    margin: 0;
  }
}

@media screen and (max-width: 991px) {
  .up_amb .second-sec>.row {
    flex-direction: column-reverse;
    row-gap: 30px;
  }

  .heading-photo-section {
    width: 100%;
  }
}