.store-table-custom {
    background-color: black;
    color: white;
    font-size: 1rem !important;
    font-family: "Francois One" !important;
    font-weight: 400 !important;
}

.MuiDataGrid-virtualScrollerRenderZone.css-1inm7gi {
    background: black !important;
}

.css-l3yyjh-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row {
    background: #212121 !important;
}

.store-table-custom .MuiDataGrid-columnHeaders {
    background-color: black;
    font-size: 1.2rem !important;
    color: white;
}

.store-table-custom .MuiDataGrid-columnHeader {
    background-color: black;
    color: white;
}

.store-table-custom .css-78c6dr-MuiToolbar-root-MuiTablePagination-toolbar {
    color: white;
    display: flex;
    align-items: baseline;
}

.store-table-custom .MuiDataGrid-row:hover {
    background-color: #0c0c0c !important;
    transition-duration: 0.5s !important;
    cursor: pointer !important;
    color: white;
    /* Slightly lighter black for hover effect */
}

.store-table-custom .MuiDataGrid-cell {
    color: white;
}

.store-table-custom .MuiDataGrid-footerContainer {
    background-color: black;
    color: white;
}

.store-table-custom .MuiDataGrid-virtualScrollerContent.css-1kwdphh-MuiDataGrid-virtualScrollerContent {
    background: #1c1b1b;
}

.store-table-custom .MuiDataGrid-filler.css-8yphpr {
    background: #1c1b1b;
}

.store-table-custom svg {
    color: white;
}

.store-table-custom .css-1r10lu0-MuiDataGrid-root .MuiDataGrid-virtualScrollerContent .MuiDataGrid-row {
    height: 80px !important;
}

.store-table-custom .css-1xif2b4-MuiPopper-root-MuiDataGrid-menu .MuiDataGrid-menuList {
    outline: 0;
    background: black;
    color: white;
    border: 1px solid white;
}

.store-table-custom li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    border-bottom: 1px solid white;
}


.store-table-custom li.MuiButtonBase-root.MuiMenuItem-root.MuiMenuItem-gutters.MuiMenuItem-root.MuiMenuItem-gutters.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root svg {
    color: white
}

.rendercell-offers {
    display: flex;
    gap: 5px;
    overflow-x: auto;
    align-items: center;

}