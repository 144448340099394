.teampofile-sec {
  margin-bottom: 40px;
}
.radio-btns-sec {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}
.right-radios-sec {
  position: relative;
}
.right-radios-sec .check-box {
  display: flex;
  align-items: center;
}
.right-radios-sec .check-box span {
  font-size: 13px;
  line-height: 18px;
  font-family: "Century-Bold";
}
.right-radios-sec input {
  margin: 0 15px;
}
.right-radios-sec input[type="checkbox"] {
  position: relative;
  appearance: none;
  width: 52px;
  height: 29px;
  background: #ccc;
  border-radius: 50px;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: 0.4s;
  background: #cb8b15;
}
.right-radios-sec input:checked[type="checkbox"] {
  background: #cb8b15;
}
.right-radios-sec input[type="checkbox"]::after {
  position: absolute;
  content: "";
  width: 25px;
  height: 25px;
  top: 2px;
  left: 1px;
  background: #fff;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  transform: scale(1.1);
  transition: 0.4s;
}
.right-radios-sec input:checked[type="checkbox"]::after {
  left: 50%;
}
.left-radios-sec {
  display: flex;
  align-items: center;
}
.left-radios-sec .radio-sec {
  margin-right: 20px;
}
.left-radios-sec .radio-sec input {
  margin-right: 10px;
}
.left-radios-sec .radio-sec label {
  font-size: 15px;
  line-height: 20px;
  text-align: center;
  font-family: "Century-Bold";
  margin: 0;
}
.teamprofile-card {
  border-radius: 5px;
  background: #1f1f1f;
  max-width: 130px;
  width: 100%;
  overflow: hidden;
  margin-bottom: 30px;
}
.teamprofile-card .card-header {
  padding: 15px;
}
.teamprofile-card .card-profile-img {
  position: relative;
  padding: 5px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
}
.user-admin-profile-img {
  position: relative;
  padding: 5px;
  margin: 0 auto;
  text-align: center;
  overflow: hidden;
  width: 130px;
  height: 130px;
}
.user-admin-profile-img::before {
  content: "";
  position: absolute;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAAA9CAYAAAD7/KSFAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAQkSURBVHhe7Vv9VdswENeZlveSlIQNGiZoMkFhgsIEpRMUJiidoHSC0glKJyidADoBYQMSmuQ9WqKenYSHZck+6STHUOfPWLo7/3RfujuDYP6klJtyMroUAJtMUvztUl5Ds70FANccYsDZHO+9G49OAMRbLh1f+6UUX9da7X0OPRYo8vZ3T/6dnXMECLEXnkV9WH9x4UqbBcpsMvqBjLddmQfcdxY12zuu9J1BkdObbfQnMSiV/KFf2YHGxpmLcM6gzCbDSyGgm2Yqr6JmR/nPRSy7PSjLAGV5qcgyQFm27CjNVzuBIifDAyngk8oQpNiDVvvURRDOHjke7UoQ3zLyCHkIzc6xLW1rUHJC8E+045X5F/Rvsam8TgHgGKKtQbmb3ByDkO8zp8L0+Lanqa43RULU6M9rzY0DG/pWoMjptCvlH/QlivV6yA1shDatNeVMAM+3oNFAv0P7WYGCKhrb7a6iokPMIrvcLJImbv6q5NBmtxeYXXeUlado2ntUHmRQTCEYfczHtVbniMow9Lq78fAID+hDxrwtQjQZlNl4eI4n0FMM5woa7V4VtGQpVxIIpiPMZpUQLeVF1Or0KYdCAgWZ7OOd4ksWffEOQTmhMCpzDVfeQlCqGoKLQOaE6EJQfNho0QuEeM7xgbmgLLx57EtStRIf1/MQQKg0UVvi7PqNEi2vIVrv54XoXFB8xf0yANDxcM2rjKBw1G9VIOj4upi/ERRtrUTKyiRqVOAXgWKgSeiMNRctKNyQRhW4rHW275MBZZH8YIkRumkHJX5FrbaSvJX1Wnw+szEmdCBeKcmntuaSAcXFBvkih6dg4yNToDzWRI0KKTWhS4Hy2ENwETjUEH0Pis8iTZFwq3xOKZLdg/JUQnAR4JQQnYDiu/BbJNiqnxsL74uaSwJKldoVZQGW1xaBqrUrygIl1zp8N5LKeikffEwWUmuKgi62bw6XPmXgs+3o4xRD08jzo/PoY2iWJ6g5tB1DvxCXftFV5mGecobMvLQduUKH3E+5ytQZ7eIEHg761Hef2H0obV/dLdmqShVS1X3Tpl5l6nqKpu2rLUdqEzqLtqPvE/ZBz+YqU9doNW3fvGr+kwjRlBCsamLd99HYZt0htAXFNBn0iHrJ+smraL3n3EuOQSy6J/iIDCFo2LQ0yD5luZBS0wzxUlya1ERNx6dwPmV+i64nmbSHZGo74nhXv4Izb6y2L0lT8mou//V0ZAyMcTLIw9dYXB8yP7hkjjYzeYWPvuMcbXr+N4chWVPumdYT11k4KW1HH6duS8PY9nUYfrbSlERb5h9SVq7mwgnB1nmK7sSq1kDz3fa11pQlSFVqotnUSihm6QxK/Q2hAV7tZBDlKMKvYX2l5qwpidOt6nfJlh89eXG0D4nUX7Br1D4nRIc3EpWDp+HnfwjjTNxb4S97AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  z-index: 9;
  width: 125px;
  height: 113px;
  background-size: contain;
  background-position: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}
.user-admin-profile-img img {
  width: 113px;
  height: 105px;
  object-fit: cover;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}
.teamprofile-card .card-profile-img::before {
  content: "";
  position: absolute;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEUAAAA9CAYAAAD7/KSFAAAAAXNSR0IArs4c6QAAAARzQklUCAgICHwIZIgAAAQkSURBVHhe7Vv9VdswENeZlveSlIQNGiZoMkFhgsIEpRMUJiidoHSC0glKJyidADoBYQMSmuQ9WqKenYSHZck+6STHUOfPWLo7/3RfujuDYP6klJtyMroUAJtMUvztUl5Ds70FANccYsDZHO+9G49OAMRbLh1f+6UUX9da7X0OPRYo8vZ3T/6dnXMECLEXnkV9WH9x4UqbBcpsMvqBjLddmQfcdxY12zuu9J1BkdObbfQnMSiV/KFf2YHGxpmLcM6gzCbDSyGgm2Yqr6JmR/nPRSy7PSjLAGV5qcgyQFm27CjNVzuBIifDAyngk8oQpNiDVvvURRDOHjke7UoQ3zLyCHkIzc6xLW1rUHJC8E+045X5F/Rvsam8TgHgGKKtQbmb3ByDkO8zp8L0+Lanqa43RULU6M9rzY0DG/pWoMjptCvlH/QlivV6yA1shDatNeVMAM+3oNFAv0P7WYGCKhrb7a6iokPMIrvcLJImbv6q5NBmtxeYXXeUlado2ntUHmRQTCEYfczHtVbniMow9Lq78fAID+hDxrwtQjQZlNl4eI4n0FMM5woa7V4VtGQpVxIIpiPMZpUQLeVF1Or0KYdCAgWZ7OOd4ksWffEOQTmhMCpzDVfeQlCqGoKLQOaE6EJQfNho0QuEeM7xgbmgLLx57EtStRIf1/MQQKg0UVvi7PqNEi2vIVrv54XoXFB8xf0yANDxcM2rjKBw1G9VIOj4upi/ERRtrUTKyiRqVOAXgWKgSeiMNRctKNyQRhW4rHW275MBZZH8YIkRumkHJX5FrbaSvJX1Wnw+szEmdCBeKcmntuaSAcXFBvkih6dg4yNToDzWRI0KKTWhS4Hy2ENwETjUEH0Pis8iTZFwq3xOKZLdg/JUQnAR4JQQnYDiu/BbJNiqnxsL74uaSwJKldoVZQGW1xaBqrUrygIl1zp8N5LKeikffEwWUmuKgi62bw6XPmXgs+3o4xRD08jzo/PoY2iWJ6g5tB1DvxCXftFV5mGecobMvLQduUKH3E+5ytQZ7eIEHg761Hef2H0obV/dLdmqShVS1X3Tpl5l6nqKpu2rLUdqEzqLtqPvE/ZBz+YqU9doNW3fvGr+kwjRlBCsamLd99HYZt0htAXFNBn0iHrJ+smraL3n3EuOQSy6J/iIDCFo2LQ0yD5luZBS0wzxUlya1ERNx6dwPmV+i64nmbSHZGo74nhXv4Izb6y2L0lT8mou//V0ZAyMcTLIw9dYXB8yP7hkjjYzeYWPvuMcbXr+N4chWVPumdYT11k4KW1HH6duS8PY9nUYfrbSlERb5h9SVq7mwgnB1nmK7sSq1kDz3fa11pQlSFVqotnUSihm6QxK/Q2hAV7tZBDlKMKvYX2l5qwpidOt6nfJlh89eXG0D4nUX7Br1D4nRIc3EpWDp+HnfwjjTNxb4S97AAAAAElFTkSuQmCC);
  background-repeat: no-repeat;
  z-index: 9;
  width: 90px;
  height: 80px;
  background-size: contain;
  background-position: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.teamprofile-card .card-profile-img img {
  width: 80px;
  height: 70px;
  object-fit: cover;
  clip-path: polygon(25% 0%, 75% 0%, 100% 50%, 75% 100%, 25% 100%, 0% 50%);
}
.teamprofile-card .card-header .team-name {
  font-size: 13px;
  text-align: center;
  padding: 15px 0 5px;
  font-family: "Century-Bold";
  margin: 0;
}
.teamprofile-card .card-bottom {
  background-color: #3a3a3a;
  padding: 5px 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.teamprofile-card .card-bottom .red-rank {
  font-size: 13px;
  text-align: center;
  font-family: "Century-Bold";
  color: #ff0707;
}
.teamprofile-card .card-bottom .team {
  font-size: 13px;
  text-align: center;
  font-family: "Century-Bold";
}
.teamprofile-card .card-bottom .team img {
  width: 20px;
  height: 20px;
}
.teampofile-sec h4 {
  font-size: 15px;
  line-height: 20px;
  font-family: "Century-Bold";
  margin-bottom: 20px;
}
.forwords-sec h4 {
  color: #ff0707;
}
.midfielders-sec h4 {
  color: #1dbe2e;
}
.defensores-sec h4 {
  color: #005eaa;
}
.technical-staff .card-bottom {
  justify-content: center;
}

.juveniles-card {
  max-width: 285px;
  width: 100%;
  margin: 0 auto 30px;
}
.juveniles-card .card-img-sec img {
  width: 100%;
  height: 100%;
}
.team-container,
.juveniles-sec-container,
.trophy-tab-sec,
.equipment-tab-sec,
.profile-sec-tabs,
.feild-position-sec {
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 0 20px;
}
.juveniles-card .card-heading p {
  font-size: 13px;
  line-height: 16px;
  margin-bottom: 5px;
}
.personal-info-sec {
  margin-bottom: 45px;
}
.personal-info-sec .personal-info-heading {
  font-size: 15px;
  line-height: 20px;
  margin-bottom: 20px;
  font-family: "Century-Italic";
}
.personal-info-card {
  background-color: #fff;
  padding: 10px 20px;
  border-radius: 50px;
  color: #131313;
  margin-bottom: 20px;
}
.personal-info-card .info-heading {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-family: "Century-Italic";
}
.personal-info-card .info-data {
  margin: 0;
  font-size: 19px;
  line-height: 24px;
  font-family: "Century-BoldItalic";
  text-align: center;
}

.profile-btsn-inner {
  position: absolute;
  bottom: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
}
.profile-btns-sec {
  position: relative;
  height: 100%;
}
.profile-btsn-inner .green-blue-btn {
  margin-bottom: 20px;
}
.feild-sec .feild-sec-heading {
  font-size: 15px;
  line-height: 20px;
  font-family: "Century-Italic";
  margin-bottom: 20px;
}
.feild-sec .feild-card {
  background-color: #fff;
  display: flex;
  border-radius: 50px;
  padding: 8px 15px;
  color: #131313;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}
.feild-sec .feild-card p {
  margin: 0;
  font-size: 15px;
  line-height: 20px;
  font-family: "Century-Italic";
}
.feild-sec .feild-card h5 {
  margin: 0;
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
}
.feild-sec-info {
  padding: 60px 0 10px;
}
.feild-sec-info .green-text {
  color: #25762e;
  font-size: 16px;
  line-height: 20px;
  font-family: "Century-Bold";
  text-transform: capitalize;
}
.feild-sec-info .per-sec {
  display: flex;
  justify-content: space-between;
}
.feild-sec-info .per-sec {
  font-size: 16px;
  line-height: 20px;
}
.feild-sec-img {
  max-width: 150px;
  height: 250px;
  width: 100%;
  margin: 20px auto 0;
}
.feild-sec-img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.attribute-img-sec {
  margin: 30px auto 0;
}
.equipment-card-sec .equipment-card {
  background-color: #fff;
  padding: 8px 15px;
  margin-bottom: 15px;
  color: #131313;
  text-align: center;
  border-radius: 50px;
}
.equipment-card-sec .equipment-card h5 {
  margin: 0;
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
}
.equipment-list-sec .equipment-list {
  background-color: #131313;
  border-radius: 5px;
  padding: 30px;
  max-width: 690px;
  width: 100%;
  margin: 30px auto 0;
}
.equipment-list-sec .equipment-list .equp-list-content {
  position: relative;
  display: block;
  margin-bottom: 30px;
}
.equipment-list .equp-list-content .equ-list-desc {
  display: inline-block;
  padding-left: 60px;
  font-size: 12px;
  line-height: 19px;
  font-family: "Century-BoldItalic";
}
.equipment-list .equp-list-content img {
  position: absolute;
  width: 50px;
  height: 50px;
}
.trophy-sec-card .trophy-card {
  background-color: #fff;
  padding: 8px 15px;
  margin-bottom: 15px;
  color: #131313;
  text-align: center;
  border-radius: 50px;
}
.trophy-sec-card .trophy-card h5 {
  margin: 0;
  font-size: 19px;
  line-height: 20px;
  font-family: "Century-BoldItalic";
}
.trophy-profile-card {
  max-width: 400px;
  width: 100%;
  display: flex;
  margin: 30px 0 20px;
}
.trophy-profile-card .trophy-img {
  width: 57px;
  height: 57px;
  margin-right: 20px;
}

.trophy-profile-card .trophy-details-sec h4 {
  font-size: 19px;
  line-height: 25px;
  font-family: "Century-Gothic";
  margin-bottom: 15px;
}
.trophy-profile-card .trophy-details-sec p {
  margin: 0;
  font-size: 16px;
  line-height: 20px;
  font-family: "Century-Gothic";
}
.radio-sec [type="radio"]:checked,
.radio-sec [type="radio"]:not(:checked) {
  position: absolute;
  left: -9999px;
}
.radio-sec [type="radio"]:checked + label,
.radio-sec [type="radio"]:not(:checked) + label {
  position: relative;
  padding-left: 28px;
  cursor: pointer;
  line-height: 20px;
  display: inline-block;
}
.radio-sec [type="radio"]:checked + label:before,
.radio-sec [type="radio"]:not(:checked) + label:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18px;
  height: 18px;
  border: 1px solid #005eaa;
  border-radius: 100%;
  background: transparent;
}
.radio-sec [type="radio"]:checked + label:after,
.radio-sec [type="radio"]:not(:checked) + label:after {
  content: "";
  width: 12px;
  height: 12px;
  background: #005eaa;
  position: absolute;
  top: 3px;
  left: 3px;
  border-radius: 100%;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
.radio-sec [type="radio"]:not(:checked) + label:after {
  opacity: 0;
  -webkit-transform: scale(0);
  transform: scale(0);
}
.radio-sec [type="radio"]:checked + label:after {
  opacity: 1;
  -webkit-transform: scale(1);
  transform: scale(1);
}
.categories-dropdown .dropdown-btn {
  background: #131313;
  padding: 10px 15px;
  border-radius: 50px;
  border: 1px solid #fef6f2;
  font-size: 13px;
  line-height: 18px;
  font-family: "Century-Bold";
  color: #cb8b15;
  width: 130px;
}
.categories-dropdown .dropdown-btn:hover {
  background: #131313;
  color: #cb8b15;
  border: 1px solid #fef6f2;
}
.categories-dropdown .dropdown-btn.btn-check:checked + .btn,
:not(.btn-check) + .dropdown-btn.btn:active,
.dropdown-btn.btn:first-child:active,
.dropdown-btn.btn.active,
.dropdown-btn.btn.show {
  background: #131313;
  color: #cb8b15;
  border: 1px solid #fef6f2;
}
.categories-dropdown .dropdown-menu {
  border: 1px solid #303030;
  width: 130px;
  min-width: 130px;
  text-align: center;
  backdrop-filter: blur(9px);
  -webkit-backdrop-filter: blur(9px);
  background: transparent;
}
.categories-dropdown .dropdown-menu a {
  font-size: 16px;
  line-height: 25px;
  font-family: "Century-Italic";
  color: #ffffff;
  padding: 10px;
}
.categories-dropdown .dropdown-menu .dropdown-item.active,
.categories-dropdown .dropdown-menu .dropdown-item:active {
  background: transparent;
}
.categories-dropdown .dropdown-menu a:hover {
  background: transparent;
  color: #cb8b15;
}
.admin-menu-sec {
  display: flex;
  justify-content: center;
  margin-top: 100px;
}
.admin-menu-sec .menu-dropdown {
  background: #131313;
  color: #fff;
  text-align: center;
  width: 155px;
  padding: 10px 15px;
  border-radius: 0;
  border: 1px solid #fef6f2;
  font-family: "Century-Bold";
}
.admin-menu-sec .menu-dropdown:hover {
  background: #131313;
  border: 1px solid #fef6f2;
}
.admin-menu-sec .dropdown-toggle::after {
  display: none;
}
.admin-menu-sec .menu-dropdown.btn-check:checked + .btn,
:not(.btn-check) + .menu-dropdown.btn:active,
.menu-dropdown.btn:first-child:active,
.menu-dropdown.btn.active,
.menu-dropdown.btn.show {
  background: #131313;
  border: 1px solid #fef6f2;
}
.admin-menu-sec .dropdown-menu {
  background: #131313;
  text-align: center;
  width: 155px;
  min-width: 100%;
  border: 1px solid #fef6f2;
  padding: 0;
  border-radius: 0;
}
.admin-menu-sec .dropdown-menu a {
  padding: 15px 15px;
  border-radius: 0;
  font-family: "Century-Bold";
  color: #fff;
}
.admin-menu-sec .dropdown-menu a:hover {
  background-color: #cb8b15;
}
.selection-dropdown.categories-dropdown .dropdown-menu a {
  font-size: 13px;
}
.user-admin-profile {
  max-width: 160px;
  margin: 0 auto;
  text-align: center;
}
.profile-desc-sec .profile-row-sec {
  align-items: center;
  padding: 30px 0;
}

/* For devices with a width between 600px and 1024px (Tablets) */
@media only screen and (min-width: 600px) and (max-width: 1024px) {
  /* Your CSS rules for tablets */
  .widthChange {
    max-width: 640px !important;
  }
  .left-radios-sec {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
}

/* For devices with a width up to 600px (Mobile) */
@media only screen and (max-width: 600px) {
  /* Your CSS rules for mobile devices */
  .left-radios-sec {
    display: flex;
    align-items: center;
  }
}
