.todas-card {
  background: #fff;
  display: flex;
  justify-content: space-between;
  border-radius: 50px;
  align-items: center;
  padding: 5px;
  margin-bottom: 30px;
}

.todas-card .left-sec {
  display: flex;
}

.todas-card .left-sec .profile-img {
  min-width: 62px;
  height: 62px;
  border-radius: 50%;
  overflow: hidden;
}

.todas-card .left-sec .profile-content {
  margin-left: 20px;
  padding: 8px 0;
  margin-right: 20px;
}

.todas-card .left-sec .profile-content h5 {
  font-size: 19px;
  line-height: 22px;
  margin: 0 0 3px 0;
  color: #131313;
  font-family: "Century-BoldItalic";
}

.todas-card .left-sec .profile-content p {
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  color: #131313;
  font-family: "Century-Italic";
}

.digital-heading {
  display: flex;
  justify-content: space-between;
  padding: 0px 10px;
  align-items: center;
  margin-bottom: 15px;
  margin-top: 0px;
  gap: 25px;
}

.digital-heading h3 {
  font-size: 21px;
  line-height: 25px;
  text-transform: uppercase;
  margin-bottom: 0;
}

.tabs-cards-sec .nav-tabs .nav-item .nav-link {
  color: #fff;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  border: 0;
}

.tabs-cards-sec .nav-tabs .nav-link.active,
.tabs-cards-sec .nav-tabs .nav-item.show .nav-link {
  background: transparent;
  border-bottom: 3px solid #eba51a;
}

.tabs-cards-sec .nav-tabs {
  border: 0;
  margin-bottom: 50px;
}

.back-sec {
  cursor: pointer;
}

.gems {
  display: flex;
  align-items: center;
  color: #fff;
}

.membersias-sec {
  margin: 0 auto;
  max-width: 240px;
  width: 100%;
}

.gems > img {
  padding-right: 15px;
}

.available-membership {
  margin-top: 100px;
  max-width: 235px;
  background-color: #131313;
  color: #fff;
}

.available-membership > h6 {
  text-align: center;
  padding: 10px 15px;
  border: 1px solid #fef6f2;
  font-size: 16px;
  line-height: 21px;
  margin-bottom: 0;
}

.available-body {
  border: 1px solid #fef6f2;
  padding: 20px 25px;
  list-style-type: none;
  margin-bottom: 0;
}

.available-body > li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.available-body > li > span {
  font-size: 16px;
  line-height: 20px;
  font-style: italic;
}

.available-body > li > span > img {
  width: 16px;
  margin-right: 11px;
}

.available-body > li:not(:last-child) {
  margin-bottom: 30px;
}

.digital-leader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #fff;
  width: 100%;
}

.digital-leader > h6 {
  font-style: italic;
  color: #fff;
}

.digital-leader > :nth-child(1),
.digital-leader > :last-child {
  width: 20%;
  text-align: right;
  font-style: italic;
}

.digital-leader > div:nth-child(3) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.digital-leader .center-dl {
  width: 80%;
}

.center-head {
  text-align: center;
}

.center-head > img {
  width: 125px;
  height: 125px;
  object-fit: cover;
  border-radius: 50%;
}

.center-head > h3 {
  font-size: 19px;
  line-height: 20px;
  margin-top: 13px;
}

.personal-info {
  max-width: 350px;
  margin: 70px auto 0;
}

.personal-info > h4 {
  font-size: 19px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 30px;
  text-align: center;
}

.personal-data {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #fef6f2;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.personal-data > span {
  width: 50%;
}

.personal-data > span {
  font-size: 11px;
  font-style: italic;
  word-break: break-all;
}

.personal-data > span:nth-child(2) {
  color: #fef6f2;
}

.available-badge {
  text-align: center;
  color: #fff;
}

.available-badge > h4 {
  font-size: 19px;
  line-height: 21px;
  text-transform: uppercase;
  margin-bottom: 17px;
}

.available-badge h6 {
  font-size: 9px;
  line-height: 15px;
  color: #eba51a;
  text-transform: uppercase;
}

.pr-hier {
  margin-bottom: 35px;
}

.available-badge .row > div {
  margin-bottom: 30px;
  padding: 0 5px;
  position: relative;
}

.first-row {
  position: relative;
}

.row.first-row > div {
  margin-bottom: 45px;
}

.row.first-row > div:before {
  content: "";
  position: absolute;
  bottom: -27px;
  left: -1px;
  right: 0;
  background: #fff;
  width: 1px;
  height: 18px;
  margin: 0 auto;
}

.sec-row {
  position: relative;
}

.row.sec-row:before {
  content: "";
  position: absolute;
  top: -18px;
  left: 0;
  right: 0;
  background: #fff;
  width: calc(100% - 33.33%);
  height: 1px;
  margin: 0 auto;
}

.available-badge .row.sec-row > div:before {
  content: "";
  position: absolute;
  top: -30px;
  left: 50%;
  transform: translate(-50%, 0);
  width: 1px;
  height: 18px;
  background: #fff;
}

.available-badge .row.sec-row > div:nth-child(1):before,
.available-badge .row.sec-row > div:nth-child(2):before,
.available-badge .row.sec-row > div:nth-child(3):before {
  top: -17px;
}

.disable {
  opacity: 0.6;
}

.digi-profile-sec {
  max-width: 200px;
  width: 100%;
  margin: 0 auto;
  text-align: center;
}

.digi-profile-sec .gems {
  justify-content: center;
  padding: 20px 0;
}

.gems-movements-sec .gems-movements {
  text-align: center;
  margin: 5px 0 20px;
}

.gems-movements-sec {
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
}

.gems-movements-sec .gems-card {
  display: flex;
  margin: 0 0 10px 0;
  justify-content: space-between;
}

.gems-movements-sec .gems-card .left-side {
  display: flex;
}

.gems-movements-sec .gems-card .left-side .img-sec {
  width: 40px;
  height: 40px;
}

.gems-movements-sec .gems-card .left-side img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.gems-movements-sec .gems-card .details-sec {
  margin-left: 20px;
}

.gems-movements-sec .gems-card .details-sec h5 {
  font-size: 16px;
  line-height: 20px;
  margin: 0 0 3px 0;
}

.gems-movements-sec .gems-card .details-sec p {
  font-size: 12px;
  line-height: 16px;
  margin: 0;
}

.gems-movements-sec .gems-card .increasing {
  font-size: 19px;
  line-height: 20px;
  color: #1dbe2e;
  margin-bottom: 5px;
}

.decreasing {
  font-size: 19px;
  line-height: 20px;
  color: #ff0707;
  margin-bottom: 5px;
}

.gems-movements-sec .gems-card .sub-hed {
  font-size: 12px;
  color: #fef6f2;
  opacity: 0.6;
  text-align: center;
}

.dis-fade .lock-icon {
  position: absolute;
  top: 50%;
  transform: translate(-50%);
  left: 50%;
}

.mt-34 {
  margin-top: 34px;
}

.Chat-box {
  display: none;
}

.ActiveClass {
  display: block;
}

.acc-head {
  display: flex;
  justify-content: space-between;
  border-radius: 20px 20px 0 0;
  padding: 11px 18px;
  background-image: linear-gradient(#cb8b15, #fdd300);
  text-transform: uppercase;
}

.acc-head .country-assets {
  display: flex;
  align-items: center;
}

.acc-head p {
  margin-bottom: 0;
  font-size: 19px;
  line-height: 1.2;
}

.acc-head .country-assets p {
  font-size: 20px;
  margin-right: 8px;
  line-height: 1.2;
}

.club-list li {
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border: 1px solid #fef6f2;
  margin: 2px 0;
  border-left: unset;
  border-right: unset;
  position: relative;
}

.club-list li:hover {
  border-color: #cb8b15;
}

.club-list li:hover::before {
  content: "";
  position: absolute;
  left: 1px;
  width: 1px;
  height: 100%;
  top: 0;
  background-color: #cb8b15;
  bottom: 0;
  z-index: -1;
}

.club-list li:hover::after {
  content: "";
  position: absolute;
  right: 1px;
  width: 1px;
  height: 100%;
  top: 0;
  background-color: #cb8b15;
  bottom: 0;
  z-index: -1;
}

.cardInfo span {
  font-size: 12px;
  margin-left: 5px;
}

.cardDate span {
  font-size: 12px;
  margin-left: 5px;
}

.digitales-list {
  display: flex;
  justify-content: center;
  text-align: center;
  max-width: 400px;
  margin: 0 auto 20px;
  flex-wrap: wrap;
  padding: 0;
}

.digitales-list .dl-text {
  max-width: 80px;
  color: #fff;
  font-size: 8px;
  margin: 10px auto 0;
}

.digitales-list li {
  flex: 0 20%;
  padding: 15px 5px;
}

.digitales-list li:hover {
  background-color: #cb8b15;
}

.club-planes a {
  color: #cb8b15;
  font-size: 16px;
  font-style: italic;
  display: flex;
  align-items: flex-end;
  height: 100%;
}

.cardInfo {
  margin-bottom: 7px;
}

.first-list {
  border-radius: 0 0 20px 20px;
}

.club-centerText {
  /* background: #916E1C; */
  /* filter: blur(4px); */
  /* z-index: -1; */
  position: relative;
}

.club-centerText::before {
  content: "";
  position: absolute;
  background: #916e1c;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: -1;
  filter: blur(20px);
  transform: scale(1.2);
}

.second-list .club-centerText {
  width: calc(100% - 50px);
  text-align: center;
}

.second-list .club-centerText::before {
  display: none;
}

.diamond-member span,
.membership-price span {
  font-size: 14px;
}

.first-list {
  border-radius: 0 0 20px 20px;
  display: flex;
  justify-content: space-between;
  padding: 15px 20px;
  border: 1px solid #fef6f2;
  border-top: unset;
}

.acc-head-outer.active .club-planes {
  display: none;
}

.acc-head-outer.active .club-centerText {
  width: 100%;
  text-align: center;
}

.acc-head-outer.active .club-centerText::before {
  display: none;
}

.club-list {
  padding: 0;
  overflow: hidden;
  width: 0;
  max-height: 0;
  transition: max-height 0.15s ease-out;
}

.acc-head-outer.active + .acc-body .club-list {
  width: 100%;
  max-height: 100%;
  transition: max-height 0.25s ease-in;
}

.acc-head-outer.active .first-list {
  border-radius: 0;
}

.black-bg {
  font-size: 10px;
  background: #000000;
  border: 1px solid #fef6f2;
  border-radius: 5px;
  color: #fef6f2;
  margin-bottom: 20px;
}

.comment-box {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  align-items: center;
  flex-direction: column;
}

.comment-box1 {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  gap: 10px;
  align-items: center;
  flex-direction: row;
}

span.profile-desc {
  display: block;
  max-height: 144px;
  overflow-y: scroll;
}

.reciver-left img {
  width: 28px;
  height: 28px;
}

.profile-text {
  width: calc(100% - 80px);
  display: flex;
  flex-direction: column;
  column-gap: 8px;
  justify-content: center;
}

.profile-time {
  width: 40px;
}

.social-comment {
  display: flex;
  gap: 10px;
  font-size: 13px;
  background: #fef6f2;
  margin: 0;
  padding: 7px 15px;
  color: #000;
  margin-top: 30px;
}

.social-comment li span {
  margin-left: 5px;
}

.social-comment li img {
  width: 15px;
}

.transparent-bg {
  padding-left: 50px;
  background: transparent;
  border: unset;
}

.transparent-bg .social-comment {
  background: transparent;
  color: #fef6f2;
  margin-top: 0;
}

.diamond-acc-head .acc-head {
  background: linear-gradient(#1b6fb7, #4b9fe8);
}

.club-list {
  border-left: 1px solid #fef6f2;
  border-right: 1px solid #fef6f2;
}

.club-list li:last-child {
  border-color: #cb8b15;
  margin: 0;
}

.token-box {
  padding: 15px 30px;
}

.green-text p,
.red-text p {
  color: #25762e;
  margin: 0;
  font-size: 12px;
}

.red-text p {
  color: #800b0f;
}

.white-box {
  background: #fff;
  color: #000;
  padding: 15px 20px;
  border-radius: 7px;
  font-size: 17px;
  margin-bottom: 10px;
  line-height: 1.1;
  font-style: italic;
  position: relative;
}

.tb-top-sec {
  padding-left: 50px;
}

.token-box button.red-btn {
  min-width: 120px;
  padding: 5px;
}

.dt-logo {
  display: inline-block;
  position: absolute;
  top: -40px;
  left: -30px;
}

.tb-bottom-sec {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.green-clr {
  color: #25762e;
  font-style: italic;
}

.digital-tabel {
  border: 1px solid #fff;
}

.digital-tabel ul {
  padding: 0;
}

.digital-tabel {
  border: 1px solid #fff;
}

.digital-tabel ul {
  padding: 0;
}

.digital-table-wrap {
  overflow: auto;
}

.digital-table {
  display: flex;
  border: 1px solid #fff;
  flex-wrap: wrap;
  background: #131313;
  height: 500px;
  min-width: 767px;
}

.digitalTable-left {
  width: 30%;
  border-right: 1px solid #fff;
}

.digital-right {
  width: 70%;
}

.digitalTable-head {
  padding: 12px;
  border-bottom: 1px solid #fff;
  min-height: 40px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
}

.digitalTable-left ul.digital-tudo-list {
  padding: 20px 30px;
}

.digitalTable-left ul.digital-tudo-list li a {
  font-size: 13px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.digitalTable-left ul.digital-tudo-list li {
  margin-bottom: 20px;
}

span.tudo-counter {
  padding: 3px 10px;
  border: 1px solid #fef6f2;
  border-radius: 8px;
}

.digitalRight-body {
  font-size: 12px;
}

span.tudo-counter {
  padding: 3px 10px;
  border: 1px solid #fef6f2;
  border-radius: 8px;
}

ul.Digiright-table li {
  display: flex;
  align-items: center;
  min-height: 40px;
}

.digitalRight-body {
  font-size: 12px;
  overflow-x: scroll;
  max-height: 458px;
}

.digiRight-content-outer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  min-height: 40px;
  align-items: center;
  border-bottom: 1px solid #fff;
  padding: 10px;
}

ul.Digiright-table li:last-child.digiRight-content-outer {
  border-bottom: unset;
}

.digiRight-content {
  display: flex;
  gap: 8px;
}

.checkbox-div {
  padding: 12px;
  min-height: 40px;
  border-right: 1px solid #fff;
}

.digihead-left,
.digitalId-div {
  min-width: 90px;
  text-align: left;
}

.digihead-right {
  width: calc(100% - 90px);
  text-align: left;
}

.digitalInner-table .digitalId-div {
  padding: 12px;
  border-bottom: 1px solid #fff;
}

.digipost-sec {
  padding: 22px 15px;
}

.digitalRight-body .comment-box-outer {
  max-width: 350px;
  margin: 0 auto;
}

.ds-text {
  margin-bottom: 35px;
}

.white-comment-box {
  max-width: 350px;
  margin: 0 auto;
  background: #fff;
  color: #000;
  border-radius: 30px;
  padding: 10px;
  font-size: 10px;
}

.white-comment-box span.profile-name {
  font-size: 12px;
  font-weight: 600;
}

.white-comment-box .profile-text {
  font-style: italic;
}

.white-comment-box .profile-time span {
  font-weight: 700;
}

.digitalInner-table {
  margin-top: 20px;
}

.digitalInner-table .comment-box-outer {
  margin-bottom: 20px;
}

.right-img {
  margin-left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 13px;
}

.todas-card-list .todas-card-content {
  height: 0;
  overflow: hidden;
  z-index: -99;
  position: relative;
}

.todas-card-list {
  margin-bottom: 30px;
}

.todas-card-list.active {
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 99999;
  left: 0;
  right: 0;
  bottom: 0;
}

.todas-card-main {
  position: relative;
  height: 100px;
}

.todas-card-list.active .todas-card-content {
  display: flex;
  box-shadow: 2px 2px 3px #000000;
  -webkit-backdrop-filter: blur(11px);
  backdrop-filter: blur(11px);
  transition: 1s;
  z-index: 99;
  background: #0000007d;
  border: 1px solid #fef6f2;
  border-radius: 15px;
  margin-top: -36px;
  padding: 30px 20px 20px;
  z-index: -1;
  position: relative;
  height: auto;
  justify-content: center;
  flex-direction: column;
  width: calc(100% - 15px);
  transition: 0.5s all;
}

.todas-card-content .tc-Maintitle p {
  margin: 0;
  font-size: 16px;
  text-transform: uppercase;
  font-style: italic;
}

.tc-Semititle {
  margin: 30px 0;
}

ul.verification-requirements li {
  font-size: 13px;
  margin-bottom: 15px;
}

.tc-profile {
  display: inline-block;
  text-align: center;
  position: absolute;
  top: auto;
  bottom: 18px;
  left: 50%;
  transform: translateX(-50%);
}

.tc-profile img {
  cursor: pointer;
}

.tc-footer {
  font-size: 10px;
}

.tc-footer p {
  margin-bottom: 5px;
}

.tc-conditions {
  display: flex;
  position: absolute;
  bottom: 18px;
  gap: 30px;
  right: 40px;
}

.tc-conditions img {
  min-width: 50px !important;
  height: 50px !important;
}

.tc-conditions p {
  display: block;
  text-align: center;
}

.todas-card-list .todas-three-item .right-sec img {
  opacity: 1;
}

.todas-card-list.active .todas-three-item .right-sec img {
  opacity: 0;
}

.todas-three-item .left-sec .profile-img {
  padding: 0 !important;
}

.todas-img img {
  cursor: pointer;
}

.right-sec {
  margin-bottom: 0px;
  display: flex;
}

.tabs-cards-sec .nav-tabs .nav-item .nav-link {
  color: #fff;
  font-size: 19px;
  line-height: 26px;
  text-transform: uppercase;
  border: 0;
}

.tabs-cards-sec .nav-tabs .nav-link.active,
.tabs-cards-sec .nav-tabs .nav-item.show .nav-link {
  background: transparent;
  border-bottom: 3px solid #eba51a;
}

.tabs-cards-sec .nav-tabs {
  border: 0;
}

.tab-content {
  overflow-y: auto !important;
  max-height: 691px !important;
  height: 500px !important;
  /* scrollbar-width: thin; */
  padding-top: 0px !important;
  /* margin-top: -38px !important; */
  overflow: auto;
}

.tab-content-wrapper {
  min-width: 750px;
}

.dashboard-container {
  padding-top: 0px !important;
  padding-right: 15px !important;
  padding-bottom: 50px !important;
  padding-left: 15px !important;
}

.tabs-section {
  overflow-y: auto;
  overflow-x: hidden;
}

.loading-adjust {
  background: transparent;
  display: flex;
  height: 32px;
  justify-content: center;
}

/* page switch */
.page-switch {
  display: flex;
  justify-content: center;
  width: 100%;
}

.page-buttons {
  background: transparent;
  color: white;
  padding: 20px;
  outline: none;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
  cursor: pointer;
}

.pagenum {
  background: transparent;
  font-size: 18px;
  color: white;
  padding: 20px;
  outline: none;
  border-top-color: transparent;
  border-right-color: transparent;
  border-bottom-color: white;
  border-left-color: transparent;
  border-bottom-width: 2px;
}

.Digital-sec .left-sec,
.Digital-sec .right-sec {
  flex: 0 calc(50% - 23px);
}

.Digital-sec .right-sec {
  justify-content: end;
}

.acc-head .country-assets img {
  max-width: 35px;
}

@media screen and (max-width: 1700px) {
  .digital-heading {
    margin-bottom: 100px !important;
  }

  .search-icon-in {
    top: 55px;
  }
}

@media screen and (max-width: 1399px) {
  .Digital-sec .left-sec,
  .Digital-sec .right-sec {
    padding: 10px;
    align-items: center;
  }
}

@media screen and (max-width: 1199px) {
  .digital-manager-page .mid-sec,
  .digital-manager-page .third-sec {
    margin-top: 30px;
    padding: 0;
  }

  .personal-info {
    max-width: 100%;
  }

  .available-membership {
    margin-top: 50px;
  }

  .gems {
    justify-content: center;
  }

  .red-btn,
  .blue-btn {
    max-width: 250px;
    margin: 50px 10px;
  }

  .two-buttons-sec {
    margin-top: 50px;
  }

  .lock-icon-first img {
    left: calc(50% - 10px) !important;
  }

  .dashboard-container .digital-manager-page .first-sec {
    order: 3;
    width: 100%;
    flex: unset;
  }

  .dashboard-container .digital-manager-page .third-sec {
    order: 2;
  }

  .fol-fol-num h6,
  .fol-fol-num-2 h6 {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .Digital-sec .left-sec,
  .Digital-sec .right-sec {
    align-items: center;
    padding: 0;
  }

  .digital-leader {
    flex-direction: column;
  }

  .digital-leader > :nth-child(1) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0;
  }

  /* .mi-poly {
    margin: 0;
    position: static;
  } */
  .digital-leader {
    position: relative;
  }

  .fol-fol-num {
    padding: 0 !important;
  }

  .fol-fol-num-2 {
    position: absolute;
    top: 0;
    padding: 0 !important;
    right: 0;
    margin: 0 !important;
  }

  .digital-leader .center-dl {
    width: 100%;
  }

  .personal-info {
    margin-top: 40px;
  }

  .red-btn,
  .blue-btn {
    max-width: 100%;
    margin: 0;
  }

  .membersias-sec,
  .available-membership {
    max-width: 100%;
  }

  .two-buttons-sec {
    gap: 15px;
  }

  .todas-card .left-sec .profile-img {
    min-width: 40px;
    width: 40px;
    height: 40px;
  }

  .todas-card .left-sec .profile-content h5 {
    font-size: 16px;
    line-height: 1.2;
  }

  .todas-card .left-sec .profile-content p {
    font-size: 14px;
    line-height: 1.2;
  }

  .todas-img-mid img {
    width: 35px;
    height: 35px;
  }

  .todas-card .left-sec .profile-content {
    margin-left: 15px;
    margin-right: 15px;
    padding: 4px 0;
  }

  .digital-heading h3 {
    font-size: 18px;
    line-height: 1.4;
  }

  .digital-manager-page .available-badge .red-btn {
    margin-bottom: 15px;
  }

  .todas-img img {
    width: 35px;
    height: 35px;
  }

  .acc-head p {
    font-size: 15px;
    line-height: 32px;
  }

  .acc-head .country-assets p {
    font-size: 16px;
  }

  .acc-head {
    flex-direction: column;
    gap: 10px;
  }

  .gems-movements-sec .gems-card .left-side .img-sec {
    width: 30px;
    height: 30px;
  }

  .gems-movements-sec .gems-card .details-sec h5 {
    font-size: 14px;
    line-height: 1.2;
  }

  .membersias-sec {
    margin-top: 40px;
  }
}
